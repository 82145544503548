import React from 'react';

import { configs } from '$configs';
import { SORT, ROUTES, COLORS } from '$gbusiness/enums';
import CellModel from '$gbusiness/models/cell';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import intl from '$intl';
import { deriveRawToInvoice } from '$business/models/invoice';
import { Button, Chip } from '@material-ui/core';
import PopOver from '$gcomponents/reusables/popOver';
import { format, formatDatetime } from '$ghelpers/date';
import ExportModel from '$business/models/exportSettings';

export const AUTO_COMPLETE = {
  endpoint: configs.api.users.search,
  renderSuggestion: e => `${e.firstName} ${e.lastName} ${e.email}`,
  selectedValue: e => e.firstName,
  onSelect: (e, history) => {
    history.push(`${ROUTES.USERS}/${e.userId}`);
  },
};

export const TABLE_CONFIG = {
  endpoint: configs.api.invoice.list,
  deriveToModel: deriveRawToInvoice,
  color: COLORS.SECONDARY,
  pageSize: 50,
  fontSize: '0.9em',
  cellPadding: `3px`,
  isStripped: true,
  minWidth: 960,
  maxWidth: 1300,
  shouldVirtualize: false,
  defaultSortKey: 'createdAt',
  defaultSortOrder: SORT.DSC,
  virtualized: {
    rowHeight: 36,
    headerHeight: 60,
    checkWidth: 60,
    threshold: 10,
    additionalHeight: 40,
  },
  filter: {
    color: COLORS.PRIMARY,
  },
};

export const exportSettings: ExportModel = {
  deriveTable: row => {
    return {
      BillDate: format(row.billDate || row.createdAt),
      CustomerName: row.customerName,
      CustomerCode: row.customerCode,
      AmountDue: row.amount && intl('PRICE', { amount: row.amount }),
      Status: row.status || 'New',
      PaidDate: row.paidAt && formatDatetime(row.paidAt),
      User: row.userName,
    };
  },
  deriveToModel: TABLE_CONFIG.deriveToModel,
  fileName: 'bills',
  url: configs.api.invoice.list,
  sortKey: TABLE_CONFIG.defaultSortKey,
  sortOrder: TABLE_CONFIG.defaultSortOrder,
};

export const deriveToExport = (row: any) => {
  return {
    BillDate: format(row.billDate || row.createdAt),
    CustomerName: row.customerName,
    CustomerCode: row.customerCode,
    AmountDue: row.amount && intl('PRICE', { amount: row.amount }),
    Status: row.status || 'New',
    PaidDate: row.paidAt && formatDatetime(row.paidAt),
    User: row.userName,
  };
};

export const TABLE: Array<CellModel> = [
  {
    label: 'SCREEN.INVOICE.COLS.BILL_DATE',
    value: 'datetime',
    component: row => <>{format(row.billDate || row.createdAt)}</>,
    sortKey: 'createdAt',
    sortable: SORT.DSC,
    width: 140,
  },
  // {
  //   label: 'SCREEN.INVOICE.COLS.INVOICE_NO',
  //   value: 'invoiceId',
  //   sortKey: 'id',
  //   sortable: SORT.ASC,
  //   width: 100,
  // },
  {
    label: 'SCREEN.INVOICE.COLS.CUSTOMER_NAME',
    value: 'customerName',
    sortable: SORT.ASC,
    width: 200,
  },
  {
    label: 'SCREEN.INVOICE.COLS.CUSTOMER_CODE',
    value: 'customerCode',
    sortable: SORT.ASC,
    width: 160,
  },
  {
    label: 'SCREEN.INVOICE.COLS.AMOUNT_DUE',
    value: 'amount',
    component: row => <>{row.amount && intl('PRICE', { amount: row.amount })}</>,
    width: 150,
  },
  {
    label: 'SCREEN.INVOICE.COLS.STATUS',
    value: 'status',
    component: row => {
      const color =
        row.status === 'Sent'
          ? 'primary'
          : row.status === 'Viewed'
          ? 'tertiary'
          : row.status === 'Paid'
          ? 'success'
          : 'default';
      return (
        <Chip variant="outlined" className={`${color} outlined`} size="small" label={row.status || 'New'} />
      );
    },
    width: 120,
  },
  {
    label: 'SCREEN.INVOICE.COLS.PAID_DATE',
    component: row => row.paidAt && <>{formatDatetime(row.paidAt)}</>,
    value: 'paidAt',
    width: 200,
  },
  {
    label: 'SCREEN.INVOICE.COLS.USER',
    value: 'userName',
    sortable: SORT.ASC,
    width: 200,
  },
  {
    label: 'SCREEN.TRANSACTIONS.COLS.ACTION',
    value: '',
    align: 'center',
    className: 'action',
    component: (row, actions) => {
      return (
        <PopOver component={<MoreHorizIcon className="pointer" />}>
          <>
            {!row.paidAt && (
              <Button className="left" fullWidth onClick={() => actions.sendReminder(row)}>
                {intl('SCREEN.INVOICE.ACTIONS.SEND_REMINDER')}
              </Button>
            )}
            <Button className="left" fullWidth onClick={() => actions.deleteInvoice(row, actions.onRefresh)}>
              {intl('SCREEN.INVOICE.ACTIONS.DELETE')}
            </Button>
            <Button
              className="left"
              fullWidth
              onClick={() => actions.transactionHistory(row, actions.onRefresh)}>
              {intl('SCREEN.PAYMENT.TRANSACTION_HISTORY')}
            </Button>
          </>
        </PopOver>
      );
    },
    width: 100,
  },
];
