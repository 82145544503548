import styled from 'styled-components';
import { IonPage } from '@ionic/react';
import { FONT, SPACE, WEIGHT } from '$gstyles';

export const IonPageWrapper = styled(IonPage)`
  .page-wrapper {
    text-align: center;
    margin: auto;
    max-width: 600px;

    .subtitle {
      font-size: ${FONT.LARGE};
    }

    .login-link {
      margin-top: ${SPACE.XXLARGE};
      font-size: ${FONT.LARGE};
    }

    h2 {
      font-weight: ${WEIGHT.SEMI_BOLD};
      margin-bottom: ${SPACE.XLARGE};
    }
  }
`;
