import React from 'react';
import intl from '$intl';
import { Div, Space, SPACE } from '$gstyles';
import CheckIcon from '@material-ui/icons/Check';
// import { Button } from '$gcomponents/primitives';

const ThankyouSection: React.FC = () => {
  return (
    <Space.All padding={SPACE.XLARGE}>
      <div className="center check icon-wrapper">
        <CheckIcon className="check-icon" />
      </div>
      <h2>{intl('SCREEN.THANK_YOU.TITLE')}</h2>
      <Div className="message">{intl('SCREEN.THANK_YOU.MESSAGE')}</Div>
      {/* <Button fullWidth onClick={onClick}>
        {intl('SCREEN.THANK_YOU.BUTTON')}
      </Button> */}
    </Space.All>
  );
};

export default ThankyouSection;
