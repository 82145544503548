import { combineReducers } from 'redux';

import { loadingReducer } from '$gbusiness/redux/loading';
import { routerReducer } from '$gbusiness/redux/router';
import { cuserReducer } from '$gbusiness/redux/currentUser';
import { userReducer } from '$gbusiness/redux/user';
import { toasterReducer } from '$gbusiness/redux/toaster';
import { localStorageReducer } from '$gbusiness/redux/localStorage';

import { initReducer } from './init';
import { invoiceReducer } from '$business/redux/invoice';
import { customerReducer } from '$business/redux/customer';
import { subscriptionReudcer } from '$business/redux/subscription';

const appReducer = combineReducers({
  init: initReducer,
  loading: loadingReducer,
  localStorage: localStorageReducer,
  routing: routerReducer,
  currentUser: cuserReducer,
  user: userReducer,
  toaster: toasterReducer,
  invoice: invoiceReducer,
  customer: customerReducer,
  subscription: subscriptionReudcer,
});

export default appReducer;
