import * as i18n from 'react-intl-universal';
import { Text } from '$gcomponents/primitives';

const checkKeyFormat = key => {
  if (!key) return false;
  if (key.includes(' ') || key !== key.toUpperCase()) return false;
  return true;
};

const intl = (key, param: any = undefined) => {
  if (!checkKeyFormat(key)) return key;

  if (param) return i18n.get(key || 'EMPTY', param);

  return i18n.get(key || 'EMPTY');
};

export { Text };

export default intl;
