import React from 'react';
import { useSelector } from 'react-redux';

import {
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogProps,
  IconButton,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { DialogModal } from './styles';
import { Div, FONT } from '$gstyles';
import intl from '$gintl';

interface ModalProps extends DialogProps {
  title?: string;
  children: React.ReactNode;
  footer?: React.ReactNode;
  minWidth?: string;
  width?: string;
  hasCloseButton?: boolean;
  onClose?: () => void;
}

const MuiModal: React.FC<ModalProps> = ({
  title = '',
  hasCloseButton = true,
  children,
  minWidth,
  width,
  onClose,
  footer,
  ...rest
}) => {
  const loading = useSelector((state: any) => state.loading);
  const { isLoadingModal, loadingText } = loading;
  return (
    <DialogModal {...rest} onBackdropClick={onClose} width={width} minwidth={minWidth} className="mui-dialog">
      {hasCloseButton && (
        <IconButton onClick={onClose} className="close-button">
          <CloseIcon />
        </IconButton>
      )}
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        <Div fontSize={FONT.MEDIUM}>{children}</Div>
      </DialogContent>
      {footer && <DialogActions>{footer}</DialogActions>}
      {isLoadingModal && (
        <Backdrop open={isLoadingModal} className="loading-backdrop">
          <CircularProgress />
          <div className="loading-text">{intl(loadingText)}</div>
        </Backdrop>
      )}
    </DialogModal>
  );
};

export default MuiModal;
