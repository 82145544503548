import React from 'react';
import EditIcon from '@material-ui/icons/Edit';

import InputRowModel from '$gbusiness/models/inputRow';
import { Row, SPACE } from '$gstyles';
import { Wrapper, Cell } from './styles';
import { configs } from '$configs';
import intl from '$intl';
import { IconButton } from '@material-ui/core';

interface DetailSectionProps {
  FORM: Array<InputRowModel>;
  formik: any;
  children?: React.ReactNode;
  onEdit: () => void;
}

const DetailSection: React.FC<DetailSectionProps> = ({ FORM, formik, children, onEdit }) => {
  return (
    <Wrapper className="form-group">
      <div className="pull-right">
        <IconButton onClick={onEdit}>
          <EditIcon />
        </IconButton>
      </div>
      <div className="inner">
        {children ||
          FORM.map((row, i) => {
            if (row.isHidden && row.isHidden(formik.values)) return null;
            return (
              <Row key={i} className={`row ${row.className || ''}`}>
                {row.items.map((col, j) => {
                  if (col.isHidden && col.isHidden(formik.values)) return null;
                  const marginBottom = `${row.lineSpace || configs.display.formSpace || 0}px`;
                  const value = formik.values[col.value];
                  const derivedValue = col.options
                    ? intl('INPUT.OPTION.' + col.options.find(c => c.value === value)?.label) || value
                    : value;
                  return (
                    <Cell
                      {...(row.style && { customStyle: row.style })}
                      key={j}
                      className="col"
                      gridSize={col.gridSize || 6}
                      padding={SPACE.SMALL}
                      style={{ marginBottom }}>
                      <div className="label">{col.labelText || intl(col.label)}</div>
                      <div className="value">{derivedValue}</div>
                    </Cell>
                  );
                })}
              </Row>
            );
          })}
      </div>
    </Wrapper>
  );
};

export default DetailSection;
