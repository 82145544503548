import { ListItem } from '@material-ui/core';
import styled from 'styled-components';

export const ListItemWrapper = styled(ListItem)`
  &.list-item {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .label {
    text-align: left;
    opacity: 0.7;
  }
  .value {
    flex: 1;
    text-align: right;
  }
`;
