import React, { useState } from 'react';
import { connect } from 'react-redux';
import { IonAlert, IonContent } from '@ionic/react';

import { SPACE, Space } from '$gstyles';
import { LoginForm, RegisterForm, ForgotForm } from '$gcomponents/widgets';
import { authActions } from '$gbusiness/redux/auth';
import { loadingActions } from '$gbusiness/redux/loading';
import LoadingModel from '$gbusiness/models/loading';

import { ModalWrapper } from './styles';
import { Link } from '$gcomponents/primitives';
import intl from '$intl';

interface LoginModalProps {
  loading: LoadingModel;
  screenState?: string;
  isLoggedIn?: boolean;
  login: Function;
  onLogin?: Function;
  register: Function;
  userId: number;
  forgotPassword: Function;
  closeModal;
  disableRegister?: boolean;
  disableForgot?: boolean;
}

const LoginModal: React.FC<LoginModalProps> = ({
  loading,
  closeModal,
  userId,
  screenState = '',
  isLoggedIn,
  login,
  onLogin,
  register,
  forgotPassword,
  disableRegister,
  disableForgot,
}) => {
  const { showLoginModal } = loading;
  const [formType, setFormType] = useState('LOGIN');
  const onClose = () => {
    closeModal();
  };
  const onSubmitLogin = values => {
    if (onLogin) {
      onLogin(values);
      return;
    }
    login(values);
  };
  const onSubmitRegister = values => register(values);
  const onSubmitForgot = values => forgotPassword(values);

  const isLogin = formType === 'LOGIN';
  const isRegister = formType === 'REGISTER';
  const isForgot = formType === 'FORGOT';

  const title = isRegister
    ? 'SCREEN.REGISTER.TITLE'
    : isForgot
    ? 'SCREEN.FORGOT.TITLE'
    : 'SCREEN.LOGIN.TITLE';
  return (
    <ModalWrapper title={title} show={showLoginModal && !isLoggedIn} onClose={onClose}>
      <IonContent>
        <IonAlert
          isOpen={screenState === 'FORGET_PASSWORD'}
          header={intl('MESSAGE.SUBMITTED')}
          message={intl('MESSAGE.PASSWORD_RESET')}
          onDidDismiss={() => {
            setFormType('LOGIN');
          }}
          buttons={['OK']}
        />
        {isLogin && <LoginForm onSubmit={onSubmitLogin} />}
        {isLogin && (
          <div className="center">
            <Link isHidden={disableForgot} className="pointer" onClick={() => setFormType('FORGOT')}>
              {intl('SCREEN.LOGIN.FORGOT_LINK')}
            </Link>
            <br />
            <Link isHidden={disableRegister} className="pointer" onClick={() => setFormType('REGISTER')}>
              {intl('SCREEN.LOGIN.REGISTER_LINK')}
            </Link>
          </div>
        )}
        {isForgot && !disableForgot && (
          <>
            <ForgotForm onSubmit={onSubmitForgot} />}
            <div className="center">
              <Link className="pointer" onClick={() => setFormType('LOGIN')}>
                {intl('SCREEN.FORGOT.LOGIN_LINK')}
              </Link>
            </div>
          </>
        )}
        {isRegister && !disableRegister && (
          <>
            <RegisterForm onSubmit={onSubmitRegister} />
            <div className="center">
              <Link className="pointer" onClick={() => setFormType('LOGIN')}>
                {intl('SCREEN.REGISTER.LOGIN_LINK')}
              </Link>
            </div>
          </>
        )}
        <Space.Ver margin={SPACE.LARGE}></Space.Ver>
      </IonContent>
    </ModalWrapper>
  );
};

const mapStateToProps = state => ({
  userId: state.currentUser.userId,
  loading: state.loading,
  isLoggedIn: state.localStorage.accessToken !== '',
  screenState: state.routing.screenState,
});

const mapDispatchToProps = {
  login: authActions.login,
  register: authActions.register,
  forgotPassword: authActions.requestPasswordReset,
  closeModal: loadingActions.closeLoginModal,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(LoginModal);
