import styled from 'styled-components';
import { IonPage } from '@ionic/react';
import { SPACE } from '$gstyles';

export const IonPageWrapper = styled(IonPage)`
  .col {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .MuiDivider-root {
    margin-top: ${SPACE.XLARGE};
  }
`;
