import { toLocal } from '$ghelpers/date';
import CardModel from './cardModel';

export default interface TransactionModel extends CardModel {
  id: number;
  transactionId: number;
  amount: number;
  paymentResult: string;
  invoiceType?: string;
  invoiceId?: number;
  customerCode?: string;
  refundStatus: string;
  refund: string;
  atuh: string;
  refNum: string;
  status: string;
  userName: string;
  datetime: string;
  actioned?: string;
  createdAt: string;
}

const derivePaymentResult = raw => {
  return raw.status;
};

const deriveStatus = raw => {
  if (raw.refundAt || raw.voidAt || raw.paidAt) return 'approved';
  if (raw.failedAt) return 'declined';
  return '';
};

const deriveRefundStatus = raw => {
  if (raw.voidAt) return 'Void';
  if (raw.refundAt) return 'Refund';
  if (raw.paidAt) return 'Purchase';
  return '';
};

const deriveTime = raw => {
  if (raw.voidAt) return raw.voidAt;
  if (raw.refundAt) return raw.refundAt;
  if (raw.paidAt) return raw.paidAt;
  if (raw.failedAt) return raw.failedAt;
};

const actioned = raw => {
  if (raw.voidAt) return 'Voided';
  if (raw.refundAt) return 'Refunded';
  if (raw.paidAt) return 'Paid';
  if (raw.failedAt) return 'Failed';
};

export const deriveRawToTransactions = raw => {
  return {
    id: raw.id,
    transactionId: raw.transactionId,
    cardHolder: raw.cardHolder,
    cardType: raw.cardType.replace('card', ''),
    last4: raw.cardNumber,
    invoiceId: raw.invoiceId || 0,
    expiry: raw.expiry,
    amount: raw.amount,
    customerCode: raw.customerCode,
    invoiceType: raw.invoiceType,
    paymentResult: derivePaymentResult(raw),
    refundStatus: deriveRefundStatus(raw),
    status: deriveStatus(raw),
    datetime: toLocal(deriveTime(raw)),
    createdAt: toLocal(raw.createdAt),
    atuh: raw.Atuh,
    actioned: actioned(raw),
    refNum: raw.refNumber,
    userName: (raw.userFirst || '') + ' ' + (raw.userLast || ''),
  };
};

// DATA GENERATOR json-generator.com
//
// {
//   totalSize: 535,
//   list: [
//     '{{repeat(10, 500)}}',
//     {
//       invoiceId: "{{index() + 1}}",
//       id: function (t) { if (t.integer(0, 20) > 1) return this.invoiceId; else return 0; },
//       amount: "{{floating(10, 1100, 2)}}",
//       invoiceType: "{{random('Item Sales', 'Reg Bill', 'PPI')}}",
//       customerCode: function (t) { return '' + t.integer(100000, 999999).toString(); },
//       userName: "{{firstName() + ' ' + surname()}}",
//       cardType: function (t) { if (this.transactionId > 0) return t.random('Visa', 'Mastercard', 'Amex', 'Discover'); else return ''; },
//       cardHolder: function (t) { if (this.transactionId > 0) return t.firstName() + ' ' + t.surname(); else return ''; },
//       last4: function (t) { if (this.transactionId > 0) return t.integer(1000, 9999); else return ''; },
//       expDate: function (t) {  if (this.transactionId > 0) return t.integer(1, 12) + '/' + t.integer(22, 25); else return ''; },
//       atuh: function (t) {  if (this.transactionId > 0) return '' + t.integer(100000, 999999).toString(); else return ''; },
//       refNum: function (t) { return this.transactionId; },
//       createdAt: function (t) { if (this.transactionId > 0) return t.date(new Date(2021, 3, 1), new Date(), 'YYYY-MM-dd HH:mm:ss'); else return ''; },
//       paidAt: function (t) { if (this.transactionId > 0) return t.date(new Date(2021, 3, 1), new Date(), 'YYYY-MM-dd HH:mm:ss'); else return ''; },
//       failedAt: function (t) { if(this.transactionId == 0 || this.paidAt) return ''; else return t.date(new Date(2021, 3, 1), new Date(), 'YYYY-MM-dd HH:mm:ss'); },
//       voidAt: function (t) { if(this.transactionId > 0 && this.paidAt && t.integer(0,100) > 95) return t.date(new Date(2021, 3, 1), new Date(), 'YYYY-MM-dd HH:mm:ss'); else return ''; },
//       refundAt: function (t) { if(this.transactionId > 0 && this.paidAt && !this.voidAt && t.integer(0,10) > 9) return t.date(new Date(2021, 3, 1), new Date(), 'YYYY-MM-dd HH:mm:ss'); else return ''; }
//     }
//   ]
// }
