import styled from 'styled-components';

export const Wrapper = styled.div`
  .MuiTabs-root {
    ${p => (p.maxWidth ? `max-width: ${p.maxWidth}` : '')};
    ${p => (p.minWidth ? `min-width: ${p.minWidth}` : '')};
    ${p => (p.height ? `min-height: ${p.height}` : '')};
    ${p => (p.classicMode ? '.MuiTabs-indicator { display: none }' : '')};
  }

  .MuiButtonBase-root.count {
    padding-right: 30px;
    .MuiBadge-badge {
      transform: initial;
      right: -24px;
      background: ${p => p.badgeColor};
    }
  }

  .MuiTab-root {
    ${p => (p.height ? `min-height: ${p.height}` : '')};
    min-width: ${p => p.itemWidth || 'initial'};
    ${p => (!p.upperCase ? `text-transform: initial` : '')};
    ${p => (p.fullWidth ? `flex: 1` : '')};
    ${p =>
      p.classicMode
        ? `
      &.Mui-selected {
        background-color: var(--ion-color-${p.textColor || 'primary'});
        color: var(--ion-color-${p.textColor || 'primary'}-contrast-bw);
        border-color: var(--ion-color-${p.textColor || 'primary'});
      }
      &:first-child {
        border-top-left-radius: 5px;
      }
      &:last-child {
        border-top-right-radius: 5px;
      }
      border: 1px solid #ddd;
      border-right-width:not:last-child 0;
      border-bottom-width: 0;
      
    `
        : ''}
  }
`;
