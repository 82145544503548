import React from 'react';
import { useDispatch } from 'react-redux';
import { differenceInDays } from 'date-fns';
import { Text } from '$intl';
import { Div, SPACE } from '$gstyles';
import { Button } from '@material-ui/core';
import { invoiceActions } from '$business/redux/invoice';
import ReceiptIcon from '@material-ui/icons/Receipt';

import { EmptyWrapper, Circle } from './styles';
import { dateToString } from '$ghelpers/date';
import { dialog } from '$gcomponents/reusables';

interface EmptySectionProps {
  onRefresh: (e) => void;
  userId: number;
  dateStart: Date;
  dateEnd: Date;
}

const EmptySection: React.FC<EmptySectionProps> = ({ onRefresh, dateStart, dateEnd, userId }) => {
  const dispatch = useDispatch();

  const loadBillConfirm = () => {
    const coverageDays = differenceInDays(dateEnd, dateStart) + 1;

    if (coverageDays < 7) {
      loadBill();
      return;
    }

    dialog.confirm({
      title: 'MESSAGE.WAIT',
      message: 'MESSAGE.FETCH_WARNING',
      key: { days: coverageDays },
      onPress: loadBill,
    });
  };
  const loadBill = async () => {
    await dispatch(
      invoiceActions.fetchInvoices({
        userId,
        dateStart: dateToString(dateStart),
        dateEnd: dateToString(dateEnd),
      }),
    );
    onRefresh({});
  };

  return (
    <EmptyWrapper className="flex-center">
      <Circle>
        <ReceiptIcon className="receipt-icon" />
        <Div>
          <Text k="SCREEN.BILLS.EMPTY.MESSAGE" />
        </Div>
        <Div>
          <Text k="SCREEN.BILLS.EMPTY.ACTION" />
        </Div>
        <Div margin={SPACE.LARGE}>
          <Button onClick={loadBillConfirm} variant="contained" color="secondary">
            <Text k="SCREEN.BILLS.EMPTY.BUTTON" />
          </Button>
        </Div>
      </Circle>
    </EmptyWrapper>
  );
};

export default EmptySection;
