import UserModel from '$gbusiness/models/user';
import { LoadCardsSuccessType } from '../card/types';

export const PROCESSING = 'PROCESSING';
export const LOGGING_IN = 'LOGGING_IN';
export const LOGGING_OUT = 'LOGGING_OUT';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const REGISTERING = 'REGISTERING';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

export const FORGOT_SUCCESS = 'FORGOT_SUCCESS';

// Action Types
export interface LoggingInType {
  type: typeof LOGGING_IN | typeof PROCESSING;
}

export interface LoggingOutType {
  type: typeof LOGGING_OUT;
}

export interface LoginSuccessType extends LoginResponseType {
  type: typeof LOGIN_SUCCESS;
}

export interface LoginFailureType {
  type: typeof LOGIN_FAILURE;
  err: string;
}

export interface LogoutSuccessType {
  type: typeof LOGOUT_SUCCESS;
}

export interface LogoutFailureType {
  type: typeof LOGOUT_FAILURE;
  err: string;
}

export interface RegisteringType {
  type: typeof REGISTERING;
}

export interface RegisterSuccessType extends RegisterResponseType {
  type: typeof REGISTER_SUCCESS;
}

export interface RegisterFailureType {
  type: typeof REGISTER_FAILURE;
  err: string;
}

export interface ForgotPasswordType {
  type: typeof FORGOT_SUCCESS;
}

export type AuthActionTypes =
  | LoggingInType
  | LoggingOutType
  | LoginSuccessType
  | LoginFailureType
  | LogoutSuccessType
  | LogoutFailureType
  | ForgotPasswordType
  | RegisteringType
  | LoadCardsSuccessType
  | RegisterSuccessType
  | RegisterFailureType;

export interface LoginResponseType {
  accessToken: string;
  user: UserModel;
}

export interface RegisterResponseType {
  accessToken: string;
  user: UserModel;
}
