import { INPUT, INPUT_TYPES } from '$gbusiness/enums';
import InputRowModel from '$gbusiness/models/inputRow';

export const CHANGE_REQUEST_FORM: Array<InputRowModel> = [
  {
    items: [
      {
        ...INPUT_TYPES.LAST_NAME,
        label: 'INPUT.LABEL.NAME',
        input: INPUT.TEXT,
        value: 'name',
        gridSize: 12,
      },
      {
        ...INPUT_TYPES.PHONE,
        gridSize: 12,
      },
      {
        ...INPUT_TYPES.EMAIL,
        gridSize: 12,
        validation: [
          {
            condition: value => {
              if (!value) return false;
              return !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value);
            },
            message: 'INVALID_EMAIL',
          },
        ],
      },
      {
        ...INPUT_TYPES.GOOGLE_ADDRESS,
      },
    ],
  },
];
