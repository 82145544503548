import styled from 'styled-components';
import { ENUMS, SPACE } from '$gstyles';
import { ListContainer } from '$gstyles/wrapper';

export const Wrapper = styled(ListContainer)`
  .MuiButton-root {
    text-transform: initial;
  }
  .scroll-container {
    padding: ${SPACE.LARGE};
    overflow-y: hidden;
    overflow-x: auto;
    padding-bottom: 4px;
    position: relative;
    z-index: 1;
  }
  .filters {
    justify-content: space-between;
    display: flex;
    margin-top: ${SPACE.LARGE};
  }
  @media screen and (max-width: ${ENUMS.SPLIT_PANE_WIDTH}) {
    .filters {
      display: block;
    }
  }

  .MuiTableRow-root.deleted {
    background-color: rgba(var(--ion-color-danger-rgb), 0.25);
  }
`;
