import {
  ToasterActionTypes,
  SET_TOASTER_DANGER,
  SET_TOASTER_WARNING,
  SET_TOASTER_SUCCESS,
  RESET_TOASTER_DANGER,
  RESET_TOASTER_SUCCESS,
  RESET_TOASTER_WARNING,
  ToasterModel,
  defaultToast,
  TOASTER_INIT_STATE,
} from './types';

export default function(state: ToasterModel = TOASTER_INIT_STATE, action: ToasterActionTypes): ToasterModel {
  switch (action.type) {
    case SET_TOASTER_SUCCESS:
      return {
        ...state,
        success: {
          ...defaultToast,
          isOpen: true,
          ...action.toast,
        },
      };
    case SET_TOASTER_WARNING:
      return {
        ...state,
        warning: {
          ...defaultToast,
          isOpen: true,
          ...action.toast,
        },
      };
    case SET_TOASTER_DANGER:
      return {
        ...state,
        danger: {
          ...defaultToast,
          isOpen: true,
          ...action.toast,
        },
      };
    case RESET_TOASTER_SUCCESS:
      return {
        ...state,
        success: defaultToast,
      };
    case RESET_TOASTER_WARNING:
      return {
        ...state,
        warning: defaultToast,
      };
    case RESET_TOASTER_DANGER:
      return {
        ...state,
        danger: defaultToast,
      };
    default:
      return state;
  }
}
