import { Dispatch } from 'redux';

import UserModel, { defaultUser, deriveRawToUser } from '$gbusiness/models/user';
import { SET_CUSER, CLEAN_CUSER, CuserActionTypes, CUSTOMER_FAILURE } from './types';
import { LOADING } from '../loading/types';
import { fetchApi } from '$gbusiness/services/api';
import { configs } from '$configs';
import { toast } from '$gcomponents/reusables';
import { COLORS } from '$gbusiness/enums';

export function handleFail(dispatch, err, key, toastStyle = '') {
  const errorKey = key || 'ERROR.SERVER';
  dispatch({
    type: CUSTOMER_FAILURE,
    err: errorKey,
  });

  if (toastStyle) toast({ text: err, message: errorKey, color: COLORS.DANGER, cssClass: toastStyle });
}

export function setCuser(user: UserModel): CuserActionTypes {
  return {
    type: SET_CUSER,
    user,
  };
}

export function editProfile(param) {
  return async (dispatch: Dispatch) => {
    const { userId } = param;
    dispatch({
      type: LOADING,
      loadingText: 'PROGRESS.SAVING',
    });

    const response = await fetchApi({
      url: configs.api.users.general + (userId ? '/' + userId : ''),
      param,
      method: userId ? 'PUT' : 'POST',
    });

    if (!response?.success) {
      handleFail(dispatch, response?.message, 'MESSAGE.SAVE_FAIL', 'medium');
      return;
    }

    const user = deriveRawToUser(response.user) || defaultUser;
    dispatch({
      type: SET_CUSER,
      user,
    });
    toast({ message: 'MESSAGE.SAVE_SUCCESS', color: COLORS.SUCCESS, cssClass: 'small' });
  };
}

export function cleanUser(): CuserActionTypes {
  return {
    type: CLEAN_CUSER,
  };
}
