import React from 'react';
import { Formik, Form } from 'formik';

import { input } from '$ghelpers';
import { Button, Text } from '$gcomponents/primitives';
import { FormSection } from '$gcomponents/reusables';
import { Box, SPACE } from '$gstyles';

import FORGOT_FORM from '$gbox/screens/forgotPasswordScreen/forgotForm';

interface ForgotFormProps {
  onSubmit: Function;
}

const ForgotForm: React.FC<ForgotFormProps> = ({ onSubmit }) => {
  const validateForm = values => {
    return input.validateError(FORGOT_FORM, values);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{ state: '' }}
      validate={validateForm}
      onSubmit={values => {
        onSubmit(values);
      }}>
      {formik => (
        <Box.MEDIUM padding={SPACE.LARGE}>
          <Form>
            <FormSection title="SCREEN.FORGOT.SUBTITLE" FORM={FORGOT_FORM} formik={formik} marginBottom="0" />
            <Button
              color="primary"
              fullWidth
              onClick={formik.handleSubmit}
              disabled={!(formik.isValid && formik.dirty)}>
              <Text k="SCREEN.FORGOT.SUBMIT_BUTTON" />
            </Button>
          </Form>
        </Box.MEDIUM>
      )}
    </Formik>
  );
};

export default ForgotForm;
