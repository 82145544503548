import styled from 'styled-components';
import { IonMenu } from '@ionic/react';

import { configs } from '$configs';
import { FONT, SPACE, WEIGHT } from '$gstyles';

export const Wrapper = styled(IonMenu)`
  @media screen and (min-width: ${configs.display.desktopMinThreshold}) {
    --side-max-width: 200px;
    --side-min-width: 200px;
  }

  ion-content,
  ion-footer,
  ion-list,
  #background-content,
  ion-list-header,
  ion-item,
  button,
  ion-toolbar,
  .menu-item a,
  div {
    border-color: var(--ion-color-base);
    --background: var(--ion-color-dark);
    background: var(--ion-color-dark);
    color: var(--ion-color-base);
    font-weight: ${WEIGHT.SEMI_BOLD};
  }
  .list-md {
    background: var(--ion-color-dark);
    color: var(--ion-color-base);
  }
  ion-item.item ion-label,
  .menu-item a,
  ion-item.item ion-icon {
    font-weight: ${WEIGHT.SEMI_BOLD};
  }
  ion-item.item:hover {
    --background: rgba(255, 255, 255, 0.3);

    ion-label {
      color: var(--ion-color-base);
      font-weight: ${WEIGHT.SEMI_BOLD};
    }
  }
  ion-item.selected {
    --background: rgba(var(--ion-color-medium-rgb), 0.6);
  }
  a.selected {
    background-color: rgba(var(--ion-color-medium-rgb), 0.6);
  }

  ion-item ion-icon {
    margin-top: ${SPACE.MEDIUM};
    margin-bottom: ${SPACE.MEDIUM};
    margin-inline-end: ${SPACE.LARGE};
  }

  ion-item::part(native) {
    min-height: ${SPACE.LARGE};
    font-size: ${FONT.SMALL};
  }

  .MuiAccordion-root.Mui-expanded {
    margin: 0;
  }
  .MuiAccordionSummary-root.Mui-expanded {
    min-height: initial;
  }
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
  }
  .MuiPaper-root {
    background-color: transparent;
    font-size: 1.4rem;
    .MuiAccordionSummary-root {
      padding: 0 ${SPACE.SMALL};
      padding-left: ${SPACE.MEDIUM};
    }
    .MuiAccordionDetails-root,
    .menu-item > div {
      padding: 0;
    }
    .menu-item > div a {
      display: block;
      padding: ${SPACE.MEDIUM};
      padding-left: 20px;
    }
    ion-list {
      padding: 0;
      width: 100%;
    }
  }

  .pop-button-container {
    margin: ${SPACE.MEDIUM};
    display: block;
  }

  .logo {
    width: 190px;
    display: inline-block;
    padding: 16px 32px;
  }

  .app-version {
    display: inline-block;
    height: ${props => (props.isProd ? '14' : '28')}px;
    position: absolute;
    padding: ${SPACE.MEDIUM};
    right: ${SPACE.SMALL};
    font-size: 1.15rem;
    opacity: 0.5;
  }

  &.md {
    ion-note {
      padding-left: ${SPACE.MEDIUM};
    }
    ion-item {
      --padding-start: ${SPACE.MEDIUM};
      --padding-end: ${SPACE.MEDIUM};
      border-radius: 4px;
    }

    ion-item ion-icon {
      color: var(--ion-color-base);
    }

    ion-item ion-label {
    }
  }
`;
