import { INPUT, INPUT_TYPES } from '$gbusiness/enums';
import InputRowModel from '$gbusiness/models/inputRow';
import { input } from '$ghelpers';

export const CARD_FORM: Array<InputRowModel> = [
  {
    items: [
      {
        label: 'INPUT.LABEL.PAYMENT.NAME',
        value: 'cardHolder',
        input: INPUT.TEXT,
        gridSize: 12,
        validation: [input.requiredValidation],
      },
      {
        label: '',
        value: 'customerCode',
        input: INPUT.HIDDEN,
        gridSize: 0,
      },
    ],
  },
  {
    items: [
      {
        ...INPUT_TYPES.CARD_NUMBER,
        gridSize: 12,
        placeholder: '',
        validation: [input.requiredValidation],
      },
      {
        ...INPUT_TYPES.EXPIRY,
        gridSize: 6,
        validation: [input.requiredValidation],
      },
      {
        ...INPUT_TYPES.CVC,
        gridSize: 6,
        value: 'cvc',
        validation: [input.requiredValidation],
      },
    ],
  },
  {
    items: [
      {
        label: 'INPUT.LABEL.BILLING_ADDRESS',
        value: 'address',
        input: INPUT.TEXT,
        placeholder: '',
        gridSize: 12,
      },
      {
        ...INPUT_TYPES.ZIP,
        gridSize: 12,
        validation: [input.requiredValidation],
      },
    ],
  },
];
