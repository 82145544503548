import { SPACE } from '$gstyles';
import { IonContent, IonFooter } from '@ionic/react';
import styled from 'styled-components';

export const IonContentWrapper = styled(IonContent)`
  .status {
    margin-left: ${SPACE.LARGE};
    font-weight: 600;
  }
  .MuiGrid-container {
    align-items: initial;
    text-align: right;
  }
  .col {
    padding: 4px 12px !important;
  }
`;

export const IonFooterWrapper = styled(IonFooter)`
  .submit-button {
    min-width: 200px;
  }
`;
