import { Div, Inline } from '$gstyles';
import React from 'react';
import packageJson from '~root/package.json';

import { Wrapper } from './styles';

interface AppVersionProps {
  includeEnv?: boolean;
  oneLine?: boolean;
}

const AppVersion: React.FC<AppVersionProps> = ({ includeEnv = true, oneLine = false }) => {
  const env = process.env.REACT_APP_ENV;
  const El = oneLine ? Inline : Div;
  return (
    <Wrapper className="app-version">
      {includeEnv && env !== 'prod' && <El className="name">{env}</El>}
      <El className="version">{packageJson.version}</El>
    </Wrapper>
  );
};

export default AppVersion;
