import React from 'react';
import { connect } from 'react-redux';

import { screen } from '$gcomponents/hoc';
import * as TABLE from './table';

import intl from '$intl';
import { KEYS } from '$business/enums/options/dateRange';
import { endOfToday, startOfToday } from 'date-fns';

import ScreenSection, { tabModel } from '../../transactionsScreen/screenSection';

const TABS: Array<tabModel> = [
  { label: 'SCREEN.INVOICE.TABS.ALL', value: 'bill' },
  { label: 'SCREEN.INVOICE.TABS.EMAIL_SENT', value: 'billSent' },
  { label: 'SCREEN.INVOICE.TABS.EMAIL_FAILED', value: 'billFailed' },
  { label: 'SCREEN.INVOICE.TABS.USPS', value: 'billUsps' },
  { label: 'SCREEN.INVOICE.TABS.DELETED', value: 'deleted' },
];

interface ActionRequiredScreenProps {}

const ActionRequiredScreen: React.FC<ActionRequiredScreenProps> = () => {
  return (
    <>
      <ScreenSection
        title={intl('SCREEN.ACTION_REQUIRED.TITLE')}
        TABLE={TABLE}
        TABS={TABS}
        useDatetime
        defaultRange={KEYS.TODAY}
        filter={{
          key: 'bill',
          view: 'bill',
          dateStart: startOfToday(),
          dateEnd: endOfToday(),
        }}
      />
    </>
  );
};

const mapStateToProps = state => ({
  resetOnRoute: true,
});

const connected = connect(mapStateToProps, null);

export default connected(screen(ActionRequiredScreen));
