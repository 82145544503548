import React from 'react';
import intl from '$intl';
import { Form, Formik } from 'formik';
import { IonContent, IonFooter } from '@ionic/react';

import { input } from '$ghelpers';
import { FormSection } from '$gcomponents/reusables';
import { Div, SPACE } from '$gstyles';
import { Flex } from '$gstyles/wrapper';
import { Button } from '$gcomponents/primitives';
import { CHANGE_REQUEST_FORM } from './changeRequestForm';
import { ModalWrapper } from '../styles';
import { useDispatch } from 'react-redux';
import { customerActions } from '$business/redux/customer';
import InvoiceModel from '$business/models/invoice';

interface ChangeRequestModalProps {
  show: boolean;
  onClose: Function;
  invoice: InvoiceModel;
  customerCode: string;
}

const ChangeRequestModal: React.FC<ChangeRequestModalProps> = ({ show, onClose, invoice, customerCode }) => {
  const dispatch = useDispatch();

  const onSubmit = async values => {
    await dispatch(customerActions.changeRequest(values));
    onClose();
  };

  const validateForm = values => input.validateError(CHANGE_REQUEST_FORM, values);

  return (
    <ModalWrapper show={show} onClose={() => onClose()} useCustom>
      <Formik
        enableReinitialize={true}
        initialValues={{
          customerCode,
        }}
        validate={validateForm}
        onSubmit={values => {
          onSubmit(values);
        }}>
        {formik => (
          <>
            <IonContent>
              <Div padding={SPACE.LARGE}>
                <Div className="title">{intl('SCREEN.CHANGE_REQUEST.TITLE')}</Div>
                <Form>
                  <Div padding="0 16px">
                    <FormSection
                      title="SCREEN.CHANGE_REQUEST.SUBTITLE"
                      FORM={CHANGE_REQUEST_FORM}
                      formik={formik}
                    />
                  </Div>
                </Form>
              </Div>
            </IonContent>
            <IonFooter>
              <Flex padding={SPACE.MEDIUM} justifyContent="flex-end">
                <Button className="third" variant="outlined" onClick={() => onClose()}>
                  {intl('BUTTON.CANCEL')}
                </Button>
                <Button
                  className="third"
                  onClick={formik.handleSubmit}
                  disabled={!formik.isValid || !formik.dirty}>
                  {intl('SCREEN.CHANGE_REQUEST.REQUEST_BUTTON')}
                </Button>
              </Flex>
            </IonFooter>
          </>
        )}
      </Formik>
    </ModalWrapper>
  );
};

export default ChangeRequestModal;
