import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IonHeader, IonBackButton, IonButtons, IonMenuButton, IonTitle } from '@ionic/react';

import { configs } from '$configs';
import intl from '$intl';
import UserModel from '$gbusiness/models/user';

import { Toolbar } from './styles';
import { Avatar } from '@material-ui/core';
import { AppModel } from '$business/redux';
import PopOver from '$gcomponents/reusables/popOver';
import { SPACE, Space, H2 } from '$gstyles';
import { Button, Link } from '$gcomponents/primitives';
import { authActions } from '$gbusiness/redux/auth';
import { ROUTES } from '$business/enums';
import { clog } from '$ghelpers/util';

interface HeaderProps {
  title?: string;
  titleParam?: object;
  titleText?: string;
  rightButton?: React.ReactNode;
  excludeHeader?: boolean;
  disableBack?: boolean;
  user?: UserModel;
}

const Header: React.FC<HeaderProps> = ({
  title,
  titleText,
  titleParam = {},
  rightButton,
  disableBack = false,
  excludeHeader = false,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state: AppModel) => state.currentUser);
  if (configs.display.layout === 'FIXED_HEADER') return null;
  const derivedTitle = intl(title, titleParam) || titleText || '';

  const onAvatarClick = () => {
    clog('AVATAR CLICKED');
  };
  const logout = () => {
    dispatch(authActions.logout());
  };

  let AvatarComponent;
  if (user) {
    AvatarComponent = (
      <Avatar alt={`${user.firstName} ${user.lastName}`} className="pointer" onClick={onAvatarClick}>
        {`${user.firstName[0]}${user.lastName[0]}`}
      </Avatar>
    );
  }

  const el = (
    <Toolbar color={configs.theme.headerColor || undefined}>
      {!disableBack && (
        <IonButtons slot="start">
          <IonBackButton />
        </IonButtons>
      )}
      <IonButtons slot="start">
        <IonMenuButton />
      </IonButtons>
      <IonTitle>{derivedTitle || intl('COMMON.APP_TITLE')}</IonTitle>
      <IonButtons slot="end">
        {rightButton && rightButton}
        {user && (
          <PopOver backdrop component={AvatarComponent}>
            <Space.All padding={SPACE.XLARGE} className="center">
              <H2 padding={0}>
                {user.firstName} {user.lastName}
              </H2>
              <Space.Bottom margin={SPACE.LARGE}>{user.email}</Space.Bottom>
              <Space.Ver margin={SPACE.LARGE} className="center">
                <Link className="link" route={ROUTES.PROFILE}>
                  {intl('MENU.PROFILE')}
                </Link>
              </Space.Ver>
              <Button color="default" fullWidth onClick={logout}>
                {intl('MENU.LOG_OUT')}
              </Button>
            </Space.All>
          </PopOver>
        )}
      </IonButtons>
    </Toolbar>
  );

  return excludeHeader ? <>{el}</> : <IonHeader>{el}</IonHeader>;
};

export default Header;
