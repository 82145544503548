import _ from 'lodash';
import React, { useState, useRef } from 'react';
import { IonButton, IonIcon, IonContent } from '@ionic/react';
import { filterSharp } from 'ionicons/icons';

import { COLORS } from '$gbusiness/enums';
import CellModel from '$gbusiness/models/cell';
import InputRowModel from '$gbusiness/models/inputRow';
import { ApiTable, Autocomplete } from '$gcomponents/reusables';
import intl, { Text } from '$gintl';
import { table } from '$gbusiness/helpers';
import { Wrapper, WrapperDesktop } from '$gstyles';

import FilterModal from './filterModal';
import FilterItem from './filterItem';
import { useHistory } from 'react-router-dom';

interface TableViewProps {
  config: any;
  TABLE: Array<CellModel>;
  AC?: any;
  FILTERS?: Array<InputRowModel>;
  savedFilters?: Array<any>;
  className?: string;
  onSelection?: (e) => void;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  actionBar?: React.ReactNode;
  onTableLoad?: (e) => void;
}

const TableView: React.FC<TableViewProps> = ({
  config,
  TABLE,
  AC,
  FILTERS = [],
  savedFilters = [],
  header,
  actionBar,
  footer,
  className = '',
  onSelection,
  onTableLoad = v => {},
}) => {
  const {
    endpoint,
    isPublic,
    mockData,
    pageSize,
    stripped,
    cellPadding,
    defaultSortKey,
    defaultSortOrder,
    minWidth,
    maxWidth,
  } = config;
  const history = useHistory();
  const contentRef = useRef<HTMLIonContentElement | null>(null);
  const [filters, setFilters] = useState({});
  const [colsToDisplay, setColsToDisplay] = useState(table.getColsToDisplay(TABLE));
  const [showModal, setShowModal] = useState(false);
  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };

  const scrollToTop = () => {
    // contentRef.current && contentRef.current.scrollToTop();
  };

  const onResetFilter = () => {
    closeModal();
    setFilters({});
  };

  const onFilterApply = (newFilters, newColsToDisplay) => {
    if (!_.isEqual(newColsToDisplay, colsToDisplay)) {
      setColsToDisplay(newColsToDisplay);
    }
    if (!_.isEqual(newFilters, filters)) setFilters(newFilters);
  };

  const closeFilter = prop => {
    const newFilters = _.omit(filters, prop);
    setFilters(newFilters);
  };

  const tableColor = config?.color || COLORS.PRIMARY;
  const filterColor = config?.filter?.color || COLORS.SECONDARY;

  return (
    <IonContent ref={contentRef} className={className}>
      <Wrapper>
        {header && header}
        {AC && (
          <Autocomplete
            AC={AC}
            endpoint={AC.endpoint}
            history={history}
            placeholder={intl('TABLE.QUICK_SEARCH')}
          />
        )}
        {FILTERS && (
          <>
            <IonButton color={filterColor} onClick={openModal}>
              <IonIcon slot="start" icon={filterSharp} />
              <Text k="BUTTON.FILTER" />
            </IonButton>
            {_.map(filters, (label, prop) => {
              if (label === '' || label === null || prop === 'datePeriod') return null;
              return (
                <FilterItem key={prop} color={filterColor} label={label} onClose={() => closeFilter(prop)} />
              );
            })}
          </>
        )}
      </Wrapper>
      <WrapperDesktop>
        {actionBar && actionBar}
        <ApiTable
          dataSource={{
            endpoint,
            isPublic,
            pageSize,
            mockData,
            defaultSortKey,
            defaultSortOrder,
          }}
          styles={{
            color: tableColor,
            isStripped: stripped,
            minWidth,
            maxWidth,
            cellPadding,
          }}
          filters={filters}
          TABLE={table.getActiveColumnTable(TABLE, colsToDisplay)}
          scrollToTop={scrollToTop}
          onTableLoad={onTableLoad}
          onSelection={onSelection}
        />
        {footer && footer}
      </WrapperDesktop>
      {FILTERS.length && (
        <FilterModal
          FILTERS={FILTERS}
          filterConfig={config?.filter}
          savedFilters={savedFilters}
          TABLE={TABLE}
          colsToDisplay={colsToDisplay}
          show={showModal}
          onClose={closeModal}
          onReset={onResetFilter}
          onSubmit={onFilterApply}
        />
      )}
    </IonContent>
  );
};

export default TableView;
