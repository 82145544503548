import {
  LoginScreen,
  ForgotPasswordScreen,
  RegisterScreen,
  ResetPasswordScreen,
  RegisterCompleteScreen,
  VerifyEmailScreen,
} from '$gbox/screens';
import RouterModel from '../models/route';

export const LOGIN = '/login';
export const FORGOT = '/forgot';
export const REGISTER = '/register';
export const REGISTER_COMPLETE = '/register-complete';
export const RESET_PASSWORD = '/resetpassword';
export const USERS = '/users';
export const VERIFY_EMAIL = '/verify-email';
export const EMPTY = '';

export const LOGIN_ROUTE: RouterModel = {
  path: LOGIN,
  component: LoginScreen,
  exact: true,
};

export const FORGOT_ROUTE: RouterModel = {
  path: FORGOT,
  param: '',
  component: ForgotPasswordScreen,
  exact: true,
};

export const RESET_PASSWORD_ROUTE: RouterModel = {
  path: RESET_PASSWORD,
  param: ':token/:email',
  component: ResetPasswordScreen,
  exact: true,
};

export const REGISTER_ROUTE: RouterModel = {
  path: REGISTER,
  param: '',
  component: RegisterScreen,
  exact: true,
};

export const REGISTER_COMPLETE_ROUTE: RouterModel = {
  path: REGISTER_COMPLETE,
  component: RegisterCompleteScreen,
  exact: true,
};

export const VERIFY_EMAIL_ROUTE: RouterModel = {
  path: VERIFY_EMAIL,
  param: ':token/:email',
  component: VerifyEmailScreen,
  exact: true,
};

export const PUBLIC_LIST = [
  LOGIN_ROUTE,
  FORGOT_ROUTE,
  RESET_PASSWORD_ROUTE,
  REGISTER_ROUTE,
  REGISTER_COMPLETE_ROUTE,
  VERIFY_EMAIL_ROUTE,
];

export const DEFAULT_PUBLIC = LOGIN_ROUTE;
export const DEFAULT_PUBLIC_ROUTE = DEFAULT_PUBLIC.path;
