import _ from 'lodash';
import React, { Component } from 'react';
import intl from '$gintl';
import { IonLoading } from '@ionic/react';

import { fetchApi } from '$gbusiness/services/api';

import { sortTable } from '../table/utils';
import { NoResult, Wrapper } from './styles';
import { DataSourceModel, defaultDataSource, defaultDisplay } from '$gbusiness/models/table';
import { Div, SPACE } from '$gstyles';
import Pagination from './pagination';

interface ApiListProps {
  dataSource: DataSourceModel;
  filters?: any;
  onDataLoad?: Function;
  scrollToTop?: Function;
  resetSelection?: boolean;
  children: any;
}

class ApiList extends Component<ApiListProps> {
  state = {
    data: [],
    totalSize: 0,
    isLoaded: false,
    isLoading: false,
    page: this.props.dataSource.defaultPage || defaultDataSource.defaultPage || 1,
    sortKey: this.props.dataSource.defaultSortKey || defaultDisplay.sortKey,
    sortOrder: this.props.dataSource.defaultSortOrder || defaultDisplay.sortOrder,
  };
  intervalId: any = null;

  componentDidMount = async () => {
    const { autoRefreshInterval } = this.props.dataSource;
    if (autoRefreshInterval && autoRefreshInterval > 1) {
      this.intervalId = setInterval(() => {
        this.fetchData(undefined, false, true);
      }, autoRefreshInterval * 1000);
    }
    this.fetchData(undefined, false, true);
  };

  componentWillUnmount = () => {
    clearInterval(this.intervalId);
  };

  componentDidUpdate(prevProps) {
    const hasFilterChanged = !_.isEqual(prevProps.filters, this.props.filters);
    const hasListChanged = prevProps.dataSource && !_.isEqual(prevProps.dataSource, this.props.dataSource);

    if (hasFilterChanged || hasListChanged) {
      this.fetchData(this.props.filters?.onlyRefresh ? undefined : 1);
    }
  }

  fetchData = async (customPage: any = undefined, appendData = false, forceHideIndicator = false) => {
    const { dataSource, filters, scrollToTop, onDataLoad } = this.props;
    const {
      endpoint,
      isPublic,
      mockData,
      pageSize,
      deriveToModel,
      method,
      hideLoadingIndicator,
    } = dataSource;
    const { isLoading, sortKey, sortOrder, page, data } = this.state;
    const newPage = customPage || page;

    if (!isLoading && !hideLoadingIndicator && !forceHideIndicator) this.setState({ isLoading: true });

    const param: any = {
      ...filters,
      sortKey,
      sortOrder,
      page: newPage,
      pageSize,
    };

    const url = method === 'GET' && param ? endpoint + '?' + new URLSearchParams(param).toString() : endpoint;
    const newParam = method === 'GET' ? {} : param;

    const response = await fetchApi({
      url,
      param: newParam,
      isPublic: isPublic,
      ...(method && { method }),
      mockData,
    });

    if (response?.list) {
      const derivedList = deriveToModel ? response.list.map(r => deriveToModel(r)) : response.list;
      const newData = appendData ? [...data, ...derivedList] : derivedList;
      const totalSize = response.totalSize || response.list.length;
      this.setState({
        page: newPage,
        isLoading: false,
        isLoaded: true,
        data: newData,
        totalSize,
      });
      if (onDataLoad) onDataLoad(totalSize, newData.length, response);
      if (scrollToTop) scrollToTop();
    } else {
      this.setState({ isLoading: false, data: [] });
      if (onDataLoad) onDataLoad(0, 0);
    }
  };

  onLoadNextPage = () => {
    const { pageSize: rowsPerPage } = this.props.dataSource;
    const { totalSize, page } = this.state;
    const pageSize = rowsPerPage === 0 ? totalSize : rowsPerPage || totalSize;
    const numberOfPages = Math.floor((totalSize - 1) / pageSize) + 1;
    if (page >= numberOfPages) return;

    this.fetchData(page + 1, true);
  };

  setPage = page => {
    if (page === this.state.page) return;

    this.setState({ page }, this.fetchData);
  };

  getNewData = (key, order) => {
    const { dataSource } = this.props;
    if (dataSource.pageSize) {
      this.fetchData();
      return;
    }

    const newData = sortTable(this.state.data, key, order);
    this.setState({ data: newData });
  };

  render() {
    const { dataSource, children } = this.props;
    const { pageSize = 50 } = dataSource;
    const { data, isLoading, totalSize, page } = this.state;

    if (!totalSize) {
      if (isLoading) return <IonLoading isOpen={true} message={intl('PROGRESS.LOADING')} />;

      return <NoResult className="no-result">{intl('MESSAGE.NO_RESULT')}</NoResult>;
    }

    const numberOfPages = Math.floor((totalSize - 1) / pageSize) + 1;

    return (
      <Wrapper>
        <IonLoading isOpen={isLoading} message={intl('PROGRESS.LOADING')} />
        <div className="list-view">
          {data.map((item, index) => {
            return children(item, index);
          })}
        </div>
        {numberOfPages > 1 && (
          <Div className="pagination" padding={`${SPACE.MEDIUM} 0 `}>
            <Pagination numberOfPages={numberOfPages} onChangePage={this.setPage} page={page} />
          </Div>
        )}
      </Wrapper>
    );
  }
}

export default ApiList;
