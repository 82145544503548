import CardModel from '../../models/cardModel';
import CustomerModel from '../../models/customer';

export const IS_PROCESSING = 'IS_PROCESSING_CARD';
export const CUSTOMER_FAILURE = 'CUSTOMER_FAILURE';
export const ADD_CARD_SUCCESS = 'ADD_CARD_SUCCESS';
export const FETCH_TRANSACTIONS_SUCCESS = 'FETCH_TRANSACTIONS_SUCCESS';
export const LOAD_CARDS_SUCCESS = 'LOAD_CARDS_SUCCESS';
export const CHECK_CUSTOMER_CODE_SUCCESS = 'CHECK_CUSTOMER_CODE_SUCCESS';
export const RESET_CUSTOMER_CODE = 'RESET_CUSTOMER_CODE';
export const DELETE_CARD_SUCCESS = 'DELETE_CARD_SUCCESS';
export const NOTHING_SUCCESS = 'NOTHING_SUCCESS';
export const RESET_STATUS = 'RESET_STATUS';
export const RESET_ALL = 'CUSTOMER_RESET_ALL';

export interface FailureType {
  type: typeof CUSTOMER_FAILURE;
  err: string;
}

export interface CheckCustomerCodeSuccessType {
  type: typeof CHECK_CUSTOMER_CODE_SUCCESS;
  isValid: boolean;
  customer?: CustomerModel;
}

export interface FetchTransactionsSuccessType {
  type: typeof CHECK_CUSTOMER_CODE_SUCCESS;
  transactions: Array<any>;
}

export interface DeleteCardSuccessType {
  type: typeof DELETE_CARD_SUCCESS;
  cardId: number;
}

export interface ResetType {
  type: typeof RESET_ALL | typeof RESET_CUSTOMER_CODE | typeof ADD_CARD_SUCCESS | typeof RESET_STATUS;
}

export interface LoadCardsSuccessType {
  type: typeof LOAD_CARDS_SUCCESS;
  cards: Array<CardModel>;
}

export type CustomerActionTypes =
  | FailureType
  | ResetType
  | LoadCardsSuccessType
  | DeleteCardSuccessType
  | FetchTransactionsSuccessType
  | CheckCustomerCodeSuccessType;

export interface CustomerReducerType {
  customers: Array<CustomerModel>;
  customer?: CustomerModel;
  isValidCustomerCode: boolean | null;
  cards: Array<CardModel>;
  isProcessing: boolean;
  isFinished: boolean;
  transactions: Array<any>;
}

export const INIT_STATE: CustomerReducerType = {
  customers: [],
  isValidCustomerCode: null,
  cards: [],
  isProcessing: false,
  isFinished: false,
  transactions: [],
};
