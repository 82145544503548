import { today, toLocal } from '$ghelpers/date';
import { EMPTY, FAILED, invoiceStatusType, PAID, SENT, VIEWED } from '../enums/invoiceStatus';
import { KEYS as EMAIL_STATUS } from '../enums/options/emailStatus';

export default interface InvoiceModel {
  invoiceId: number;
  uuid: string;
  invoiceType: string;
  invoiceNumber?: string;
  billNo?: string;
  seqNumber: number;
  email: string;
  salesEmail?: string;
  emailStatus: string;
  customerCode?: string;
  customerName: string;
  customerOwner?: string;
  address1?: string;
  address2?: string;
  amount: number;
  tax?: number;
  message?: string;
  note?: string;
  userName: string;
  status: invoiceStatusType;
  pastDue: boolean;
  dueDate?: string;
  importedDate?: string;
  billDate?: string;
  projNo?: string;
  createdAt?: string;
  datetime?: string;
  sentAt?: string;
  paidAt?: string;
}

const deriveStatus = raw => {
  if (raw.paidAt) return PAID;
  if (raw.viewedAt) return VIEWED;
  if (raw.sentAt) return SENT;
  if (raw.failedAt) return FAILED;
  if (raw.createdAt) return EMPTY;
};

const deriveTime = raw => {
  if (raw.paidAt) return raw.paidAt;
  if (raw.viewedAt) return raw.viewedAt;
  if (raw.sentAt) return raw.sentAt;
  if (raw.actionedAt) return raw.actionedAt;
  if (raw.createdAt) return raw.createdAt;
};

const derivePastDue = raw => {
  const { pastDue, dueDate } = raw;
  if (pastDue) return pastDue;
  if (!dueDate || today() <= dueDate) return 'NO';
  return 'YES';
};

const deriveEmailStatus = raw => {
  const { emailStatus, failedAt, sentAt } = raw;
  if (emailStatus) return emailStatus;
  if (failedAt) return EMAIL_STATUS.FAILED;
  if (sentAt) return EMAIL_STATUS.SUCCESS;
  return '';
};

export const deriveRawToInvoice = raw => {
  return {
    invoiceId: raw.id,
    uuid: raw.uuid,
    invoiceNumber: raw.invoiceNumber,
    billNo: raw.billNo || '',
    invoiceType: raw.invoiceType,
    seqNumber: raw.id,
    email: raw.email,
    ...(raw.salesEmail && { salesEmail: raw.salesEmail }),
    emailStatus: deriveEmailStatus(raw),
    address1: raw.address1,
    address2: raw.address2,
    customerCode: raw.customerCode,
    customerName: raw.customerName,
    customerOwner: raw.customerOwner,
    pastDue: derivePastDue(raw),
    // TODO: revert back to raw.amount - response is wrong
    amount: Number(raw.totalAmount),
    ...(raw.tax && { tax: Number(raw.tax) }),
    ...(raw.message && { message: raw.message }),
    ...(raw.note && { note: raw.note }),
    userName: (raw.userFirst || '') + ' ' + (raw.userLast || ''),
    status: deriveStatus(raw),
    dueDate: raw.dueDate,
    ...(raw.importedDate && { importedDate: raw.importedDate }),
    ...(raw.billDate && { billDate: raw.billDate }),
    ...(raw.projNo && { projNo: raw.projNo }),
    ...(raw.sentAt && { sentAt: toLocal(raw.sentAt) }),
    createdAt: toLocal(raw.deleted_at || raw.createdAt),
    datetime: toLocal(deriveTime(raw)),
    paidAt: toLocal(raw.paidAt),
    className: raw.deleted_at ? 'deleted' : '',
  };
};

// DATA GENERATOR json-generator.com
//
// {
//   totalSize: 535,
//   list: [
//     '{{repeat(5, 300)}}',
//     {
//       id: "{{index() + 1}}",
//       uuid: "{{guid()}}",
//       invoiceType: "{{random('Item Sales', 'Reg Bill', 'PPI')}}",
//       invoiceNumber: "{{integer(1000000, 9999999)}}",
//       seqNumber: "{{index() + 1}}",
//       customerCode: "{{integer(1000000, 9999999)}}",
//       customerName: "{{firstName() + ' ' + surname()}}",
//       email: "{{email()}}",
//       totalAmount: "{{floating(10, 1100, 2)}}",
//       notes: function (t) {
//         if (t.integer(0, 10) > 8) return t.lorem();
//         else return '';
//       },
//       userName: "{{firstName() + ' ' + surname()}}",
//       dueDate: "{{date(new Date(), new Date(2021, 7, 10), 'YYYY-MM-dd')}}",
//       createdAt: "{{date(new Date(2021, 0, 1), new Date(), 'YYYY-MM-dd HH:mm:ss')}}",
//       actionedAt: "{{date(new Date(2021, 3, 1), new Date(), 'YYYY-MM-dd HH:mm:ss')}}",
//       sentAt: "{{date(new Date(2021, 3, 1), new Date(), 'YYYY-MM-dd HH:mm:ss')}}",
//       viewedAt: "{{date(new Date(2021, 3, 1), new Date(), 'YYYY-MM-dd HH:mm:ss')}}",
//       paidAt: "{{date(new Date(2021, 3, 1), new Date(), 'YYYY-MM-dd HH:mm:ss')}}"
//     }
//   ]
// }
