import React from 'react';
import CloseIcon from '@material-ui/icons/Close';

import { ButtonWrapper } from './styles';

interface CloseButtonProps {
  onClick: Function;
}

const CloseButton: React.FC<CloseButtonProps> = ({ onClick }) => {
  return (
    <ButtonWrapper onClick={onClick}>
      <CloseIcon />
    </ButtonWrapper>
  );
};

export default CloseButton;
