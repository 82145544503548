import React from 'react';
import intl from '$intl';
import { Div, Space, SPACE } from '$gstyles';
import CloseIcon from '@material-ui/icons/Close';

interface InvalidSectionProps {
  isExpired: boolean;
  isDeleted: boolean;
}

const InvalidSection: React.FC<InvalidSectionProps> = ({ isExpired, isDeleted }) => {
  const titleKey = isExpired
    ? 'SCREEN.INVALID.TITLE_EXPIRED'
    : isDeleted
    ? 'SCREEN.INVALID.TITLE_DELETED'
    : 'SCREEN.INVALID.TITLE_INVALID';
  return (
    <Space.All padding={SPACE.XLARGE}>
      <div className="center icon-wrapper close">
        <CloseIcon className="close-icon" />
      </div>
      <h2>{intl(titleKey)}</h2>
      <Div className="message">{intl('SCREEN.INVALID.MESSAGE')}</Div>
    </Space.All>
  );
};

export default InvalidSection;
