import _ from 'lodash';
import React, { Component } from 'react';
import { IonIcon } from '@ionic/react';
import { searchSharp, closeSharp } from 'ionicons/icons';
import Autosuggest from 'react-autosuggest';

import { fetchApi } from '$gbusiness/services/api';
import { Wrapper } from './styles';

interface AutoCompleteProps {
  AC: any;
  endpoint: string;
  history?: any;
  width?: string;
  height?: string;
  placeholder?: string;
  throttle?: number;
  hideSearchIcon?: boolean;
  extraParam?: any;
}

interface StateModel {
  value: any;
  query: string;
  suggestions: Array<any>;
}

class Autocomplete extends Component<AutoCompleteProps> {
  state: StateModel = {
    value: null,
    query: '',
    suggestions: [],
  };

  static defaultProps: AutoCompleteProps = {
    AC: {},
    endpoint: '',
    width: 'auto',
    height: '42px',
    placeholder: '',
    throttle: 300,
  };

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
      query: newValue,
    });
  };

  onSelect = (suggestion, i) => {
    const { AC, history: h } = this.props;
    if (AC.onSelect) AC.onSelect(this.state.value, h);
  };

  renderSuggestion = suggestion => <div>{this.props.AC.renderSuggestion(suggestion)}</div>;

  getSuggestionValue = e => {
    const { AC } = this.props;
    if (AC.getLabel) return AC.getLabel(e);
    return '';
  };

  loadSuggestions = async query => {
    const { AC } = this.props;
    const { extraParam = {}, method, deriveRaw, minChar = 2, isPublic, onResponse, clearOnFetch } = AC;
    if (query && query.length < minChar) return;

    const response = await fetchApi({
      url: this.props.endpoint + (method === 'GET' ? `/${query}` : ''),
      param: {
        ...extraParam,
        query,
      },
      ...(isPublic && { isPublic: true }),
      method: method || 'POST',
    });
    if (onResponse) {
      onResponse(response, this.state.query);
      if (clearOnFetch) this.onClearInput();
    }
    this.setState({
      suggestions: deriveRaw ? deriveRaw(response) : response.list || [],
    });
  };

  onSuggestionHighlighted = ({ suggestion }) => {
    if (!suggestion) return;
    this.setState({ value: suggestion, query: this.props.AC.getLabel(suggestion) });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.debouncedLoadSuggestions(value);
  };

  onSuggestionsClearRequested = () => {
    this.setState({ suggestions: [] });
  };

  onClearInput = () => {
    this.setState({ value: null, query: '' });
  };

  onBlurSelect = (e, { highlightedSuggestion: item }) => {
    const { history } = this.props;
    if (item && this.onSelect) this.onSelect(item, history);
  };

  onKeyPress = e => {
    const { AC } = this.props;
    if ((e.key === 'Enter' || e.keyCode === 13) && AC.onEnter) AC.onEnter();
  };

  debouncedLoadSuggestions = _.debounce(this.loadSuggestions, this.props.throttle || 300);

  render() {
    const { query, suggestions } = this.state;
    const { height, width, placeholder, hideSearchIcon } = this.props;

    const inputProps = {
      placeholder,
      value: query,
      onChange: this.onChange,
      onBlur: this.onBlurSelect,
      onKeyPress: this.onKeyPress,
    };

    return (
      <>
        <Wrapper height={height} width={width} hideSearchIcon={hideSearchIcon} className="ac-wrapper">
          {!hideSearchIcon && <IonIcon icon={searchSharp} slot="start" />}
          <Autosuggest
            id="auto-suggest"
            placeholder={placeholder}
            suggestions={suggestions}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            getSuggestionValue={this.getSuggestionValue}
            renderSuggestion={this.renderSuggestion}
            onSuggestionSelected={this.onSelect}
            onSuggestionHighlighted={this.onSuggestionHighlighted}
            inputProps={inputProps}
          />
          {inputProps.value !== '' && (
            <IonIcon onClick={this.onClearInput} icon={closeSharp} className="pointer clear-button" />
          )}
        </Wrapper>
      </>
    );
  }
}

export default Autocomplete;
