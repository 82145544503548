import React, { useEffect } from 'react';
import intl from '$gintl';

import { Wrapper } from './styles';
import OptionModel from '$gbusiness/models/option';
import { Badge, Tab, Tabs } from '@material-ui/core';

interface TabSimpleProps {
  TABS: Array<OptionModel>;
  index: number;
  onChangeIndex: Function;
  fullWidth?: boolean;
  maxWidth?: string;
  minWidth?: string;
  height?: string;
  itemWidth?: string;
  hasBadge?: boolean;
  upperCase?: boolean;
  variant?: string;
  color?: 'primary' | 'secondary';
  badgeColor?: string;
  className?: string;
}

const TabSimple: React.FC<TabSimpleProps> = ({
  TABS,
  index,
  onChangeIndex,
  maxWidth,
  minWidth,
  itemWidth,
  hasBadge,
  height,
  color = 'primary',
  badgeColor = '#333',
  variant = 'outlined',
  fullWidth = false,
  upperCase = false,
  className = '',
}) => {
  useEffect(() => {
    window.dispatchEvent(new CustomEvent('resize'));
  }, []);

  const handleClick = (e, newIndex: number) => {
    if (newIndex === index) return;
    onChangeIndex(newIndex);
  };

  return (
    <Wrapper
      itemWidth={itemWidth}
      maxWidth={maxWidth}
      minWidth={minWidth}
      fullWidth={fullWidth}
      uppserCase={upperCase}
      className={`${className} ${variant || ''}`}
      height={height}
      textColor={variant === 'filled ' ? 'inherit' : color}
      badgeColor={badgeColor}
      classicMode={variant === 'filled'}>
      <Tabs
        value={index}
        indicatorColor={color}
        textColor={variant === 'filled ' ? 'inherit' : color}
        onChange={handleClick}
        variant="scrollable"
        scrollButtons="auto">
        {TABS.map((tab, i) => {
          const label =
            !tab.count || !hasBadge ? (
              intl(tab.label)
            ) : (
              <Badge badgeContent={tab.count} color="primary">
                {intl(tab.label)}
              </Badge>
            );
          return <Tab key={i} className={hasBadge ? 'count' : ''} label={label} />;
        })}
      </Tabs>
    </Wrapper>
  );
};

export default TabSimple;
