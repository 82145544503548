import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import MuiModal from '$gcomponents/reusables/modal/material';

import { AppModel } from '$business/redux';
import { checkCustomerCode, addCard, fetchCards, resetStatus } from '$business/redux/customer/actions';
import intl from '$intl';
import { Button } from '$gcomponents/primitives';
import { FormSection } from '$gcomponents/reusables';
import { input } from '$ghelpers';

import CardFormModel, { defaultCardForm } from '$business/models/cardForm';
import { CARD_FORM } from '../forms/CardForm';

interface CardFormModalProps {
  initialValues?: CardFormModel;
  onClose: () => void;
  show: boolean;
  fullScreen?: boolean;
}

const CardFormModal: React.FC<CardFormModalProps> = ({
  onClose,
  initialValues = defaultCardForm,
  fullScreen = false,
  show,
}) => {
  const dispatch = useDispatch();
  const formRef = useRef<any>();
  const customer = useSelector((state: AppModel) => state.customer);

  const derivedInitialValues = input.deriveFormInitialValues(CARD_FORM, initialValues);
  const validateForm = values => {
    return input.validateError(CARD_FORM, values);
  };
  const onSubmit = values => {
    dispatch(addCard(values));
  };

  const closeModal = () => {
    dispatch(resetStatus());
    formRef.current.resetForm();
    onClose();
  };

  useEffect(() => {
    formRef.current.resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!customer.isFinished) return;
    const { customer: customerObj } = customer;
    if (customerObj?.customerCode) dispatch(fetchCards(customerObj?.customerCode));
    closeModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer.isFinished]);

  return (
    <Formik
      innerRef={formRef}
      enableReinitialize={true}
      initialValues={{
        ...derivedInitialValues,
      }}
      validate={validateForm}
      onSubmit={onSubmit}>
      {formik => {
        return (
          <MuiModal
            open={show}
            width="400px"
            onClose={closeModal}
            fullScreen={fullScreen}
            title={intl('SCREEN.CARD_MODAL.TITLE')}
            footer={
              <Button
                disabled={!formik.isValid && !formik.dirty}
                color="primary"
                isLoading={customer.isProcessing}
                loadingText={intl('SCREEN.CARD_MODAL.SUBMIT_BUTTON_LOADING')}
                fullWidth
                variant="contained"
                onClick={formik.handleSubmit}>
                {intl('SCREEN.CARD_MODAL.SUBMIT_BUTTON')}
              </Button>
            }>
            <Form>
              <FormSection
                FORM={CARD_FORM}
                formik={formik}
                callback={value => {
                  dispatch(checkCustomerCode(value));
                }}
              />
            </Form>
          </MuiModal>
        );
      }}
    </Formik>
  );
};

export default CardFormModal;
