import React from 'react';

import { configs } from '$configs';
import { SORT, COLORS } from '$gbusiness/enums';
import CellModel from '$gbusiness/models/cell';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { formatDatetime } from '$gbusiness/helpers/date';
import intl from '$intl';
import { deriveRawToTransactions } from '$business/models/transactions';
import { KEYS } from '$business/enums/options/billType';
import { Button, Chip } from '@material-ui/core';
import PopOver from '$gcomponents/reusables/popOver';
import MockData from '$business/mocks/getRecurringTransactions.json';
import { TableModel } from '$gbusiness/models/table';

export const TABLE_CONFIG: TableModel = {
  dataSource: {
    endpoint: configs.api.invoice.list,
    isPublic: true,
    method: 'GET',
    deriveToModel: deriveRawToTransactions,
    mockData: MockData,
    pageSize: 50,
    shouldVirtualize: false,
    virtualized: {
      rowHeight: 36,
      headerHeight: 60,
      checkWidth: 60,
      threshold: 10,
      additionalHeight: 40,
    },
  },
  styles: {
    color: COLORS.SECONDARY,
    cellPadding: `3px`,
    isStripped: true,
    minWidth: 960,
    maxWidth: 1800,
    fontSize: '0.9em',
  },
  filter: {
    color: COLORS.PRIMARY,
  },
};

export const TABLE: Array<CellModel> = [
  {
    label: 'SCREEN.TRANSACTIONS.COLS.TIME',
    value: 'createdAt',
    component: row => <>{row.createdAt ? formatDatetime(row.createdAt) : ''}</>,
    sortable: SORT.ASC,
    width: 250,
  },
  {
    label: 'SCREEN.TRANSACTIONS.COLS.CARD_TYPE',
    value: 'cardType',
    width: 150,
  },
  {
    label: 'SCREEN.TRANSACTIONS.COLS.CARDHOLDER',
    value: 'cardHolder',
    width: 240,
  },
  {
    label: 'SCREEN.TRANSACTIONS.COLS.EXPIRY',
    value: 'expiry',
    width: 100,
  },
  {
    label: 'SCREEN.TRANSACTIONS.COLS.CARD_NUMBER',
    value: 'last4',
    component: row => <>xxx {row.last4}</>,
    width: 180,
  },
  {
    label: 'SCREEN.TRANSACTIONS.COLS.REFUND_STATUS',
    value: 'refundStatus',
    width: 150,
  },
  {
    label: 'SCREEN.TRANSACTIONS.COLS.CUSTOMER_CODE',
    value: 'customerCode',
    sortable: SORT.ASC,
    width: 160,
  },
  {
    label: 'SCREEN.TRANSACTIONS.COLS.INVOICE_TYPE',
    value: 'invoiceType',
    component: row => {
      const color =
        row.invoiceType === KEYS.CONTRACT
          ? 'primary'
          : row.invoiceType === KEYS.PPI
          ? 'tertiary'
          : row.invoiceType === KEYS.SALES
          ? 'SALES'
          : 'default';
      return (
        <Chip
          variant="outlined"
          className={`${color} outlined`}
          size="small"
          label={intl('INPUT.OPTION.BILLING_TYPE.' + row.invoiceType) || 'Unknown'}
        />
      );
    },
    sortable: SORT.ASC,
    sortKey: 'invoiceType',
    width: 170,
  },
  {
    label: 'SCREEN.TRANSACTIONS.COLS.AMOUNT',
    value: 'amount',
    sortable: SORT.DSC,
    component: row => <>{intl('PRICE', { amount: row.amount })}</>,
    width: 150,
  },
  {
    label: 'SCREEN.TRANSACTIONS.COLS.PAYMENT_STATUS',
    value: 'paymentResult',
    component: row => {
      const color = row.paymentResult === 'Declined' ? 'danger' : 'success';
      return <div className={`${color} outlined`}>{row.paymentResult}</div>;
    },
    width: 150,
  },
  {
    label: 'SCREEN.TRANSACTIONS.COLS.USER',
    value: 'userName',
    sortable: SORT.ASC,
    sortKey: 'userLast',
    width: 180,
  },
  {
    label: 'SCREEN.TRANSACTIONS.COLS.ACTION',
    value: '',
    align: 'center',
    component: (row, actions) => {
      return (
        <PopOver component={<MoreHorizIcon className="pointer" />}>
          <>
            {!row.voidAt && !row.refundAt && (
              <>
                <Button
                  className="left"
                  fullWidth
                  onClick={() => actions.voidTransaction(row, actions.onRefresh)}>
                  {intl('SCREEN.INVOICE.ACTIONS.VOID')}
                </Button>
                <Button
                  className="left"
                  fullWidth
                  onClick={() => actions.refundTransaction(row, actions.onRefresh)}>
                  {intl('SCREEN.INVOICE.ACTIONS.REFUND')}
                </Button>
              </>
            )}
            <Button className="left" fullWidth onClick={() => actions.viewReceipt(row)}>
              {intl('SCREEN.INVOICE.ACTIONS.VIEW_RECEIPT')}
            </Button>
          </>
        </PopOver>
      );
    },
    width: 100,
  },
];
