import styled from 'styled-components';
import { IonButton } from '@ionic/react';

export const InputWrapper = styled.div`
  height: ${p => (p.size ? p.size * 2 : 32)}px;
  width: ${p => (p.size ? p.size * 8 : 128)}px;
  overflow: hidden;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: space-between;
  color: #333;
  background-color: #fff;
  border-radius: 1px;
  div {
    flex: 1;
    font-size: ${p => (p.size ? p.size : 24)}px;
  }
  border: 1px solid #ccc;

  svg {
    font-size: ${p => (p.size ? p.size : 24)}px;
  }
  button {
    color: ${p => (p.color ? `var(--ion-color-${p.color}-contrast-bw);` : 'white')};
    background-color: ${p => (p.color ? `var(--ion-color-${p.color});` : '#333')};
    border-radius: 0;
  }
`;

export const QuantityButton = styled(IonButton)`
  margin: 0;
  display: block;
  height: 100%;
  width: 60px
  --box-shadow: none;
  --background: ${p => (p.color ? `var(--ion-color-${p.color}-contrast-bw);` : 'white')};
  color: ${p => (p.color ? `var(--ion-color-${p.color});` : '#333')};
  ${p => p.color || 'transparent'};
  */--border-radius: 0;
`;
