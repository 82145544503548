import { CLOSE_LOGIN_MODAL, LOADING, LoadingActionTypes, LOADING_MODAL, SHOW_LOGIN_MODAL } from './types';

export function loading(text = undefined): LoadingActionTypes {
  return {
    type: LOADING,
    loadingText: text,
  };
}

export function loadingModal(text = undefined): LoadingActionTypes {
  return {
    type: LOADING_MODAL,
    loadingText: text,
  };
}

export function showLoginModal(): LoadingActionTypes {
  return {
    type: SHOW_LOGIN_MODAL,
  };
}

export function closeLoginModal(): LoadingActionTypes {
  return {
    type: CLOSE_LOGIN_MODAL,
  };
}
