import styled from 'styled-components';
import { Div, SPACE } from '$gstyles';

export const Wrapper = styled(Div)``;

export const EmptyWrapper = styled(Div)`
  text-align: center;
  position: absolute;
  left: 0;
  top: 100px;
  bottom: 0;
  right: 0;
`;

export const Circle = styled(Div)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 461px;
  height: 461px;
  max-width: 95vmin;
  max-height: 95vmin;
  background: #f2f2f2;
  border-radius: 50%;

  .receipt-icon {
    color: #bdbdbd;
    font-size: 90px;
    margin: ${SPACE.XLARGE};
  }
`;
