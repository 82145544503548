export const KEYS = {
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  BI_WEEKLY: 'BI_WEEKLY',
  SEMI_MONTHLY: 'SEMI_MONTHLY',
  MONTHLY: 'MONTHLY',
  BI_MONTHLY: 'BI_MONTHLY',
  QUARTERLY: 'QUARTERLY',
  FOUR_MONTHS: 'FOUR_MONTHS',
  SEMI_ANNUALLY: 'SEMI_ANNUALLY',
  ANNUALLY: 'ANNUALLY',
};

const LIST = Object.keys(KEYS);

export default LIST.map(item => ({
  label: `RECURRING_INTERVAL.${item}`,
  value: item,
}));

export type DATE_RANGE_TYPE = typeof LIST[number] | undefined;
