import { BILL_TYPE } from '$business/enums/options';
import { INPUT, INPUT_TYPES } from '$gbusiness/enums';
import InputRowModel from '$gbusiness/models/inputRow';
import { input } from '$ghelpers';

export const TRANSACTION_FORM: Array<InputRowModel> = [
  {
    items: [
      {
        ...INPUT_TYPES.AMOUNT,
        gridSize: 6,
        validation: [input.requiredValidation],
      },
      {
        label: 'INPUT.LABEL.BILL.BILL_TYPE',
        value: 'invoiceType',
        input: INPUT.SELECT,
        options: BILL_TYPE,
        gridSize: 6,
        validation: [
          input.requiredValidation,
          {
            condition: v => v > 0,
            message: 'OVER_ZERO',
          },
        ],
      },
    ],
  },
];

export const TRANSACTION_MESSAGE_FORM: Array<InputRowModel> = [
  {
    items: [
      {
        ...INPUT_TYPES.AMOUNT,
        gridSize: 6,
        validation: [input.requiredValidation],
      },
      {
        label: 'INPUT.LABEL.BILL.BILL_TYPE',
        value: 'invoiceType',
        input: INPUT.SELECT,
        options: BILL_TYPE,
        gridSize: 6,
        validation: [
          input.requiredValidation,
          {
            condition: v => v > 0,
            message: 'OVER_ZERO',
          },
        ],
      },
    ],
  },
  {
    items: [
      {
        label: 'INPUT.LABEL.BILL.EMAIL_MESSAGE',
        value: 'message',
        input: INPUT.TEXT,
        gridSize: 12,
      },
    ],
  },
];
