import { ROUTES } from '$gbusiness/enums';
import { UserScreen } from '$gbox/screens';
import RouteModel from '$gbusiness/models/route';

import {
  // HomeScreen,
  ProfileScreen,
  InvoiceLinkScreen,
  PaymentScreen,
  MakeLinkScreen,
  MakePaymentScreen,
  MakeRecurringScreen,
  // RecurringsScreen,
  RecurringTransactionsScreen,
  TransactionsScreen,
  SendBillsScreen,
  ActionRequiredScreen,
} from '$product/screens';

const { USERS } = ROUTES;

// IMPORTS FROM GBOX

export const HOME = '/home';
export const PROFILE = '/profile';
export const INVOICES = '/bills';
export const TRANSACTIONS = '/transactions';
export const INVOICE_LINKS = '/invoices/links';
export const RECURRINGS = '/recurrings';
export const RECURRING = '/recurrings';
export const PAYMENT = '/payment';
export const MAKE_LINK = '/makelink';
export const MAKE_PAYMENT = '/makepayment';
export const MAKE_RECURRING = '/makerecurring';
export const SEND_BILL = '/bills';
export const ACTIONS = '/actions';

export const PROFILE_ROUTE = {
  title: 'MENU.PROFILE',
  path: PROFILE,
  component: ProfileScreen,
};

export const SEND_BILLS_ROUTE = {
  title: 'MENU.SEND_BILLS',
  path: INVOICES,
  component: SendBillsScreen,
};

export const ACTION_REQUIRED_ROUTE = {
  title: 'MENU.ACTION_REQUIRED',
  path: ACTIONS,
  component: ActionRequiredScreen,
};

export const INVOICE_LINKS_ROUTE = {
  title: 'MENU.PAYMENT_LINK',
  path: INVOICE_LINKS,
  component: InvoiceLinkScreen,
};

export const RECURRING_ROUTE = {
  path: RECURRING,
  param: ':subscriptionId',
  component: RecurringTransactionsScreen,
  exact: true,
};

export const TRANSACTIONS_ROUTE = {
  title: 'MENU.TRANSACTIONS',
  path: TRANSACTIONS,
  component: TransactionsScreen,
};

export const MAKE_LINK_ROUTE = {
  path: MAKE_LINK,
  component: MakeLinkScreen,
  exact: true,
};

export const MAKE_PAYMENT_ROUTE = {
  path: MAKE_PAYMENT,
  component: MakePaymentScreen,
  exact: true,
};

export const MAKE_RECURRING_ROUTE = {
  path: MAKE_RECURRING,
  component: MakeRecurringScreen,
  exact: true,
};

export const USERS_ROUTE = {
  path: USERS,
  param: ':userId',
  component: UserScreen,
  exact: true,
};

export const LIST: Array<RouteModel> = [
  SEND_BILLS_ROUTE,
  ACTION_REQUIRED_ROUTE,
  INVOICE_LINKS_ROUTE,
  RECURRING_ROUTE,
  MAKE_LINK_ROUTE,
  MAKE_PAYMENT_ROUTE,
  MAKE_RECURRING_ROUTE,
  TRANSACTIONS_ROUTE,
  USERS_ROUTE,
  PROFILE_ROUTE,
];

export const PUBLIC_LIST = [
  ...ROUTES.PUBLIC_LIST,
  {
    path: PAYMENT,
    param: ':uuid',
    component: PaymentScreen,
    exact: true,
  },
];

export const MENU_GATEWAY = [INVOICE_LINKS_ROUTE, TRANSACTIONS_ROUTE];
export const MENU_BILL = [SEND_BILLS_ROUTE, ACTION_REQUIRED_ROUTE];
export const defaultRoute = SEND_BILLS_ROUTE;
export const DEFAULT_APP_ROUTE = defaultRoute.path;
