import styled from 'styled-components';
import { Flex } from '$gstyles/wrapper';
import { SPACE } from '$gstyles';
import { mobileStyle } from '$gstyles/utils';

export const Wrapper = styled(Flex)`
  .MuiButton-root {
    opacity: 0.5;
    font-weight: 600;
    text-transform: initial;
  }
  .MuiButton-contained {
    opacity: 1;
  }
  label {
    margin-right: ${SPACE.MEDIUM};
  }

  ${mobileStyle(`
    display: block;
    label {
      display: block;
      margin-top: ${SPACE.SMALL};
      margin-bottom: ${SPACE.SMALL};
    }
  `)}
`;
