import React, { useState } from 'react';
import { connect } from 'react-redux';

import { screen } from '$gcomponents/hoc';
import { TABLE_CONFIG, TABLE } from './recurringTransactionsTable';
import { Table } from '$gcomponents/reusables';
import { subscriptionActions } from '$business/redux/subscription';
import { invoiceActions } from '$business/redux/invoice';
import MuiModal from '$gcomponents/reusables/modal/material';
import TransactionModel from '$business/models/transactions';
import ReceiptDetails from '../../transactionsScreen/components/receiptDetails';
import { Header } from '$gcomponents/widgets';
import { IonContent } from '@ionic/react';

import { IonPageWrapper } from './styles';
import { SPACE, Space } from '$gstyles';
import SubscriptionModel from '$business/models/subscription';
import TopSection from './topSection';
import { ROUTES } from '$business/enums';

interface RecurringTransactionsScreenProps {
  history: any;
  onHydrate: Function;
  onDehydrate: Function;
  subscription: SubscriptionModel;
  transactions: Array<TransactionModel>;
  voidTransaction: Function;
  refundTransaction: Function;
}

const RecurringTransactionsScreen: React.FC<RecurringTransactionsScreenProps> = ({
  history,
  voidTransaction,
  refundTransaction,
  transactions,
  subscription,
}) => {
  const [receipt, setShowReceipt] = useState<TransactionModel | null>(null);
  if (!subscription) return <IonPageWrapper />;

  const itemActions = {
    viewReceipt: row => {
      setShowReceipt(row);
    },
    voidTransaction: async (row, onRefresh) => {
      await voidTransaction(row.transactionId);
      onRefresh();
    },
    refundTransaction: async (row, onRefresh) => {
      await refundTransaction(row.transactionId);
      onRefresh();
    },
  };

  const handleBack = () => {
    history.push(ROUTES.RECURRINGS);
  };

  const {
    styles: { color, cellPadding, minWidth, maxWidth, isStripped, fontSize },
  } = TABLE_CONFIG;

  return (
    <>
      <IonPageWrapper>
        <Header title="SCREEN.RECURRINGS.TITLE_DETAILS" />
        <IonContent>
          <Space.All padding={SPACE.LARGE}>
            <TopSection subscription={subscription} onBack={handleBack} />
            <Table
              data={transactions}
              display={{ pageSize: 200, page: 1, totalDataSize: transactions.length }}
              styles={{
                color,
                cellPadding,
                minWidth,
                maxWidth,
                isStripped,
                fontSize,
              }}
              itemActions={itemActions}
              TABLE={TABLE}
            />
          </Space.All>
        </IonContent>
      </IonPageWrapper>
      <MuiModal open={!!receipt} onClose={() => setShowReceipt(null)}>
        <ReceiptDetails transaction={receipt} />
      </MuiModal>
    </>
  );
};

const mapStateToProps = state => ({
  transactions: state.subscription.transactions,
  subscription: state.subscription.subscription,
  resetOnRoute: true,
});

const mapDispatchToProps = {
  onHydrate: param => subscriptionActions.getSubscription(param.subscriptionId),
  onDehydrate: subscriptionActions.dehydrate,
  voidTransaction: invoiceActions.voidTransaction,
  refundTransaction: invoiceActions.refundTransaction,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(RecurringTransactionsScreen));
