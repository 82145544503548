export const KEYS = {
  SALES: 'SALES',
  PPI: 'PPI',
  CONTRACT: 'CONTRACT',
  KAVO: 'KAVO',
  NSK: 'NSK',
  AIC: 'AIC',
  SERVICE_FEE: 'SERVICE_FEE',
};

const LIST = Object.keys(KEYS);

export default LIST.map(item => ({
  label: `BILLING_TYPE.${item}`,
  value: item,
}));

export type DATE_RANGE_TYPE = typeof LIST[number] | undefined;
