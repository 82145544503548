import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { format as dateFormat } from 'date-fns';
import { Formik, Form } from 'formik';
import { IonContent, IonFooter } from '@ionic/react';

import { customerActions, customerActionTypes } from '$business/redux/customer';
import { invoiceActions, invoiceActionTypes } from '$business/redux/invoice';
import intl from '$intl';
import { Button, MCheckbox, CloseButton, HeaderTitle } from '$gcomponents/primitives';
import { FormSection } from '$gcomponents/reusables';
import { input } from '$ghelpers';
import { screen } from '$gcomponents/hoc';
import { Div, H2, Inline, SPACE, Space } from '$gstyles';
import { Flex } from '$gstyles/wrapper';
import CardFormModal from '$components/cardFormModal';
import UserModel from '$gbusiness/models/user';
import CardList from '$components/cardFormModal/cardList';
import CardFormModel, { defaultCardForm } from '$business/models/cardForm';
import { currency } from '$ghelpers/util';
import { ROUTES } from '$business/enums';

import { CUSTOMER_FORM } from '$components/forms/CustomerForm';
import { RECURRING_FORM } from '$components/forms/RecurringForm';
import { TRANSACTION_FORM } from '$components/forms/Transaction';
import { IonPageWrapper } from './styles';
import { defaultRecurringForm } from '$business/models/recurringForm';
import { DATE_FORMATS } from '$gbusiness/enums';

const initialFormValues = {
  ...defaultRecurringForm,
  ...(process.env.REACT_APP_ENV === 'dev' && { customerCode: '07650023' }),
};

interface MakeRecurringScreenProps {
  history: any;
  user: UserModel;
  invoice: invoiceActionTypes.InvoiceReducerType;
  customer: customerActionTypes.CustomerReducerType;
  createSubscription: Function;
  checkCustomerCode: Function;
  fetchCards: Function;
  dehydrate: Function;
}

const MakeRecurringScreen: React.FC<MakeRecurringScreenProps> = ({
  invoice,
  customer: customerReducer,
  createSubscription,
  fetchCards,
  checkCustomerCode,
  history,
  user,
}) => {
  const formRef = useRef<any>();
  const [cardIndex, setCardIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const { isFinished, isProcessing } = invoice;
  const { isValidCustomerCode, cards, customer } = customerReducer;
  const derivedInitialValues = {
    ...input.deriveFormInitialValues(CUSTOMER_FORM, initialFormValues),
    ...input.deriveFormInitialValues(RECURRING_FORM, initialFormValues),
    ...input.deriveFormInitialValues(TRANSACTION_FORM, initialFormValues),
  };

  const initialCardValues: CardFormModel = {
    ...defaultCardForm,
    customerCode: customer?.customerCode || '',
    ...(formRef?.current && {
      cardHolder: formRef.current.values.customerName,
      address: formRef.current.values.address1,
      zip: formRef.current.values.zip,
    }),
  };

  const validateForm = values => {
    return input.validateError([...CUSTOMER_FORM, ...RECURRING_FORM, ...TRANSACTION_FORM], values);
  };
  const onSubmit = values => {
    const { cardIndex, startDate, endDate, ...newValues } = values;
    const selectedCard = cards[cardIndex];
    createSubscription({
      ...newValues,
      startDate: dateFormat(startDate, DATE_FORMATS.DATE),
      ...(values.isRecurring && { endDate: dateFormat(endDate, DATE_FORMATS.DATE) }),
      userId: user.userId,
      cardId: selectedCard.cardId,
    });
  };
  const closeScreen = () => {
    history.goBack();
  };

  // After successfully submitted dehydrate and dismiss
  useEffect(() => {
    if (!invoice.isFinished) return;
    if (formRef?.current && formRef.current.resetForm) formRef.current.resetForm();
    // closeScreen();
    history.replace(`${ROUTES.RECURRINGS}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFinished]);

  // Once customer code has been fetched
  useEffect(() => {
    if (!isValidCustomerCode || !customer) return;
    const { address1, address2, email, name, zip } = customer;
    formRef.current.setFieldValue('customerName', name);
    const customerEmail = process.env.REACT_APP_DEFAULT_EMAIL || email;
    formRef.current.setFieldValue('email', customerEmail);
    formRef.current.setFieldValue('address1', address1);
    formRef.current.setFieldValue('address2', address2);
    formRef.current.setFieldValue('zip', zip);
    formRef.current.setFieldValue('cardIndex', 0);
    fetchCards(customer.customerCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer?.customerCode]);

  useEffect(() => {
    formRef.current.resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <IonPageWrapper>
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        initialValues={derivedInitialValues}
        validate={validateForm}
        onSubmit={onSubmit}>
        {formik => {
          const { values } = formik;
          const amountText = values?.amount ? ` - ${currency(values?.amount)}` : '';
          return (
            <>
              <IonContent>
                <CloseButton onClick={closeScreen} />
                <Div padding={SPACE.LARGE} maxWidth="800px">
                  <H2 textAlign="left">{intl('SCREEN.MAKE_RECURRING.TITLE')}</H2>
                  <Form>
                    <Space.Ver padding={SPACE.SMALL} />
                    <FormSection
                      title="SCREEN.MAKE_RECURRING.SUBTITLE"
                      FORM={CUSTOMER_FORM}
                      formik={formik}
                      callback={value => {
                        checkCustomerCode(value);
                      }}
                    />
                    <HeaderTitle marginBottom={SPACE.DEF}>
                      {intl('SCREEN.MAKE_RECURRING.SUBTITLE2')}
                      <Inline marginLeft={SPACE.LARGE}>
                        <MCheckbox
                          name="isRecurring"
                          label="INPUT.LABEL.RECURRING.RECURRING"
                          handleChange={e => formik.setFieldValue('isRecurring', e.target.checked)}
                        />
                      </Inline>
                    </HeaderTitle>
                    <FormSection FORM={RECURRING_FORM} formik={formik} />
                    <CardList
                      title="SCREEN.MAKE_RECURRING.SUBTITLE3"
                      cards={isValidCustomerCode ? cards : undefined}
                      onCardSelect={i => setCardIndex(parseInt(i))}
                      onOpenCardModal={() => setShowModal(true)}
                      cardIndex={cardIndex}
                    />
                    <FormSection
                      title="SCREEN.MAKE_RECURRING.SUBTITLE4"
                      FORM={TRANSACTION_FORM}
                      formik={formik}
                    />
                  </Form>
                  {/* {invoice.isValidCustomerCode && <div>{intl('MESSAGE.VALID_CUSTOMER_CODE')}</div>}
                  {invoice.isValidCustomerCode === false && (
                    <div>{intl('MESSAGE.INVALID_CUSTOMER_CODE')}</div>
                  )} */}
                </Div>
              </IonContent>
              <IonFooter>
                <Flex padding={SPACE.MEDIUM} justifyContent="space-between">
                  <Button variant="outlined" onClick={closeScreen}>
                    {intl('BUTTON.CANCEL')}
                  </Button>
                  <Button
                    disabled={!(formik.isValid && isValidCustomerCode)}
                    color="primary"
                    className="submit-button"
                    isLoading={isProcessing}
                    loadingText={intl('SCREEN.MAKE_RECURRING.SUBMIT_BUTTON_LOADING')}
                    variant="contained"
                    onClick={formik.handleSubmit}>
                    {intl('SCREEN.MAKE_RECURRING.SUBMIT_BUTTON', { amount: amountText })}
                  </Button>
                </Flex>
              </IonFooter>
            </>
          );
        }}
      </Formik>
      <CardFormModal initialValues={initialCardValues} onClose={() => setShowModal(false)} show={showModal} />
    </IonPageWrapper>
  );
};

const mapStateToProps = state => ({
  user: state.currentUser,
  invoice: state.invoice,
  customer: state.customer,
  resetOnRoute: true,
});

const mapDispatchToProps = {
  onDehydrate: () => {
    invoiceActions.dehydrate();
    return customerActions.dehydrate();
  },
  checkCustomerCode: customerActions.checkCustomerCode,
  createSubscription: invoiceActions.createSubscription,
  fetchCards: customerActions.fetchCards,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(MakeRecurringScreen));
