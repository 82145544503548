import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, FONT, Row, Space, SPACE } from '$gstyles';
import { Form, Formik } from 'formik';

import { input } from '$ghelpers';
import { dialog, FormSection, SplitButton } from '$gcomponents/reusables';

import { BILL_FORM } from '$components/forms/BillForm';
import { Flex } from '$gstyles/wrapper';
import { Button } from '$gcomponents/primitives';
import intl from '$intl';
import { IonContentWrapper, IonFooterWrapper } from './styles';
import InvoiceModel from '$business/models/invoice';
import DetailSection from '$components/detailSection';
import { IonButton, IonButtons, IonHeader, IonTitle, IonToolbar } from '@ionic/react';
import { PAID } from '$business/enums/invoiceStatus';
import { KEYS as EMAIL_STATUS } from '$business/enums/options/emailStatus';
import { invoiceActions } from '$business/redux/invoice';
import { AppModel } from '$business/redux';
import { format } from '$ghelpers/date';
import { DATE_FORMATS } from '$gbusiness/enums';

interface InvoiceDetailsScreenProps {
  invoice: InvoiceModel;
  editMode?: boolean;
  onClose: () => void;
  onSave: () => void;
}

const InvoiceDetailsScreen: React.FC<InvoiceDetailsScreenProps> = ({
  invoice,
  editMode = false,
  onClose,
  onSave,
}) => {
  const dispatch = useDispatch();
  const isFinished = useSelector((state: AppModel) => state.invoice.isFinished);
  useEffect(() => {
    if (!isFinished) return;
    dispatch(invoiceActions.clearInvoiceStatus());
    onClose();
    onSave();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFinished]);

  const [isEditMode, setIsEditMode] = useState(editMode);
  if (!invoice) return null;

  const { status, billNo, invoiceId, salesEmail, email, emailStatus } = invoice;
  const validateForm = values => {
    return input.validateError(BILL_FORM, values);
  };

  const derivedInitialValues = input.deriveFormInitialValues(BILL_FORM, {
    ...invoice,
    createdAt: format(invoice.createdAt, DATE_FORMATS.SLASH),
    sentAt: format(invoice.sentAt, DATE_FORMATS.DATETIME),
  });
  const title = intl(`SCREEN.BILL_DETAILS.${isEditMode ? 'TITLE' : 'TITLE_VIEW'}`);
  const statusColor = emailStatus === EMAIL_STATUS.FAILED ? 'danger' : 'success';

  const onSubmit = values => {
    const { createdAt, seqNumber, sentAt, ...newValues } = values;
    dispatch(
      invoiceActions.updateInvoice({
        ...newValues,
        invoiceId,
      }),
    );
  };
  const onCancel = () => {
    onClose();
  };

  const emailBill = (email, isSales = false) => {
    if (!email) {
      dialog.alert({ message: 'ERROR.INVALID_EMAIL' });
      return;
    }
    dialog.confirm({
      message: isSales ? 'MESSAGE.SEND_BILL_WARNING_SALES' : 'MESSAGE.SEND_BILL_WARNING_ONE',
      key: { email },
      onPress: () => {
        dispatch(invoiceActions.sendInvoice([invoiceId], email));
      },
    });
  };
  const onEmailSales = () => {
    emailBill(salesEmail, true);
  };
  const onEmailCustomer = () => {
    emailBill(email);
  };

  const emailOptions = [
    { label: intl('SCREEN.BILL_DETAILS.BUTTON_EMAIL_SALES'), onClick: onEmailSales },
    { label: intl('SCREEN.BILL_DETAILS.BUTTON_EMAIL_CUSTOMER'), onClick: onEmailCustomer },
  ];

  return (
    <Formik
      enableReinitialize={true}
      initialValues={derivedInitialValues}
      validate={validateForm}
      onSubmit={values => {
        onSubmit(values);
      }}>
      {formik => (
        <>
          <IonHeader>
            <IonToolbar>
              <IonTitle>{title}</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={onClose}>{intl('BUTTON.CLOSE')}</IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContentWrapper>
            <Space.All margin={SPACE.LARGE}>
              <Row marginLeft={SPACE.SMALL}>
                <Col gridSize={6} className="flex" fontSize={FONT.MEDIUM}>
                  <Flex>
                    <div>{billNo}</div>
                    <div className={`status outlined ${statusColor}`}>
                      {intl('STATUS.EMAIL.' + emailStatus)}
                    </div>
                  </Flex>
                </Col>
                <Col gridSize={6}>
                  {status !== PAID && (
                    <SplitButton options={emailOptions} color="secondary" defaultIndex={0} />
                  )}
                </Col>
              </Row>
              {isEditMode && (
                <Form>
                  <FormSection FORM={BILL_FORM} formik={formik} />
                </Form>
              )}
              {!isEditMode && (
                <DetailSection formik={formik} FORM={BILL_FORM} onEdit={() => setIsEditMode(true)} />
              )}
            </Space.All>
          </IonContentWrapper>
          {isEditMode && (
            <IonFooterWrapper>
              <Flex padding={SPACE.MEDIUM} justifyContent="space-between">
                <Button variant="outlined" onClick={onCancel}>
                  {intl('BUTTON.CANCEL')}
                </Button>
                <Button
                  disabled={!formik.isValid}
                  color="primary"
                  className="submit-button"
                  variant="contained"
                  onClick={formik.handleSubmit}>
                  {intl('BUTTON.UPDATE')}
                </Button>
              </Flex>
            </IonFooterWrapper>
          )}
        </>
      )}
    </Formik>
  );
};

export default InvoiceDetailsScreen;
