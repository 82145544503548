import React, { useState } from 'react';
import intl from '$intl';
import InvoiceModel from '$business/models/invoice';
import { Col, Div, Row, Space, SPACE } from '$gstyles';
import { Flex } from '$gstyles/wrapper';
import { Divider } from '@material-ui/core';
import { configs } from '$configs';
import { format } from '$ghelpers/date';
import { Button } from '$gcomponents/primitives';
import TransactionHistoryModal from './transactionHistoryModal';
import ChangeRequestModal from './changeRequestModal';
import { useDispatch } from 'react-redux';
import { customerActions } from '$business/redux/customer';

interface InvoiceSectionProps {
  invoice: InvoiceModel;
  transactions: Array<any>;
}

const InvoiceSection: React.FC<InvoiceSectionProps> = ({ invoice, transactions }) => {
  const {
    invoiceNumber,
    email,
    customerCode,
    customerName,
    customerOwner,
    address1,
    address2,
    billDate,
    createdAt,
    tax,
    projNo,
    amount,
  } = invoice;

  const dispatch = useDispatch();
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [showRequestModal, setShowRequestModal] = useState(false);

  const total = (tax || 0) + amount;
  const totalCurrency = intl('PRICE', { amount: total });

  // Methods
  const t = key => intl(`SCREEN.PAYMENT.${key}`);

  const ItemSection = ({ left, right }) => {
    return (
      <Flex paddingTop={SPACE.MEDIUM}>
        <Div flex={1} className="left">
          {left}
        </Div>
        <Div flex={1} className="right">
          {right}
        </Div>
      </Flex>
    );
  };

  const viewTransactionHistory = async () => {
    const param = {
      customerCode,
      billDate,
      projNo,
    };
    if (!transactions.length) dispatch(customerActions.fetchTransactionHistory(param));
    setShowHistoryModal(true);
  };

  const handleClose = async () => {
    setShowRequestModal(false);
    setShowHistoryModal(false);
  };

  return (
    <Space.All padding={SPACE.SMALL}>
      <Div className="invoice-section">
        <div>
          <Flex className="header-section" justifyContent="space-between">
            <Row flex={1}>
              <Col gridSize={6} className="logo-image">
                <img src={`${configs.display.imagePath}logo2.png`} alt={intl('COMMON.APP_TITLE')} />
              </Col>
              <Col gridSize={6} className="invoice-number">
                {invoiceNumber && <>#{invoice.billNo || invoice.invoiceNumber}</>}
              </Col>
            </Row>
          </Flex>
          <Flex className="client-section" justifyContent="space-between">
            <Row flex={1}>
              <Col gridSize={7} className="left">
                <div className="label">{t('CLIENT_INFO')}</div>
                {customerOwner && <div>{customerOwner}</div>}
                {customerName && <div>{customerName}</div>}
                {email && <div>{email}</div>}
              </Col>
              <Col gridSize={5} className="right">
                <div className="label">{t('BILL_TO')}</div>
                {customerName && <div>{customerName}</div>}
                {address1 && <div>{address1}</div>}
                {address2 && <div>{address2}</div>}
              </Col>
            </Row>
          </Flex>
          <Divider />
          <Flex className="amount-section">
            <Div flex={1}>
              <ItemSection left="Issue Date" right={format(billDate || createdAt, 'MM.dd.yyyy')} />
              <ItemSection left="Amount Due" right={totalCurrency} />
            </Div>
          </Flex>
          <Divider />
          <Flex className="footer-section">
            <Div flex={1}></Div>
            <Div flex={1}>
              <div className="label">Questions?</div>
              <div>Hiossen.Inc</div>
              <div>AR@hiossen.com</div>
              <div>267-988-4510</div>
              <div>Mon - Fri. 9am - 6pm EST</div>
            </Div>
          </Flex>
        </div>
      </Div>
      <Flex className="button-groups">
        <Button className="" onClick={viewTransactionHistory}>
          {intl('SCREEN.PAYMENT.TRANSACTION_HISTORY')}
        </Button>
        <Button className="third" onClick={() => setShowRequestModal(true)}>
          {intl('SCREEN.PAYMENT.REQUEST_CHANGE')}
        </Button>
      </Flex>
      <TransactionHistoryModal show={showHistoryModal} onClose={handleClose} transactions={transactions} />
      <ChangeRequestModal
        invoice={invoice}
        customerCode={customerCode || ''}
        show={showRequestModal}
        onClose={() => setShowRequestModal(false)}
      />
    </Space.All>
  );
};

export default InvoiceSection;
