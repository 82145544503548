import CardModel from '$gbusiness/models/card';

export const IS_PROCESSING = 'IS_PROCESSING_CARD';
export const CARD_FAILURE = 'CUSTOMER_FAILURE';
export const ADD_CARD_SUCCESS = 'ADD_CARD_SUCCESS';
export const SET_DEFAULT_SUCCESS = 'SET_DEFAULT_SUCCESS';
export const LOAD_CARDS_SUCCESS = 'LOAD_CARDS_SUCCESS';
export const DELETE_CARD_SUCCESS = 'DELETE_CARD_SUCCESS';
export const RESET_STATUS = 'RESET_STATUS';
export const RESET_ALL = 'RESET_ALL';

export interface FailureType {
  type: typeof CARD_FAILURE;
  err: string;
}

export interface DeleteCardSuccessType {
  type: typeof DELETE_CARD_SUCCESS | typeof SET_DEFAULT_SUCCESS;
  cardId: number;
}

export interface ResetType {
  type: typeof RESET_ALL | typeof ADD_CARD_SUCCESS | typeof RESET_STATUS;
}

export interface LoadCardsSuccessType {
  type: typeof LOAD_CARDS_SUCCESS;
  cards: Array<CardModel>;
}

export type CardActionTypes = FailureType | ResetType | LoadCardsSuccessType | DeleteCardSuccessType;

export interface CardReducerType {
  cards: Array<CardModel>;
  isProcessing: boolean;
  isFinished: boolean;
}

export const INIT_STATE: CardReducerType = {
  cards: [],
  isProcessing: false,
  isFinished: false,
};
