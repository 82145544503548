import { Dispatch } from 'redux';

import { configs } from '$configs';
import { fetchApi } from '$gbusiness/services/api';
import {
  RESET_ALL,
  INVOICE_FAILURE,
  CREATE_INVOICE_SUCCESS,
  FETCH_INVOICES_SUCCESS,
  InvoiceActionTypes,
  PAYMENT_SUCCESS,
  RESET_SUCCESS,
  SEND_INVOICES_SUCCESS,
  VALIDATE_INVOICE_SUCCESS,
  DELETE_INVOICES_SUCCESS,
  CREATE_SUBSCRIPTION_SUCCESS,
  UPDATE_INVOICE_SUCCESS,
  SUSPEND_SUBSCRIPTION_SUCCESS,
  UNSUSPEND_SUBSCRIPTION_SUCCESS,
  TOKEN_PAYMENT_SUCCESS,
  CLEAR_INVOICE_STATUS,
} from './types';
import { LOADED, LOADING } from '$gbusiness/redux/loading/types';

import { toast } from '$gcomponents/reusables';
import { COLORS } from '$gbusiness/enums';
import { deriveRawToInvoice } from '../../models/invoice';
import { sendInvoicesByChunk, splitList } from './utils';
import loader from '$gcomponents/reusables/loader';
import intl from '$intl';
import { sleep } from '$ghelpers/util';

export function dispatchLoading(dispatch, key = '') {
  dispatch({
    type: LOADING,
    ...(key && { loadingText: key }),
  });
}

export function handleFail(dispatch, err, key, shouldToast = false) {
  const errorKey = key || 'ERROR.SERVER';
  dispatch({
    type: INVOICE_FAILURE,
    err: errorKey,
  });

  if (shouldToast) toast({ text: err, message: errorKey, color: COLORS.DANGER });
}

export function fetchInvoices(values) {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.IMPORTING');

    const response = await fetchApi({
      url: configs.api.invoice.fetch,
      method: 'POST',
      param: values,
    });

    if (!response || !response?.importedBills) {
      handleFail(dispatch, response?.message, 'ERROR.FETCH_FAIL', true);
      return;
    }

    dispatch({
      type: FETCH_INVOICES_SUCCESS,
    });
    toast({
      message: 'MESSAGE.FETCH_BILL_SUCCESS',
      key: { count: response.importedBills },
      color: COLORS.SUCCESS,
    });
  };
}

export function sendInvoice(list, email = '', shouldUpdate = false) {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch);

    const response = await fetchApi({
      url: configs.api.invoice.email,
      param: {
        invoices: JSON.stringify(list),
        email: email || process.env.REACT_APP_DEFAULT_EMAIL || '',
        shouldUpdate,
      },
      // mockData: SendInvoicesMock,
    });

    if (!response || response?.numSuccess === undefined) {
      handleFail(dispatch, response?.err, 'ERROR.SEND_INVOICES_FAIL', true);
      return;
    }

    dispatch({
      type: LOADED,
    });
    toast({ message: 'MESSAGE.INVOICE_SENT', key: { email }, color: COLORS.SUCCESS, cssClass: 'large' });
  };
}

export function sendInvoices(list) {
  return async (dispatch: Dispatch) => {
    const thisLoader = loader({ message: 'PROGRESS.LONG_TIME' });

    const splittedList = splitList(list, 1, 5);
    let current = 0;
    const max = list.length;

    let numResult = [0, 0];
    for (const chunk of splittedList) {
      if (thisLoader) {
        thisLoader.message = intl('PROGRESS.SEND_CHUNK', { current: current + 1, max });
      }

      const response = await sendInvoicesByChunk(chunk);
      numResult[0] += response[0];
      numResult[1] += response[1];
      current += chunk.length;
    }
    if (thisLoader) {
      thisLoader.message = intl('PROGRESS.SEND_CHUNK', { current: numResult[1], max });
      sleep(500);
      thisLoader.dismiss();
    }

    dispatch({
      type: SEND_INVOICES_SUCCESS,
      numFailure: numResult[0],
      numSuccess: numResult[1],
    });
  };
}

export function validateInvoice(uuid) {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch);
    const response = await fetchApi({
      url: configs.api.invoice.validate + uuid,
      method: 'GET',
      isPublic: true,
      // mockData: ValidateInvoiceMock,
    });

    if (!response || !response.status) {
      handleFail(dispatch, response?.err, 'ERROR.ERROR');
      return;
    }

    dispatch({
      type: VALIDATE_INVOICE_SUCCESS,
      status: response.status,
      invoice: response.invoice ? deriveRawToInvoice(response.invoice) : null,
    });
  };
}

export function createPaymentRequest(values) {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.PROCESSING');
    const response = await fetchApi({
      url: configs.api.invoice.create,
      method: 'POST',
      param: values,
      // mockData: CreatePaymentRequestMock,
    });

    if (!response || response?.invoiceId === undefined) {
      handleFail(dispatch, response?.err, 'ERROR.CREATE_INVOICE_FAIL', true);
      return;
    }

    dispatch({
      type: CREATE_INVOICE_SUCCESS,
    });
    toast({ message: 'MESSAGE.CREATE_INVOICE_SUCCESS', color: COLORS.SUCCESS });
  };
}

export function submitCardPayment(param) {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.PAYMENT');
    const response = await fetchApi({
      url: configs.api.transaction.pay,
      method: 'POST',
      isPublic: true,
      param: {
        ...param,
        cardNumber: param.cardNumber.replace(/ /g, ''),
      },
      // mockData: PayCardMock,
    });

    if (!response || !response?.success) {
      handleFail(dispatch, response?.err, 'ERROR.PAYMENT_FAIL', true);
      return;
    }

    dispatch({
      type: PAYMENT_SUCCESS,
    });
  };
}

export function sendReminders(invoices) {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.REMINDER');
    const response = await fetchApi({
      url: configs.api.invoice.reminder,
      method: 'POST',
      param: {
        invoices: JSON.stringify(invoices),
      },
    });

    if (!response || !response?.success) {
      handleFail(dispatch, response?.err, 'ERROR.SEND_EMAIL_FAIL', true);
      return;
    }

    dispatch({
      type: LOADED,
    });
    toast({ message: 'MESSAGE.REMINDER_SENT', color: COLORS.SUCCESS });
  };
}

export function submitTokenPayment(param) {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.PAYMENT');

    const response = await fetchApi({
      url: configs.api.transaction.payToken,
      method: 'POST',
      param,
    });

    if (!response || !response?.success) {
      handleFail(dispatch, response?.err, 'ERROR.DELETE_CARD_FAIL', true);
      return;
    }

    dispatch({
      type: TOKEN_PAYMENT_SUCCESS,
    });
    toast({ message: 'MESSAGE.MAKE_PAYMENT_SUCCESS', color: COLORS.SUCCESS });
  };
}

export function voidTransaction(transactionId) {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.PAYMENT');

    const response = await fetchApi({
      url: configs.api.transaction.void,
      method: 'POST',
      param: {
        transactionId,
      },
      // mockData: AddSubscriptionMock,
    });

    if (!response || !response?.success) {
      handleFail(dispatch, response?.err || response?.message, 'ERROR.VOID_FAIL', true);
      return;
    }

    dispatch({
      type: LOADED,
    });
    toast({ message: 'MESSAGE.VOID_SUCCESS', color: COLORS.SUCCESS });
  };
}

export function refundTransaction(transactionId) {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.PAYMENT');

    const response = await fetchApi({
      url: configs.api.transaction.refund,
      method: 'POST',
      param: {
        transactionId,
      },
      // mockData: AddSubscriptionMock,
    });

    if (!response || !response?.success) {
      handleFail(dispatch, response?.err || response?.message, 'ERROR.REFUND_FAIL', true);
      return;
    }

    dispatch({
      type: LOADED,
    });
    toast({ message: 'MESSAGE.REFUND_SUCCESS', color: COLORS.SUCCESS });
  };
}

export function createSubscription(param) {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.SAVING');
    const response = await fetchApi({
      url: configs.api.subscription.create,
      method: 'POST',
      param,
      // mockData: AddSubscriptionMock,
    });

    if (!response || response?.success === undefined) {
      handleFail(dispatch, response?.err, 'ERROR.CREATE_SUBSCRIPTION_FAIL', true);
      return;
    }

    dispatch({
      type: CREATE_SUBSCRIPTION_SUCCESS,
    });
  };
}

export function updateInvoice(param) {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.SAVING');
    const response = await fetchApi({
      url: configs.api.invoice.update,
      method: 'POST',
      param,
    });

    if (!response || response?.success === undefined) {
      handleFail(dispatch, response?.err, 'ERROR.SAVE', true);
      return;
    }

    dispatch({
      type: UPDATE_INVOICE_SUCCESS,
    });
  };
}

export function deleteInvoices(invoices) {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.PROCESSING');
    const response = await fetchApi({
      url: configs.api.invoice.delete,
      method: 'DELETE',
      param: {
        invoices: JSON.stringify(invoices),
      },
    });

    if (!response || response?.success === undefined) {
      handleFail(dispatch, response?.err, 'ERROR.DELETE_INVOICE_FAIL', true);
      return;
    }

    dispatch({
      type: DELETE_INVOICES_SUCCESS,
    });
    toast({ message: 'MESSAGE.INVOICE_DELETE_SUCCESS', color: COLORS.SUCCESS });
  };
}

export function deleteSubscription(subscriptionId) {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.PROCESSING');
    const response = await fetchApi({
      url: configs.api.subscription.delete + subscriptionId,
      method: 'DELETE',
    });

    if (!response || response?.success === undefined) {
      handleFail(dispatch, response?.err, 'ERROR.DELETE_INVOICE_FAIL', true);
      return;
    }

    dispatch({
      type: DELETE_INVOICES_SUCCESS,
    });
    toast({ message: 'MESSAGE.INVOICE_DELETE_SUCCESS', color: COLORS.SUCCESS });
  };
}

export function suspendSubscription(subscriptionId) {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.PROCESSING');
    const response = await fetchApi({
      url: configs.api.subscription.delete + subscriptionId,
      method: 'POST',
      param: { subscriptionId },
    });

    if (!response || response?.success === undefined) {
      handleFail(dispatch, response?.err, 'ERROR.SUSPEND_SUBSCRIPTION_FAIL', true);
      return;
    }

    dispatch({
      type: SUSPEND_SUBSCRIPTION_SUCCESS,
    });
    toast({ message: 'MESSAGE.SUSPEND_SUBSCRIPTION_SUCCESS', color: COLORS.SUCCESS });
  };
}

export function unsuspendSubscription(subscriptionId) {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.PROCESSING');
    const response = await fetchApi({
      url: configs.api.subscription.delete + subscriptionId,
      method: 'POST',
      param: { subscriptionId },
    });

    if (!response || response?.success === undefined) {
      handleFail(dispatch, response?.err, 'ERROR.UNSUSPEND_SUBSCRIPTION_FAIL', true);
      return;
    }

    dispatch({
      type: UNSUSPEND_SUBSCRIPTION_SUCCESS,
    });
    toast({ message: 'MESSAGE.UNSUSPEND_SUBSCRIPTION_SUCCESS', color: COLORS.SUCCESS });
  };
}

export function clearInvoiceStatus(): InvoiceActionTypes {
  return { type: CLEAR_INVOICE_STATUS };
}

export function resetSuccess(): InvoiceActionTypes {
  return { type: RESET_SUCCESS };
}

export function dehydrate(): InvoiceActionTypes {
  return { type: RESET_ALL };
}
