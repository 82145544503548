import React from 'react';

import { COLORS } from '$gbusiness/enums';
import CellModel from '$gbusiness/models/cell';
import { defaultTable } from '$gbusiness/models/table';
import { currency } from '$ghelpers/util';

export const TRANSACTION_HISTORY_TABLE_CONFIG = {
  ...defaultTable,
  styles: {
    color: COLORS.SECONDARY,
    cellPadding: '4px',
    isStripped: true,
    minWidth: 600,
    maxWidth: 800,
  },
  filter: {
    color: COLORS.PRIMARY,
  },
};

const TRANSACTION_HISTORY_TABLE: Array<CellModel> = [
  {
    label: 'SCREEN.TRANSACTION_HISTORY.COLS.DATE',
    value: 'prt_date',
    alwaysShow: true,
    width: 200,
  },
  {
    label: 'SCREEN.TRANSACTION_HISTORY.COLS.TYPE',
    value: 'trs_type',
    alwaysShow: true,
    width: 220,
  },
  {
    label: 'SCREEN.TRANSACTION_HISTORY.COLS.DESCRIPTION',
    value: 'trs_dec',
    alwaysShow: true,
    width: 350,
  },
  {
    label: 'SCREEN.TRANSACTION_HISTORY.COLS.AMOUNT',
    value: 'amt',
    component: row => <>{currency(row.amt)}</>,
    width: 200,
  },
  {
    label: 'SCREEN.TRANSACTION_HISTORY.COLS.BALANCE',
    value: 'bal',
    component: row => <>{currency(row.bal)}</>,
    width: 200,
  },
];

export default TRANSACTION_HISTORY_TABLE;
