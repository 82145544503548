import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex !important;
  align-items: center;
  width: ${props => props.width || 'auto'};
  height: ${props => props.height || '30px'};
  background: var(--ion-background-color, #fff);
  border-radius: 2px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  --cancel-button-color: var(--ion-color-step-900, #1a1a1a);
  --clear-button-color: initial;
  color: var(--ion-color-step-850, #262626);
  --icon-color: var(--ion-color-step-600, #666666);
  margin: 8px 2px;
  background: inherit;
  left: 0px;
  right: 0px;

  --placeholder-color: initial;
  --placeholder-font-style: initial;
  --placeholder-font-weight: initial;
  --placeholder-opacity: 0.5;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: -ms-flexbox;
  display: block;
  position: relative;
  -ms-flex-align: center;
  align-items: center;
  color: var(--color);
  font-family: var(--ion-font-family, inherit);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  ion-icon {
    color: var(--icon-color);
  }

  & .react-autosuggest__container {
    position: relative;
    width: 100%;
  }

  & .react-autosuggest__input {
    width: 100%;
    height: ${props => props.height || '30px'};
    padding: 10px 20px;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    padding: 4px 30px 4px ${props => (props.hideSearchIcon ? '8px' : '40px')};
    border: 0px solid #aaa;
    border-radius: 4px;
  }

  & .react-autosuggest__input--focused {
    outline: none;
  }

  & .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  & .react-autosuggest__suggestions-container {
    display: block !important;
    overflow-y: auto;
    max-height: 300px;
  }

  & .react-autosuggest__suggestions-container--open {
    display: block !important;
    position: absolute;
    top: ${props => props.height || '30px'};
    left: 0;
    right: 0;
    border: 1px solid #ccc;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 10;
  }

  & .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    position: relative;
    left: 0;
    right: 0;
    list-style-type: none;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }

  & .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
    border-top: 1px solid #ddd;
  }

  & .react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
  }

  & button {
    background: none;
  }

  & ion-icon {
    position: absolute;
    left: 8px;
    width: 20px;
    height: 20px;
    z-index: 1;
  }

  & ion-icon.clear-button {
    left: initial;
    right: 12px;
  }
`;
