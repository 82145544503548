import { ENUMS } from './enums';

export const mobileStyle = style => {
  return `
  @media screen and (max-width: ${ENUMS.MOBILE_WIDTH}) {
    ${style}
  }
  `;
};

export const splitUnderStyle = style => {
  return `
  @media screen and (max-width: ${ENUMS.SPLIT_PANE_WIDTH}) {
    ${style}
  }
  `;
};

export const splitOverStyle = style => {
  return `
  @media screen and (min-width: ${ENUMS.SPLIT_PANE_WIDTH}) {
    ${style}
  }
  `;
};

export const desktopStyle = style => {
  return `
  @media screen and (min-width: ${ENUMS.DESKTOP_MIN_WIDTH}) {
    ${style}
  }
  `;
};

export const deriveColWidth = size => {
  const width = (size * 100) / 12;

  return `${width}%`;
};
