export default interface CardFormModel {
  cardNumber: string;
  cardHolder: string;
  customerCode: string;
  expiry: string;
  cvc: string;
  address: string;
  zip: string;
}

export const defaultCardForm = {
  cardNumber: '',
  cardHolder: '',
  customerCode: '',
  expiry: '',
  cvc: '',
  address: '',
  zip: '',
};
