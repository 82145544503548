export const MYSQL = 'yyyy-MM-dd HH:mm:ss';
export const DATE = 'yyyy-MM-dd';
export const SLASH = 'M/d/yyyy';
export const READABLE = 'MMM d, yyyy';
export const WEEKDAY = 'eee';
export const MONTH_DAY = 'M/d';
export const DAY_WEEKDAY = 'M/d (eee)';
export const DATE_WEEKDAY = 'M/d/yy (eee)';
export const DATETIME = 'M/d/yyyy h:mm aaa';
export const TIME = 'h:mm aaa';
