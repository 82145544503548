import React from 'react';
import { useDispatch } from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';

import intl from '$intl';
import CardModel from '$business/models/cardModel';
import { Div, SPACE } from '$gstyles';
import { CardListWrapper, CardRow, CardCol } from '../../screens/makePaymentScreen/styles';
import { HeaderTitle, Text } from '$gcomponents/primitives';
import CardItem from './cardItem';
import { Button, Divider, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { Desktop, Flex } from '$gstyles/wrapper';
import { deleteCard } from '$business/redux/customer/actions';
import { dialog } from '$gcomponents/reusables';

interface CardListProps {
  title: string;
  cards?: Array<CardModel>;
  onCardSelect: (e) => void;
  cardIndex: number;
  onOpenCardModal: () => void;
}

const CardList: React.FC<CardListProps> = ({
  cards,
  title,
  onCardSelect,
  onOpenCardModal,
  cardIndex = 0,
}) => {
  const dispatch = useDispatch();

  const onDeleteCard = cardId => {
    dialog.confirm({
      title: 'MESSAGE.DELETE_CARD_WARNING',
      message: 'MESSAGE.DELETE_CARD_WARNING_MSG',
      cssClass: 'large',
      onPress: () => {
        dispatch(deleteCard(cardId));
      },
    });
  };

  return (
    <CardListWrapper>
      <HeaderTitle>
        <Text k={title} />
      </HeaderTitle>
      <Div className="cards-box">
        <Desktop>
          <CardRow paddingBottom={SPACE.MEDIUM}>
            <CardCol gridSize={7}>{intl('SCREEN.MAKE_PAYMENT.COLS.CARD')}</CardCol>
            <CardCol gridSize={3}>{intl('SCREEN.MAKE_PAYMENT.COLS.CARDHOLDER')}</CardCol>
            <CardCol gridSize={2}>{intl('SCREEN.MAKE_PAYMENT.COLS.EXPIRY')}</CardCol>
          </CardRow>
          <Divider />
        </Desktop>
        <RadioGroup name="cardIndex" value={cardIndex} onChange={e => onCardSelect(e.target.value)}>
          {(cards || []).map((card, index) => {
            return (
              <CardRow key={index}>
                <CardCol breakpoint="320px" gridSize={7}>
                  <Flex>
                    <FormControlLabel value={index} control={<Radio />} label="" />
                    <CardItem card={card} />
                  </Flex>
                </CardCol>
                <CardCol breakpoint="320px" gridSize={3}>
                  {card.cardHolder}
                </CardCol>
                <CardCol breakpoint="320px" gridSize={2}>
                  <div className="pull-right">
                    <DeleteIcon className="pointer" onClick={() => onDeleteCard(card.cardId)} />
                  </div>
                  {card.expiry}
                </CardCol>
              </CardRow>
            );
          })}
        </RadioGroup>
        {cards && (
          <Button color="default" size="small" variant="contained" onClick={onOpenCardModal}>
            {intl('SCREEN.MAKE_PAYMENT.ADD_CARD_BUTTON')}
          </Button>
        )}
      </Div>
    </CardListWrapper>
  );
};

export default CardList;
