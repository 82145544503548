import styled from 'styled-components';

const toKebabCase = str =>
  str
    .split(/(?=[A-Z])/)
    .join('-')
    .toLowerCase();

const reservedAttrs = ['className', 'id', 'style', 'theme', 'children', 'customStyle'];

export default styled.div`
  ${props =>
    Object.entries(props).map(([k, v]) => {
      if (k === 'onClick' && v !== undefined) return 'cursor: pointer;';
      if (typeof v === 'function') return '';
      if (reservedAttrs.includes(k)) return '';
      return `${toKebabCase(k)}: ${v} !important;`;
    })}
`;

export const Inline = styled.span`
  ${props => Object.entries(props).map(([k, v]) => `${toKebabCase(k)}: ${v} !important;`)}
`;
