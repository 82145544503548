import React, { useState } from 'react';
import { connect } from 'react-redux';

import { screen } from '$gcomponents/hoc';
import * as TRANSACTION_TABLE from './transactionTable';
import ScreenSection, { tabModel } from './screenSection';
import intl from '$intl';
import { invoiceActions } from '$business/redux/invoice';
import MuiModal from '$gcomponents/reusables/modal/material';
import TransactionModel from '$business/models/transactions';
import ReceiptDetails from './components/receiptDetails';
import { dialog } from '$gcomponents/reusables';

const TABS: Array<tabModel> = [
  { label: 'SCREEN.TRANSACTIONS.TABS.ALL', value: '' },
  { label: 'SCREEN.TRANSACTIONS.TABS.APPROVED', value: 'paidAt' },
  { label: 'SCREEN.TRANSACTIONS.TABS.DECLINED', value: 'failedAt' },
];

interface TransactionsScreenProps {
  voidTransaction: Function;
  refundTransaction: Function;
}

const TransactionsScreen: React.FC<TransactionsScreenProps> = ({ voidTransaction, refundTransaction }) => {
  const [receipt, setShowReceipt] = useState<TransactionModel | null>(null);

  const itemActions = {
    viewReceipt: row => {
      setShowReceipt(row);
    },
    voidTransaction: async (row, onRefresh) => {
      dialog.confirm({
        message: 'MESSAGE.VOID_WARNING',
        cssClass: 'large',
        onPress: async () => {
          await voidTransaction(row.id);
          onRefresh();
        },
      });
    },
    refundTransaction: async (row, onRefresh) => {
      dialog.confirm({
        message: 'MESSAGE.REFUND_WARNING',
        cssClass: 'large',
        onPress: async () => {
          await refundTransaction(row.id);
          onRefresh();
        },
      });
    },
  };

  return (
    <>
      <ScreenSection
        exportSettings={TRANSACTION_TABLE.exportSettings}
        title={intl('SCREEN.TRANSACTIONS.TITLE')}
        itemActions={itemActions}
        TABLE={TRANSACTION_TABLE}
        TABS={TABS}
      />
      <MuiModal open={!!receipt} onClose={() => setShowReceipt(null)}>
        <ReceiptDetails transaction={receipt} />
      </MuiModal>
    </>
  );
};

const mapStateToProps = state => ({
  resetOnRoute: true,
});

const mapDispatchToProps = {
  voidTransaction: invoiceActions.voidTransaction,
  refundTransaction: invoiceActions.refundTransaction,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(TransactionsScreen));
