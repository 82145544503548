import styled from 'styled-components';
import { InputLabel, FormControl } from '@material-ui/core';
import { SPACE } from '$gstyles';

export const FormControlWrapper = styled(FormControl)`
  .MuiSelect-select {
    text-align: left;
  }
  .MuiInputAdornment-root {
    margin-right: 4px;
    margin-left: -4px;
  }
  .MuiOutlinedInput-root {
    width: 70px;
  }
  .MuiOutlinedInput-input {
    text-align: left;
  }
`;

export const FormControlWrapper2 = styled(FormControl)`
  .MuiSelect-select {
    text-align: left;
  }
  .MuiInputBase-root.select-input {
    top: 8px;
    margin-bottom: 5px;
  }
`;

export const BSLabel = styled(InputLabel)`
  margin-top: ${SPACE.MEDIUM};

  &&.bs-label {
    position: absolute;
    top: -16px;
    left: 0;
    font-size: 13px;
    font-weight: 500;
    transform: none;
  }
`;
