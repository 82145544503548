export const phone = value => {
  const mv = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  return !mv[2] ? mv[1] : mv[1] + '-' + mv[2] + (mv[3] ? '-' + mv[3] : '');
};

export const ssn = value => {
  const mv = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,2})(\d{0,4})/);
  return !mv[2] ? mv[1] : mv[1] + '-' + mv[2] + (mv[3] ? '-' + mv[3] : '');
};

export const cardNumber = value => {
  const regex = /^(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})$/g;
  const onlyNumbers = value.replace(/[^\d]/g, '');

  return onlyNumbers.replace(regex, (regex, $1, $2, $3, $4) =>
    [$1, $2, $3, $4].filter(group => !!group).join(' '),
  );
};

export const expiry = value => {
  const mv = value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,2})/);
  return !mv[2] ? mv[1] : mv[1] + '/' + mv[2];
};

export const currency = value => {
  return parseFloat(value).toFixed(2);
};
