import React from 'react';

import { configs } from '$configs';
import { SORT, COLORS } from '$gbusiness/enums';
import CellModel from '$gbusiness/models/cell';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import intl from '$intl';
import { Button } from '@material-ui/core';
import PopOver from '$gcomponents/reusables/popOver';
import { format } from '$ghelpers/date';
import MockData from '$business/mocks/getSubscriptions.json';
import { deriveRawToSubscription } from '$business/models/subscription';
import { Div } from '$gstyles';

export const TABLE_CONFIG = {
  endpoint: configs.api.invoice.list,
  isPublic: true,
  deriveToModel: deriveRawToSubscription,
  mockData: MockData,
  color: COLORS.SECONDARY,
  pageSize: 50,
  fontSize: '0.9em',
  cellPadding: `3px`,
  isStripped: true,
  minWidth: 960,
  maxWidth: 1300,
  shouldVirtualize: false,
  virtualized: {
    rowHeight: 36,
    headerHeight: 60,
    checkWidth: 60,
    threshold: 10,
    additionalHeight: 40,
  },
  filter: {
    color: COLORS.PRIMARY,
  },
};

export const TABLE: Array<CellModel> = [
  {
    label: 'SCREEN.INVOICE.COLS.STATUS',
    value: 'status',
    component: row => {
      const color = row.suspendedAt ? 'danger' : 'success';
      const key = row.suspendedAt ? 'SUSPENDED' : 'ACTIVATED';
      return (
        <Div fontWeight="600" className={`${color} outlined`}>
          {intl('SCREEN.RECURRINGS.' + key)}
        </Div>
      );
    },
    width: 120,
  },
  {
    label: 'SCREEN.INVOICE.COLS.CUSTOMER_CODE',
    value: 'customerCode',
    sortable: SORT.ASC,
    width: 160,
  },
  {
    label: 'SCREEN.RECURRINGS.COLS.COUNT',
    value: 'count',
    sortable: SORT.ASC,
    width: 100,
  },
  {
    label: 'SCREEN.RECURRINGS.COLS.NET_AMOUNT',
    value: 'amount',
    component: row => <>{row.amount && intl('PRICE', { amount: row.amount })}</>,
    width: 150,
  },
  {
    label: 'SCREEN.RECURRINGS.COLS.INTERVAL',
    value: 'interval',
    component: row => <>{row.interval && intl('INPUT.OPTION.RECURRING_INTERVAL.' + row.interval)}</>,
    sortable: SORT.ASC,
    width: 160,
  },
  {
    label: 'SCREEN.RECURRINGS.COLS.NEXT_RUN',
    component: row => row.nextPaymentAt && <>{format(row.nextPaymentAt)}</>,
    value: 'nextPaymentAt',
    width: 180,
  },
  {
    label: 'SCREEN.RECURRINGS.COLS.LAST_RUN',
    component: row => row.lastPaymentAt && <>{format(row.lastPaymentAt)}</>,
    value: 'lastPaymentAt',
    width: 180,
  },
  {
    label: 'SCREEN.INVOICE.COLS.USER',
    value: 'userName',
    sortable: SORT.ASC,
    width: 200,
  },
  {
    label: 'SCREEN.TRANSACTIONS.COLS.ACTION',
    value: '',
    align: 'center',
    className: 'action',
    component: (row, actions) => {
      return (
        <PopOver component={<MoreHorizIcon className="pointer" />}>
          <>
            {!row.paidAt && (
              <Button className="left" fullWidth onClick={() => actions.editSubscription(row)}>
                {intl('SCREEN.INVOICE.ACTIONS.EDIT')}
              </Button>
            )}
            {row.suspendedAt && (
              <Button className="left" fullWidth onClick={() => actions.unsuspend(row, actions.onRefresh)}>
                {intl('SCREEN.INVOICE.ACTIONS.UNSUSPEND')}
              </Button>
            )}
            <Button className="left" fullWidth onClick={() => actions.viewSubscription(row)}>
              {intl('SCREEN.INVOICE.ACTIONS.VIEW')}
            </Button>
            <Button
              className="left"
              fullWidth
              onClick={() => actions.deleteSubscription(row, actions.onRefresh)}>
              {intl('SCREEN.INVOICE.ACTIONS.DELETE')}
            </Button>
          </>
        </PopOver>
      );
    },
    width: 100,
  },
];
