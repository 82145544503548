import React, { useState } from 'react';
import { startOfDay, endOfDay, startOfToday } from 'date-fns';
import { FormControl, InputAdornment, Select } from '@material-ui/core';
import { DatePicker, DateTimePicker } from '@material-ui/pickers';
import SearchIcon from '@material-ui/icons/Search';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

import { IconInputWrapper, InnerAdornment, OuterAdornment } from '$styles/input';
import { Inline } from '$gstyles';
import dateRangeOptions, { KEYS } from '$business/enums/options/dateRange';
import { DATE_FORMATS } from '$gbusiness/enums';
import intl from '$intl';
import { getDateRangeFromPeriod } from '$business/helpers/util';
import { input } from '$gcomponents/utils';
import { endOfToday } from 'date-fns/esm';

interface LeftComponentProps {
  onSearch: Function;
  useDatetime?: boolean;
  dataLength: number;
  filter: filterProps;
  setFilter: Function;
}

export interface filterProps {
  query: string;
  dateStart: Date | null;
  dateEnd: Date | null;
  forceRefresh?: boolean;
}

const defaultRange = KEYS.TODAY;

export const defaultFilter: filterProps = {
  query: '',
  dateStart: startOfToday(),
  dateEnd: endOfToday(),
  forceRefresh: false,
};

const LeftComponent: React.FC<LeftComponentProps> = ({
  filter: parentFilter,
  dataLength,
  onSearch,
  useDatetime = false,
}) => {
  const [rangeSelection, setRangeSelection] = useState(defaultRange);
  const [filter, setFilter] = useState(parentFilter);

  const onRangeSelectionChange = e => {
    const value = e.target.value;
    if (value === KEYS.CUSTOM) return;
    setRangeSelection(value);
    const [dateStart, dateEnd] = getDateRangeFromPeriod(value);
    setFilter({ ...filter, dateStart: dateStart, dateEnd: dateEnd });
  };
  const onDateStartChange = e => {
    const newFilter = { ...filter, dateStart: useDatetime ? e : startOfDay(e) };
    setFilter(newFilter);
    setRangeSelection(KEYS.CUSTOM);
    onSearch(newFilter);
  };
  const onDateEndChange = e => {
    const newFilter = { ...filter, dateEnd: useDatetime ? e : endOfDay(e) };
    setFilter(newFilter);
    setRangeSelection(KEYS.CUSTOM);
    onSearch(newFilter);
  };
  const searchNow = e => {
    e.stopPropagation();
    onSearch(filter);
  };

  const Picker = useDatetime ? DateTimePicker : DatePicker;
  const dateFormat = useDatetime ? DATE_FORMATS.DATETIME : DATE_FORMATS.SLASH;
  const pickerWidth = useDatetime ? 210 : 140;
  const rangeWidth = 150;
  const dateRanges = input.generateSelectOptions(dateRangeOptions, true);

  return (
    <>
      {/* <IconInputWrapper>
        <TextField
          label=""
          variant="outlined"
          placeholder="test"
          size="small"
          style={{ minWidth: pickerWidth + 'px' }}
          value={filter.query}
          onChange={onQueryChange}
          onKeyPress={ev => {
            if (ev.key === 'Enter') {
              ev.preventDefault();
              searchNow(ev);
            }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={searchNow}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </IconInputWrapper>
      <Space.Hor margin={SPACE.MEDIUM} />
      */}
      <FormControl variant="outlined" size="small" style={{ minWidth: rangeWidth + 'px' }}>
        <Select displayEmpty variant="outlined" onChange={onRangeSelectionChange} value={rangeSelection}>
          {dateRanges}
        </Select>
      </FormControl>
      <IconInputWrapper>
        <Picker
          label=""
          size="small"
          inputVariant="outlined"
          value={filter.dateStart}
          onChange={onDateStartChange}
          maxDate={filter.dateEnd}
          format={dateFormat}
          style={{ width: pickerWidth + 'px' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <InnerAdornment>
                  <CalendarTodayIcon />
                </InnerAdornment>
              </InputAdornment>
            ),
          }}
        />
      </IconInputWrapper>
      <Inline className="flexCenter">to</Inline>
      <IconInputWrapper>
        <Picker
          label=""
          size="small"
          inputVariant="outlined"
          value={filter.dateEnd}
          onChange={onDateEndChange}
          minDate={filter.dateStart}
          style={{ width: pickerWidth + 48 + 'px' }}
          format={dateFormat}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <InnerAdornment>
                  <CalendarTodayIcon />
                </InnerAdornment>
                <OuterAdornment onClick={searchNow}>
                  <SearchIcon />
                </OuterAdornment>
              </InputAdornment>
            ),
          }}
        />
      </IconInputWrapper>
      <div className="total-count">{intl('SCREEN.BILLS.TOTAL_COUNT', { count: dataLength })}</div>
      {/* <Button size="small" color="primary" onClick={resetFilter}>
        {intl('COMMON.RESET')}
      </Button> */}
    </>
  );
};

export default LeftComponent;
