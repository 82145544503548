import styled from 'styled-components';

export const Wrapper = styled.span`
  font-size: 1.2rem;
  opacity: 0.7;

  .title {
    display: inline-block;
    margin-right: 0.5rem;
  }
  .version {
    display: inline-block;
  }
  .version:before {
    content: 'v';
  }
  .name {
    margin-right: 4px;
  }
`;
