export default interface PaymentModel {
  invoiceId: number;
  email?: string;
  cardHolder: string;
  cardNumber: string;
  expiry: string;
  cvc: string;
  zip: string;
  state?: string;
  country?: string;
  amount: number;
  saveCard: boolean;
  shouldEmail?: boolean;
}

export const defaultPayment = {
  invoiceId: 0,
  email: '',
  cardHolder: '',
  cardNumber: '',
  expiry: '',
  cvc: '',
  zip: '',
  state: 'PA',
  country: 'UNITED_STATES',
  amount: 0,
  saveCard: true,
  shouldEmail: false,
};
