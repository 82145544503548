import InvoiceModel from '$business/models/invoice';
import { CheckCustomerCodeSuccessType } from '../customer/types';

export const IS_PROCESSING = 'IS_SUBMITTING';
export const INVOICE_FAILURE = 'INVOICE_FAILURE';
export const RESET_FAILURE = 'RESET_FAILURE';
export const FETCH_INVOICES_SUCCESS = 'FETCH_INVOICES_SUCCESS';
export const CLEAN_INVOICES = 'CLEAN_INVOICES';
export const CREATE_INVOICE_SUCCESS = 'CREATE_INVOICES_SUCCESS';
export const DELETE_INVOICES_SUCCESS = 'DELETE_INVOICES_SUCCESS';
export const SEND_INVOICES_SUCCESS = 'SEND_INVOICES_SUCCESS';
export const UPDATE_INVOICE_SUCCESS = 'UPDATE_INVOICE_SUCCESS';
export const RESET_SUCCESS = 'RESET_SUCCESS';
export const VALIDATE_INVOICE_SUCCESS = 'VALIDATE_INVOICE_SUCCESS';
export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS';
export const CREATE_SUBSCRIPTION_SUCCESS = 'CREATE_SUBSCRIPTION_SUCCESS';
export const DELETE_SUBSCRIPTION_SUCCESS = 'CREATE_SUBSCRIPTION_SUCCESS';
export const SUSPEND_SUBSCRIPTION_SUCCESS = 'SUSPEND_SUBSCRIPTION_SUCCESS';
export const UNSUSPEND_SUBSCRIPTION_SUCCESS = 'UNSUSPEND_SUBSCRIPTION_SUCCESS';
export const VOID_TRANSACTION_SUCCESS = 'VOID_TRANSACTION_SUCCESS';
export const REFUND_TRANSACTION_SUCCESS = 'REFUND_TRANSACTION_SUCCESS';
export const TOKEN_PAYMENT_SUCCESS = 'TOKEN_PAYMENT_SUCCESS';
export const RESET_ALL = 'INVOICE_RESET_ALL';
export const CLEAR_INVOICE_STATUS = 'CLEAR_INVOICE_STATUS';
export const RESET_CUSTOMER_CODE = 'RESET_CUSTOMER_CODE';

// Action Types
export interface typeOnlyType {
  type:
    | typeof FETCH_INVOICES_SUCCESS
    | typeof CLEAN_INVOICES
    | typeof CREATE_INVOICE_SUCCESS
    | typeof UPDATE_INVOICE_SUCCESS
    | typeof DELETE_INVOICES_SUCCESS
    | typeof RESET_SUCCESS
    | typeof RESET_ALL
    | typeof RESET_CUSTOMER_CODE
    | typeof IS_PROCESSING
    | typeof CLEAR_INVOICE_STATUS
    | typeof PAYMENT_SUCCESS
    | typeof TOKEN_PAYMENT_SUCCESS
    | typeof SUSPEND_SUBSCRIPTION_SUCCESS
    | typeof UNSUSPEND_SUBSCRIPTION_SUCCESS
    | typeof REFUND_TRANSACTION_SUCCESS
    | typeof VOID_TRANSACTION_SUCCESS
    | typeof CREATE_SUBSCRIPTION_SUCCESS;
}

export interface failureType {
  type: typeof INVOICE_FAILURE | typeof RESET_FAILURE;
  err: string;
}

export interface SendInvoicesSuccessType {
  type: typeof SEND_INVOICES_SUCCESS;
  numSuccess: number;
  numFailure: number;
}

export interface ValidateInvoiceSuccessType {
  type: typeof VALIDATE_INVOICE_SUCCESS;
  invoice: InvoiceModel;
  status?: string;
}

export interface InvoiceReducerType {
  invoice: InvoiceModel | null;
  numSuccess: number;
  numFailure: number;
  isProcessing: boolean;
  isFinished: boolean;
  status?: string;
}

export type InvoiceActionTypes =
  | typeOnlyType
  | failureType
  | SendInvoicesSuccessType
  | CheckCustomerCodeSuccessType
  | ValidateInvoiceSuccessType;

export const INVOICE_INIT_STATE: InvoiceReducerType = {
  invoice: null,
  numSuccess: 0,
  numFailure: 0,
  isProcessing: false,
  isFinished: false,
  status: '',
};
