import styled from 'styled-components';
import { Col, Div, Row, SPACE } from '$gstyles';

export const CardListWrapper = styled(Div)`
  .cards-box {
    margin-bottom: ${SPACE.XLARGE};
    padding: ${SPACE.LARGE};
    border: 1px solid var(--ion-border-color);
    border-radius: 4px;
    background-color: #fff;
    color: #333;
    overflow-x: auto;
  }

  .MuiFormGroup-root {
    display: block;
    min-width: 500px;
  }

  .MuiRadio-root {
    padding: 0;
    padding-left: ${SPACE.MEDIUM};
  }

  hr.MuiDivider-root {
    margin-top: initial;
  }

  .card-icon {
    max-height: 18px;
    max-width: 28px;
  }

  .last4:first-letter {
    text-transform: uppercase;
    margin-left: ${SPACE.MEDIUM};
  }

  .expiry {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const CardRow = styled(Row)`
  align-items: center;
  margin: ${SPACE.MEDIUM};

  .MuiSvgIcon-root {
    font-size: 2.4rem;
  }
`;

export const CardCol = styled(Col)``;
