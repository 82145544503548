export const KEYS = {
  SUCCESS: 'SUCCESS',
  USPS: 'USPS',
  FAILED: 'FAILED',
};

const LIST = Object.keys(KEYS);

export default LIST.map(item => ({
  label: `EMAIL_STATUS.${item}`,
  value: item,
}));

export type EMAIL_STATUS_TYPE = typeof LIST[number] | undefined;
