import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import { IonContent, IonFooter } from '@ionic/react';

import { defaultPaymentRequest } from '$business/models/paymentRequest';
import { customerActions, customerActionTypes } from '$business/redux/customer';
import { invoiceActions, invoiceActionTypes } from '$business/redux/invoice';
import intl from '$intl';
import { Button, CloseButton } from '$gcomponents/primitives';
import { FormSection } from '$gcomponents/reusables';
import { input } from '$ghelpers';
import { screen } from '$gcomponents/hoc';
import { Div, H2, SPACE, Space } from '$gstyles';
import { Flex } from '$gstyles/wrapper';

import { CUSTOMER_FORM } from '$components/forms/CustomerForm';
import { TRANSACTION_MESSAGE_FORM } from '$components/forms/Transaction';
import { IonPageWrapper } from './styles';
import UserModel from '$gbusiness/models/user';

const initialFormValues = defaultPaymentRequest;

interface MakeLinkScreenProps {
  history: any;
  user: UserModel;
  invoice: invoiceActionTypes.InvoiceReducerType;
  customer: customerActionTypes.CustomerReducerType;
  createPaymentRequest: Function;
  checkCustomerCode: Function;
  dehydrate: Function;
}

const MakeLinkScreen: React.FC<MakeLinkScreenProps> = ({
  history,
  invoice,
  customer: customerReducer,
  createPaymentRequest,
  checkCustomerCode,
  user,
}) => {
  const formRef = useRef<any>();
  const derivedInitialValues = {
    ...input.deriveFormInitialValues(CUSTOMER_FORM, initialFormValues),
    ...input.deriveFormInitialValues(TRANSACTION_MESSAGE_FORM, initialFormValues),
  };
  const { isValidCustomerCode, customer } = customerReducer;

  const validateForm = values => input.validateError([...CUSTOMER_FORM, ...TRANSACTION_MESSAGE_FORM], values);
  const onSubmit = values => {
    createPaymentRequest({
      ...values,
      userId: user.userId,
      shouldEmail: true,
      amount: parseFloat(values.amount.replace(',', '')),
    });
  };
  const closeScreen = () => {
    history.goBack();
  };

  // After successfully submitted dehydrate and dismiss
  useEffect(() => {
    if (!invoice.isFinished) return;
    //dehydrate();
    if (formRef?.current && formRef.current.resetForm) formRef.current.resetForm();
    closeScreen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice.isFinished]);

  // Once customer code has been fetched
  useEffect(() => {
    if (!isValidCustomerCode || !customer) return;
    const { address1, address2, email, name, zip } = customer;
    formRef.current.setFieldValue('customerName', name);
    const customerEmail = process.env.REACT_APP_DEFAULT_EMAIL || email;
    formRef.current.setFieldValue('email', customerEmail?.trim());
    formRef.current.setFieldValue('address1', address1?.trim());
    formRef.current.setFieldValue('address2', address2?.trim());
    formRef.current.setFieldValue('zip', zip);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer?.customerCode]);

  useEffect(() => {
    formRef.current.resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <IonPageWrapper>
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        initialValues={derivedInitialValues}
        validate={validateForm}
        onSubmit={onSubmit}>
        {formik => {
          return (
            <>
              <IonContent>
                <CloseButton onClick={closeScreen} />
                <Div padding={SPACE.LARGE} maxWidth="800px">
                  <H2 textAlign="left">{intl('SCREEN.PAYMENT_REQUEST.TITLE')}</H2>
                  <Form>
                    <Space.Ver padding={SPACE.SMALL} />
                    <FormSection
                      title="SCREEN.PAYMENT_REQUEST.SUBTITLE"
                      FORM={CUSTOMER_FORM}
                      formik={formik}
                      callback={value => {
                        checkCustomerCode(value);
                      }}
                    />
                    <FormSection
                      title="SCREEN.PAYMENT_REQUEST.SUBTITLE2"
                      FORM={TRANSACTION_MESSAGE_FORM}
                      formik={formik}
                    />
                  </Form>
                  {/* {invoice.isValidCustomerCode && <div>{intl('MESSAGE.VALID_CUSTOMER_CODE')}</div>}
                  {invoice.isValidCustomerCode === false && (
                    <div>{intl('MESSAGE.INVALID_CUSTOMER_CODE')}</div>
                  )} */}
                </Div>
              </IonContent>
              <IonFooter>
                <Flex padding={SPACE.MEDIUM} justifyContent="space-between">
                  <Button variant="outlined" onClick={closeScreen}>
                    Cancel
                  </Button>
                  <Button
                    disabled={!(formik.isValid && isValidCustomerCode)}
                    color="primary"
                    className="submit-button"
                    isLoading={invoice.isProcessing}
                    loadingText={intl('SCREEN.PAYMENT_REQUEST.SUBMIT_BUTTON_LOADING')}
                    variant="contained"
                    onClick={formik.handleSubmit}>
                    {intl('SCREEN.PAYMENT_REQUEST.SUBMIT_BUTTON')}
                  </Button>
                </Flex>
              </IonFooter>
            </>
          );
        }}
      </Formik>
    </IonPageWrapper>
  );
};

const mapStateToProps = state => ({
  user: state.currentUser,
  invoice: state.invoice,
  customer: state.customer,
  resetOnRoute: true,
});

const mapDispatchToProps = dispatch => ({
  onDehydrate: () => {
    dispatch(invoiceActions.dehydrate());
    dispatch(customerActions.dehydrate());
  },
  createPaymentRequest: e => dispatch(invoiceActions.createPaymentRequest(e)),
  checkCustomerCode: e => dispatch(customerActions.checkCustomerCode(e)),
});

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(MakeLinkScreen));
