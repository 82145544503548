import {
  LOAD_CARDS_SUCCESS,
  CHECK_CUSTOMER_CODE_SUCCESS,
  RESET_CUSTOMER_CODE,
  RESET_ALL,
  CustomerReducerType,
  INIT_STATE,
  IS_PROCESSING,
  ADD_CARD_SUCCESS,
  CUSTOMER_FAILURE,
  RESET_STATUS,
  DELETE_CARD_SUCCESS,
  FETCH_TRANSACTIONS_SUCCESS,
  NOTHING_SUCCESS,
} from './types';

export default function customerReducer(
  state: CustomerReducerType = INIT_STATE,
  action,
): CustomerReducerType {
  switch (action.type) {
    case IS_PROCESSING:
      return {
        ...state,
        isProcessing: true,
      };
    case ADD_CARD_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        isFinished: true,
      };
    case CUSTOMER_FAILURE:
      return {
        ...state,
        isProcessing: false,
      };
    case LOAD_CARDS_SUCCESS:
      return {
        ...state,
        cards: action.cards,
      };
    case CHECK_CUSTOMER_CODE_SUCCESS:
      return {
        ...state,
        isValidCustomerCode: action.isValid,
        ...(action.customer && { customer: action.customer }),
      };
    case FETCH_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactions: action.transactions,
      };
    case DELETE_CARD_SUCCESS:
      return {
        ...state,
        cards: state.cards.filter(c => c.cardId !== action.cardId),
      };
    case RESET_CUSTOMER_CODE:
      return {
        ...state,
        isValidCustomerCode: INIT_STATE.isValidCustomerCode,
      };
    case RESET_STATUS:
      return {
        ...state,
        isProcessing: false,
        isFinished: false,
      };
    case NOTHING_SUCCESS:
      return state;
    case RESET_ALL:
      return INIT_STATE;
    default:
      return state;
  }
}
