import { COLORS } from '$gbusiness/enums';
import intl from '$gintl';
import { checkmark, warningOutline, alertCircleOutline } from 'ionicons/icons';

const getIconFromColor = color => {
  switch (color) {
    case COLORS.SUCCESS:
      return checkmark;
    case COLORS.WARNING:
      return warningOutline;
    case COLORS.DANGER:
      return alertCircleOutline;
    default:
      return '';
  }
};

const toast = params => {
  const icon = getIconFromColor(params.color);

  const button = params.button || {
    side: 'start',
    ...(icon && { icon }),
    part: 'icon',
  };

  const toast = document.createElement('ion-toast');
  toast.message = params.text || intl(params.message, params.key) || intl('ERROR.UNKNOWN');
  toast.duration = params.duration || 5000;
  toast.position = params.position || 'top';
  toast.color = params.color;
  toast.cssClass = params.cssClass || '';
  toast.buttons = [button];

  const rootEl = document.getElementById('root-app');

  if (rootEl) {
    rootEl.appendChild(toast);
    return toast.present();
  }
};

export default toast;
