import { Dispatch } from 'redux';

import { LOADING, LOADING_MODAL } from '$gbusiness/redux/loading/types';

import {
  CUSTOMER_FAILURE,
  CHECK_CUSTOMER_CODE_SUCCESS,
  CustomerActionTypes,
  LOAD_CARDS_SUCCESS,
  RESET_ALL,
  ADD_CARD_SUCCESS,
  RESET_STATUS,
  DELETE_CARD_SUCCESS,
  FETCH_TRANSACTIONS_SUCCESS,
  NOTHING_SUCCESS,
} from './types';
import { configs } from '$configs';
import { fetchApi } from '$gbusiness/services/api';
import { COLORS } from '$gbusiness/enums';
import { toast } from '$gcomponents/reusables';
import { deriveRawToCard } from '../../models/cardModel';
import { SET_TOASTER_DANGER, SET_TOASTER_SUCCESS } from '$gbusiness/redux/toaster/types';

export function dispatchLoading(dispatch, key = '') {
  dispatch({
    type: LOADING,
    ...(key && { loadingText: key }),
  });
}

export function dispatchLoadingModal(dispatch, key = '') {
  dispatch({
    type: LOADING_MODAL,
    ...(key && { loadingText: key }),
  });
}

export function handleFail(dispatch, err, key, shouldToast = false) {
  const errorKey = key || 'ERROR.SERVER';
  dispatch({
    type: CUSTOMER_FAILURE,
    err: err || errorKey,
  });

  if (!shouldToast) return;

  dispatch({
    type: SET_TOASTER_DANGER,
    toast: {
      text: err,
      key: errorKey,
    },
  });
}

export function fetchCards(customerCode) {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch);

    const response = await fetchApi({
      url: configs.api.card.list + '/' + customerCode,
      method: 'GET',
    });

    if (!response || !response?.list) {
      handleFail(dispatch, response?.err, 'ERROR.FETCH_FAIL');
      return;
    }

    dispatch({
      type: LOAD_CARDS_SUCCESS,
      cards: response.list.map(c => deriveRawToCard(c)),
    });
  };
}

export function addCard(values) {
  return async (dispatch: Dispatch) => {
    dispatchLoadingModal(dispatch, 'PROGRESS.SUBMITTING');

    const response = await fetchApi({
      url: configs.api.card.create,
      method: 'POST',
      param: {
        ...values,
        cardNumber: values.cardNumber.replace(/ /g, ''),
      },
    });

    if (!response || !response?.success) {
      handleFail(dispatch, response?.err, 'ERROR.ADD_CARD_FAIL', true);
      return;
    }

    dispatch({
      type: ADD_CARD_SUCCESS,
    });
    dispatch({
      type: SET_TOASTER_SUCCESS,
      toast: {
        key: 'MESSAGE.ADD_CARD_SUCCESS',
      },
    });
  };
}

export function deleteCard(cardId) {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.PROCESSING');

    const response = await fetchApi({
      url: configs.api.card.delete + cardId,
      method: 'DELETE',
    });

    if (!response || !response?.success) {
      handleFail(dispatch, response?.err, 'ERROR.ADD_CARD_FAIL', true);
      return;
    }

    dispatch({
      type: DELETE_CARD_SUCCESS,
      cardId: cardId,
    });
    toast({ message: 'MESSAGE.DELETE_CARD_SUCCESS', color: COLORS.SUCCESS });
  };
}

export function checkCustomerCode(code) {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.CHECKING');

    const response = await fetchApi({
      url: configs.api.customer.check + code,
      method: 'GET',
      // mockData: CheckCustomerCodeMock,
    });

    if (!response || response?.isValid === undefined) {
      handleFail(dispatch, response?.message, 'ERROR.INVALID_CUSTOMER_CODE', true);
      return;
    }

    dispatch({
      type: CHECK_CUSTOMER_CODE_SUCCESS,
      isValid: !!response.isValid,
      customer: response?.customer,
    });

    toast({
      message: response.isValid ? 'MESSAGE.VALID_CUSTOMER_CODE' : 'MESSAGE.INVALID_CUSTOMER_CODE',
      color: response.isValid ? COLORS.SUCCESS : COLORS.DANGER,
    });
  };
}

export function fetchTransactionHistory(param) {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.PROCESSING');

    const response = await fetchApi({
      url: configs.api.customer.transactionHistory,
      method: 'POST',
      param,
    });

    if (!response || !response?.data) {
      handleFail(dispatch, response?.message, 'ERROR.SERVER', true);
      return;
    }

    dispatch({
      type: FETCH_TRANSACTIONS_SUCCESS,
      transactions: response.data,
    });
    return;
  };
}

export function changeRequest(param) {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.PROCESSING');

    const response = await fetchApi({
      url: configs.api.customer.changeRequest,
      method: 'POST',
      param,
    });

    if (!response || !response?.success) {
      handleFail(dispatch, response?.err, 'ERROR.CHANGE_REQUEST', true);
      return;
    }

    dispatch({
      type: NOTHING_SUCCESS,
    });
    toast({ message: 'MESSAGE.CHANGE_REQUEST_SUCCESS', color: COLORS.SUCCESS });
  };
}

export function resetStatus(): CustomerActionTypes {
  return { type: RESET_STATUS };
}

export function dehydrate(): CustomerActionTypes {
  return { type: RESET_ALL };
}
