import React from 'react';
import { connect } from 'react-redux';
import { IonContent } from '@ionic/react';

import { screen } from '$gcomponents/hoc';

import { IonPageWrapper } from './styles';
import { PageWrapper } from '$gstyles/wrapper';
import intl from '$gintl';

interface RegisterCompleteScreenProps {
  email: string;
}

const RegisterCompleteScreen: React.FC<RegisterCompleteScreenProps> = ({ email = 'your email address' }) => {
  return (
    <IonPageWrapper>
      <IonContent>
        <PageWrapper className="page-wrapper">
          <h2>{intl('SCREEN.REGISTER_DONE.TITLE')}</h2>
          <div className="subtitle">{intl('SCREEN.REGISTER_DONE.SUBTITLE', { email })}</div>
        </PageWrapper>
      </IonContent>
    </IonPageWrapper>
  );
};

const connected = connect(null, null);

export default connected(screen(RegisterCompleteScreen));
