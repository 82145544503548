import LoadingModel from '$gbusiness/models/loading';

export const LOADING = 'LOADING';
export const LOADING_MODAL = 'LOADING_MODAL';
export const LOADED = 'LOADED_SUCCESS';
export const SHOW_LOGIN_MODAL = 'SHOW_LOGIN_MODAL';
export const CLOSE_LOGIN_MODAL = 'CLOSE_LOGIN_MODAL';

// Action Model
export interface LoadingType {
  type: typeof LOADING | typeof LOADING_MODAL | typeof SHOW_LOGIN_MODAL | typeof CLOSE_LOGIN_MODAL;
  loadingText?: string;
  err?: string;
}

export type LoadingActionTypes = LoadingType;

export const LOADING_INIT_STATE: LoadingModel = {
  isLoading: false,
  isLoadingModal: false,
  loadingText: 'PROGRESS.LOADING',
  hasError: false,
  err: '',
  showLoginModal: false,
};
