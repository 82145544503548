import * as Color from 'color';
import { configs } from '$configs';
import { setStorageItem, setStorageObj } from './local.storage';
import { THEMES } from '../enums';

const { DEFAULT, AUTUMN, NIGHT, NEON, DARK } = THEMES;
const { theme } = configs;

export function switchTheme(theme) {
  const cssText = CSSTextGenerator(theme || DEFAULT);

  const rootEl = document.getElementsByTagName('body')[0];
  if (rootEl) rootEl.style.cssText = cssText;

  // TODO: Store the saved theme to local storage
  setStorageItem('theme', theme);
}

export function swithCustomTheme(colorset) {
  const cssText = GenerateCSSText(colorset);

  const rootEl = document.getElementsByTagName('body')[0];
  if (rootEl) rootEl.style.cssText = cssText;

  setStorageObj('colorSet', colorset);
}

export const themes = {
  [DEFAULT]: {
    primary: theme.primary,
    secondary: theme.secondary,
    tertiary: theme.tertiary,
    success: theme.success,
    warning: theme.warning,
    danger: theme.danger,
    dark: theme.dark,
    medium: theme.medium,
    light: theme.light,
  },
  [AUTUMN]: {
    primary: '#F78154',
    secondary: '#4D9078',
    tertiary: '#B4436C',
    light: '#FDE8DF',
    medium: '#FCD0A2',
    dark: '#B89876',
  },
  [NIGHT]: {
    primary: '#8CBA80',
    secondary: '#FCFF6C',
    tertiary: '#FE5F55',
    medium: '#BCC2C7',
    dark: '#F7F7FF',
    light: '#495867',
  },
  [NEON]: {
    primary: '#39BFBD',
    secondary: '#4CE0B3',
    tertiary: '#FF5E79',
    light: '#F4EDF2',
    medium: '#B682A5',
    dark: '#34162A',
  },
  [DARK]: {
    primary: '#428cff',
    secondary: '#50c8ff',
    tertiary: theme.tertiary,
    medium: '#989aa2',
    dark: '#f4f5f8',
    light: '#222428',
  },
};

function contrast(color, ratio = 0.8) {
  color = Color(color);
  return color.luminosity() > (configs.display.contrastThres || 0.7)
    ? color.darken(ratio)
    : color.lighten(ratio);
}

function blockOrWhite(color) {
  color = Color(color);
  return color.luminosity() > (configs.display.contrastThres || 0.7) ? '#333333' : '#ffffff';
}

function hexToRgb(hex) {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    const result = [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',');
    return result;
  }
  throw new Error('Bad Hex');
}

function CSSTextGenerator(key) {
  const newColorset = themes[key];
  if (!newColorset) return '';

  return GenerateCSSText(newColorset);
}

function GenerateCSSText(newColorset) {
  const colors = { ...themes[DEFAULT], ...newColorset };
  const { primary, secondary, tertiary, success, warning, danger, dark: oDark, medium, light } = colors;

  const dark = !newColorset.dark ? blockOrWhite(light) : oDark;
  const textColor = configs.theme.text;

  const shadeRatio = 0.15;
  const tintRatio = 0.15;

  return `
    --ion-border-color: ${contrast(light, 0.1)};
    --ion-label-color: ${contrast(textColor, 0.2)};
    --ion-font-family: ${configs.theme.fontFamily};
    --ion-color: ${contrast(textColor, 0.2)};
    --ion-link-color: ${Color(light).luminosity() > 0.7 ? primary : Color(primary).lighten(0.3)};

    --ion-color-base: ${light};
    --ion-color-contrast: ${dark};
    --ion-background-color: ${light};
    --ion-text-color: ${textColor};
    --color: ${textColor};
    --ion-toolbar-background-color: ${contrast(light, 0.1)};
    --ion-toolbar-text-color: ${blockOrWhite(textColor)};
    --ion-item-background-color: ${contrast(light, 0.3)};
    --ion-item-text-color: ${contrast(textColor, 0.3)};

    --ion-color-primary: ${primary};
    --ion-color-primary-rgb: ${hexToRgb(primary)};
    --ion-color-primary-contrast: ${contrast(primary, 1.6)};
    --ion-color-primary-contrast-bw: ${blockOrWhite(primary)};
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade:  ${Color(primary).darken(shadeRatio)};
    --ion-color-primary-tint:  ${Color(primary).lighten(tintRatio)};
    --ion-color-secondary: ${secondary};
    --ion-color-secondary-rgb: ${hexToRgb(secondary)};
    --ion-color-secondary-contrast: ${contrast(secondary)};
    --ion-color-secondary-contrast-bw: ${blockOrWhite(secondary)};
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade:  ${Color(secondary).darken(shadeRatio)};
    --ion-color-secondary-tint: ${Color(secondary).lighten(tintRatio)};
    --ion-color-tertiary:  ${tertiary};
    --ion-color-tertiary-rgb: ${hexToRgb(tertiary)};
    --ion-color-tertiary-contrast: ${contrast(tertiary)};
    --ion-color-tertiary-contrast-bw: ${blockOrWhite(tertiary)};
    --ion-color-tertiary-contrast-rgb: 255,255,255;
    --ion-color-tertiary-shade: ${Color(tertiary).darken(shadeRatio)};
    --ion-color-tertiary-tint:  ${Color(tertiary).lighten(tintRatio)};
    --ion-color-success: ${success};
    --ion-color-success-rgb: ${hexToRgb(success)};
    --ion-color-success-contrast: ${contrast(success)};
    --ion-color-success-contrast-bw: ${blockOrWhite(success)};
    --ion-color-success-contrast-rgb: 255,255,255;
    --ion-color-success-shade: ${Color(success).darken(shadeRatio)};
    --ion-color-success-tint: ${Color(success).lighten(tintRatio)};
    --ion-color-warning: ${warning};
    --ion-color-warning-rgb: ${hexToRgb(warning)};
    --ion-color-warning-contrast: ${contrast(warning)};
    --ion-color-warning-contrast-bw: ${blockOrWhite(warning)};
    --ion-color-warning-contrast-rgb: 255,255,255;
    --ion-color-warning-shade: ${Color(warning).darken(shadeRatio)};
    --ion-color-warning-tint: ${Color(warning).lighten(tintRatio)};
    --ion-color-danger: ${danger};
    --ion-color-danger-rgb: ${hexToRgb(danger)};
    --ion-color-danger-contrast: ${contrast(danger)};
    --ion-color-danger-contrast-bw: ${blockOrWhite(danger)};
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: ${Color(danger).darken(shadeRatio)};
    --ion-color-danger-tint: ${Color(danger).lighten(tintRatio)};
    --ion-color-dark: ${dark};
    --ion-color-dark-rgb: ${hexToRgb(dark)};
    --ion-color-dark-contrast: ${contrast(dark)};
    --ion-color-dark-contrast-bw: ${blockOrWhite(dark)};
    --ion-color-dark-contrast-rgb: 255,255,255;
    --ion-color-dark-shade: ${Color(dark).darken(shadeRatio)};
    --ion-color-dark-tint: ${Color(dark).lighten(tintRatio)};
    --ion-color-medium: ${medium};
    --ion-color-medium-rgb: ${hexToRgb(medium)};
    --ion-color-medium-contrast: ${contrast(medium)};
    --ion-color-medium-contrast-bw: ${blockOrWhite(medium)};
    --ion-color-medium-contrast-rgb: 255,255,255;
    --ion-color-medium-shade: ${Color(medium).darken(shadeRatio)};
    --ion-color-medium-tint: ${Color(medium).lighten(tintRatio)};
    --ion-color-light: ${light};
    --ion-color-light-rgb: ${hexToRgb(light)};
    --ion-color-light-contrast: ${contrast(light)};
    --ion-color-light-contrast-bw: ${blockOrWhite(light)};
    --ion-color-light-contrast-rgb: 0,0,0;
    --ion-color-light-shade: ${Color(light).darken(shadeRatio)};
    --ion-color-light-tint: ${Color(light).lighten(tintRatio)};`;
}
