import styled from 'styled-components';
import { Div, FONT, SPACE, WEIGHT } from '$gstyles';
import { desktopStyle } from '$gstyles/utils';
import { Modal } from '$gcomponents/reusables';

export const Wrapper = styled(Div)`
  max-width: 1200px;
  margin: auto;

  .label {
    font-weight: ${WEIGHT.SEMI_BOLD};
  }

  .thankyou-paper {
    margin: auto;
    max-width: 500px;
    min-width: 300px;
    text-align: center;

    .icon-wrapper {
      display: flex;
      align-items: center;

      max-width: 387px;
      max-height: 387px;
      width: 60vmin;
      height: 60vmin;
      min-width: 200px;
      min-height: 200px;
      border-radius: 50%;
    }
    .check.icon-wrapper {
      background-color: rgba(111, 207, 151, 0.17);
    }
    .close.icon-wrapper {
      background-color: rgba(235, 87, 87, 0.1);
    }
    .check-icon {
      /* font-size: 280px; */
      font-size: clamp(150px, 45vmin, 280px);
      color: #33b469;
    }
    .close-icon {
      font-size: clamp(100px, 32vmin, 190px);
      color: #eb5757;
    }
    .message {
      font-weight: ${WEIGHT.SEMI_BOLD};
      font-size: ${FONT.MEDIUM};
      margin: ${SPACE.LARGE};
      color: #828282;
    }
    button {
      margin-top: 30px;
      width: 60vmin;
      min-width: 200px;
      max-width: 387px;
    }
  }

  .button-groups {
    justify-content: space-between;
    margin-top: ${SPACE.LARGE};

    .MuiButtonBase-root {
      width: 45%;
      min-height: 56px;
    }
  }

  .invoice-section {
    margin: auto;
    margin-top: ${SPACE.XLARGE};
    background-color: #f2f2f2;
    padding: ${SPACE.MEDIUM};
    color: #000000;
    max-width: 680px;
    min-width: 300px;

    .logo-image img {
      max-width: 190px;
      margin-top: ${SPACE.LARGE};
    }
    .invoice-number {
      margin-top: ${SPACE.LARGE};
      font-weight: ${WEIGHT.SEMI_BOLD};
      color: var(--ion-text-color);
    }

    .header-section {
      font-size: ${FONT.XLARGE};
    }
    .client-section {
      min-height: 200px;
      .left,
      .right {
        margin: ${SPACE.LARGE} 0;
      }
    }
    .amount-section {
      height: 150px;
      .left {
        color: #626262;
      }
      .right {
        margin-left: ${SPACE.LARGE};
      }
    }
    .footer-section {
      /* background: #ffddff; */
      height: 140px;
    }
  }

  .MuiPaper-root {
    margin: auto;
    box-shadow: none;
  }

  .form {
    max-width: 680px;
    min-width: 300px;
    .subtitle {
      color: #828282;
    }
    .form-group {
      .row {
        margin: ${SPACE.XLARGE} 0;
      }
    }
  }

  ${desktopStyle(`
      .form-container {
        padding: 0px ${SPACE.XLARGE};
      }
      .invoice-number {
        text-align: right;
      }
      .invoice-section {
        padding: ${SPACE.XLARGE};
      }
      .MuiPaper-root {
        margin: auto;
        box-shadow: 2px 2px 10px 0px rgb(0 0 0 / 20%);
      }
      .header-section {
        height: 110px;
      }
      .client-section {
        height: 200px;
      }
  `)}
`;

export const ModalWrapper = styled(Modal)`
  .title {
    font-size: 2rem !important;
    padding: ${SPACE.LARGE};
    font-weight: ${WEIGHT.SEMI_BOLD};
  }
  .header-title {
    margin: 0 0 24px 0 !important;
    > div {
      font-size: 1.6rem !important;
    }
  }
  ion-footer {
    .MuiButtonBase-root {
      margin: 10px;
    }
  }
`;
