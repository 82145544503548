import { FONT, SPACE } from '$gstyles';
import { IonPage } from '@ionic/react';
import styled from 'styled-components';

export const IonPageWrapper = styled(IonPage)`
  .send-text {
    margin-left: ${SPACE.MEDIUM};
  }
  .total-count {
    margin-left: ${SPACE.LARGE};
    opacity: 0.7;
    font-size: ${FONT.SMALL};
  }
  .no-result {
    display: none;
  }
`;

export const BlankMask = styled.div`
  position: absolute;
  z-index: 102;
  left: 0;
  right: 0;
  top: 60px;
  bottom: 0;
  background: #fff;
`;
