import styled from 'styled-components';
import { Modal } from '$gcomponents/reusables';

export const ModalWrapper = styled(Modal)`
  --background: var(--ion-color-light);
  --color: var(--ion-color-light-contrast);

  ion-content {
    --background: var(--ion-background-color);
  }

  .MuiFormLabel-root {
    color: var(--ion-text-color);
  }
`;
