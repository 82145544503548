import React from 'react';
import { Button } from '@material-ui/core';
import intl from '$intl';

interface RightComponentProps {
  onClickSend: () => void;
  onClickDelete: () => void;
  isSelected: boolean;
}

const RightComponent: React.FC<RightComponentProps> = ({ isSelected, onClickSend, onClickDelete }) => {
  if (!isSelected) return null;

  return (
    <>
      <Button color="secondary" variant="contained" onClick={onClickDelete}>
        {intl('BUTTON.DELETE_SELECTED')}
      </Button>
      <Button color="secondary" variant="contained" onClick={onClickSend}>
        {intl('SCREEN.BILLS.BUTTON_SEND')}
      </Button>
    </>
  );
};

export default RightComponent;
