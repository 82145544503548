export const fullScreen = `
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

export const vcenter = `
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; 
`;

export const flex = `
  display: flex;
  align-items: center;
`;

export const background = `
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  width: 100%;
  height: 100%;
`;

export const ellipsis = `
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
