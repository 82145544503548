import React, { ReactNode, useEffect, useState } from 'react';

import CellModel from '$gbusiness/models/cell';
import { TableModel } from '$gbusiness/models/table';
import { ApiTable, Table, TabSimple } from '$gcomponents/reusables';
import { Table2Wrapper } from './styles';
import OptionModel from '$gbusiness/models/option';
import { Button, TabsProps } from '@material-ui/core';
import { PopoverWrapper } from '$gcomponents/reusables/popOver/styles';
import ItemActionModel from '$gbusiness/models/itemAction';
import { convertToItemAction } from '$ghelpers/table';
import intl from '$gintl';
import { SORT } from '$gbusiness/enums';
import { sortArray } from '$ghelpers/util';

interface TableViewProps {
  TABLE: Array<CellModel>;
  tableConfig: TableModel;
  children?: ReactNode;
  data?: Array<any>;
  onDrag?: Function;
  filter?: any;
  onSelection?: Function;
  itemActions?: Array<ItemActionModel>;
  onTableLoad?: Function;
  className?: string;
  tabs?: {
    TABS: Array<OptionModel>;
    index: number;
    onChange: Function;
    hasBadge?: boolean;
    color?: TabsProps['indicatorColor'];
    itemWidth?: string;
    height?: string;
    variant?: string;
    className?: string;
    fullWidth?: boolean;
  };
}

const TableView2: React.FC<TableViewProps> = ({
  TABLE,
  data,
  className,
  tableConfig,
  onDrag,
  children,
  filter,
  onTableLoad,
  itemActions,
  tabs,
  onSelection,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [row, setRow] = useState<any>(null);
  const [fixedData, setFixedData] = useState<Array<any>>(data || []);
  const [sorting, setSorting] = useState(['', '']);

  useEffect(() => {
    if (!sorting[0] || !sorting[1]) {
      setFixedData(data || []);
      return;
    }
    setFixedData(sortArray(data, sorting[0], sorting[1]));
  }, [data, sorting]);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>, row) => {
    setRow(row);
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setRow(null);
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    setAnchorEl(null);
  };
  const onChangeSort = (key, order) => {
    setSorting([key, sorting[1] === SORT.ASC ? SORT.DSC : SORT.ASC]);
  };
  const open = Boolean(anchorEl);

  return (
    <Table2Wrapper className={className || ''}>
      {!!children && children}
      {!!tabs && (
        <TabSimple
          TABS={tabs?.TABS}
          index={tabs?.index}
          onChangeIndex={newIndex => tabs.onChange(newIndex)}
          color={tabs.color || 'primary'}
          itemWidth={tabs.itemWidth || '100px'}
          hasBadge={tabs.hasBadge}
          height={tabs.height || '40px'}
          variant={tabs.variant || 'filled'}
          className={tabs.className || 'table-tabs'}
          fullWidth={tabs.fullWidth}
        />
      )}
      {!data ? (
        <ApiTable
          TABLE={TABLE}
          dataSource={tableConfig.dataSource}
          styles={tableConfig.styles}
          filters={filter}
          onSelection={onSelection}
          onTableLoad={onTableLoad}
          resetSelection={filter?.resetSelection}
          itemActions={convertToItemAction(itemActions, handlePopoverOpen)}
        />
      ) : (
        <Table
          TABLE={TABLE}
          styles={tableConfig.styles}
          onSelection={onSelection}
          onDrag={onDrag}
          data={fixedData}
          onChangeSort={onChangeSort}
          resetSelection={filter?.resetSelection}
          itemActions={convertToItemAction(itemActions, handlePopoverOpen)}
        />
      )}

      {itemActions && (
        <PopoverWrapper
          className={'backdrop'}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          onClose={handlePopoverClose}>
          <div className="pop-container">
            {itemActions.map((a, i) => {
              if (a.hide && row && a.hide(row)) return null;
              return (
                <Button
                  fullWidth
                  key={i}
                  onClick={e => {
                    a.onClick(row);
                    handlePopoverClose(e);
                  }}>
                  {intl(a.label)}
                </Button>
              );
            })}
          </div>
        </PopoverWrapper>
      )}
    </Table2Wrapper>
  );
};

export default TableView2;
