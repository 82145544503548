import { configs } from '$configs';
import { fetchApi } from '$gbusiness/services/api';
import { randomInt } from '$ghelpers/util';

export const sendInvoicesByChunk = async list => {
  const response = await fetchApi({
    url: configs.api.invoice.email,
    param: {
      invoices: JSON.stringify(list),
      email: process.env.REACT_APP_DEFAULT_EMAIL || '',
      shouldUpdate: true,
    },
  });

  if (!response || response?.numSuccess === undefined) {
    return [list.length, 0];
  }

  return [response.numFailure, response.numSuccess];
};

export const splitList = (list, min = 0, max = 5) => {
  if (list.count < 5) return list;
  const tempList: Array<number> = [...list];
  const newArray = [] as any;

  while (tempList.length > 0) {
    newArray.push(tempList.splice(0, randomInt(min, max)));
  }

  return newArray;
};
