export default interface CardModel {
  cardId?: number;
  cardType: string;
  last4: string;
  expiry: string;
  cvv: string;
  cardHolder?: string;
  token?: string;
}

export const deriveRawToCard = raw => {
  return {
    cardId: raw.id,
    cardType: raw.cardType,
    last4: raw.cardNumber,
    expiry: raw.expiry,
    cvv: raw.cvc || '',
    cardHolder: raw.cardHolder,
  };
};

// {
//   list: [
//     '{{repeat(2, 5)}}',
//     {
//       cardId: "{{index() + 1}}",
//       last4: "{{integer(1000, 9999)}}",
//       expiry: "{{integer(1, 12) + '/' + integer(22, 25)}}",
//       cardHolder: "{{firstName() + ' ' + surname()}}",
//       token: "{{guid()}}",
//       createdAt: "{{date(new Date(2021, 0, 1), new Date(), 'YYYY-MM-dd HH:mm:ss')}}",
//     }
//   ]
// }
