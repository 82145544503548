import {
  subDays,
  startOfWeek,
  startOfMonth,
  startOfToday,
  startOfYear,
  endOfToday,
  endOfYesterday,
  startOfYesterday,
} from 'date-fns';

import { PERIODS } from '../enums';

export const toNumber = str => {
  if (!str) return 0;
  return Math.round(Number(str.toString().replace(/,/g, '')) * 100) / 100;
};

export const getDateRangeFromPeriod = period => {
  switch (period) {
    case PERIODS.TODAY:
      return [startOfToday(), endOfToday()];
    case PERIODS.YESTERDAY:
      return [startOfYesterday(), endOfYesterday()];
    case PERIODS.LAST_7_DAYS:
      const last7 = subDays(startOfToday(), 7);
      return [last7, endOfToday()];
    case PERIODS.LAST_30_DAYS:
      const last30 = subDays(startOfToday(), 30);
      return [last30, endOfToday()];
    case PERIODS.LAST_365_DAYS:
      const last365 = subDays(startOfToday(), 365);
      return [last365, endOfToday()];
    case PERIODS.THIS_WEEK:
      return [startOfWeek(startOfToday()), endOfToday()];
    case PERIODS.THIS_MONTH:
      return [startOfMonth(startOfToday()), endOfToday()];
    case PERIODS.THIS_YEAR:
      return [startOfYear(startOfToday()), endOfToday()];
    default:
      return [null, endOfToday()];
  }
};
