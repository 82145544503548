import { Div, Mixins } from '$gstyles';
import styled from 'styled-components';

export const Wrapper = styled.div`
  min-height: 100px;
  height: ${p => p.height || '100%'};
  width: ${p => p.width || 'auto'};
  margin: auto;

  .drop-container {
    display: flex;
  }

  .dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-style: dashed;
    background-color: #eee;
    border-color: #ccc;
    color: var(--ion-color);
    outline: none;
    transition: border 0.24s ease-in-out;
    height: 100%;

    p {
      display: flex;
      align-items: center;
      height: 100%;
      text-align: center;
    }
  }

  .inputmode-wrapper {
    height: 100%;
    border: 5px solid #ccc;

    .thumbs-container {
      display: 'flex';
      flex-direction: 'row';
      flex-wrap: 'wrap';
      width: 100%;
      margin-top: 16;
    }
  }
  .replace-button {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .thumb {
    display: inline-flex;
    border-radius: 2;
    border: 1px solid #eaeaea;
    margin-bottom: 8px;
    margin-right: 8px;
    height: ${p => p.height || 'auto'};
    width: ${p => p.width || 'auto'};
    padding: 4;
    box-sizing: border-box;
  }

  .thumb-inner {
    display: flex;
    min-width: 0;
    overflow: hidden;
  }

  .img {
    display: block;
    width: auto;
    height: 100%;
  }

  .crop-container {
    width: 600px;
  }
`;

export const ImgWrapper = styled.img`
  ${Mixins.background}
  width: 100%;
  height: 100%;
`;

export const ImageWrapper = styled(Div)`
  ${Mixins.background}
  background-color: #fff;
  background-size: ${p => (p.size === 'contain' ? (p.size === 'stretch' ? '' : 'contain') : 'cover')};
  ${p => p.size === 'stretch' && 'background-size: 100% 100%;'}
  background-image: url(${p => p.src});
  width: 100%;
  height: 100% !important;
`;
