import React from 'react';
import intl from '$intl';

import { ChipWrapper } from './styles';

interface TopSectionProps {
  label: string;
  value: string;
  color?: string;
}

const ChipBox: React.FC<TopSectionProps> = ({ label, value, color }) => {
  return (
    <ChipWrapper color={color}>
      <div>
        <div className="value">{value}</div>
        <div className="label">{intl(label) || label}</div>
      </div>
    </ChipWrapper>
  );
};

export default ChipBox;
