import styled from 'styled-components';
import { IonPage } from '@ionic/react';
import { SPACE, WEIGHT } from '$gstyles';

export const IonPageWrapper = styled(IonPage)`
  .column {
    flex-wrap: wrap;
  }

  .top-top {
    > * {
      margin-right: ${SPACE.LARGE};
      margin-bottom: ${SPACE.LARGE};
    }
  }
  .back-button {
    display: flex;
    align-items: center;
    background-color: #f2f2f2;
    border-radius: 999px;
    font-size: 1.2rem;
    cursor: pointer;
    padding: ${SPACE.SMALL} ${SPACE.MEDIUM};
    > div {
      font-weight: ${WEIGHT.SEMI_BOLD};
      margin: 0 ${SPACE.SMALL};
    }
  }
  .customer-code {
    font-size: 1.8rem;
    font-weight: ${WEIGHT.SEMI_BOLD};
  }
  .status {
    text-transform: uppercase;
    font-weight: ${WEIGHT.SEMI_BOLD};
  }
`;

export const ChipWrapper = styled.div`
  background-color: ${p => p.color || '#F5F9FE'};
  height: 75px;
  align-items: center;
  padding: ${SPACE.MEDIUM};
  border: 1px solid #ddd;
  display: flex;
  margin-right: ${SPACE.LARGE};
  margin-bottom: ${SPACE.LARGE};

  > div {
    justify-content: center;
    text-align: center;
    line-height: 28px;
  }

  .label {
    font-size: 0.9em;
  }
  .value {
    font-size: 1.3em;
    font-weight: ${WEIGHT.SEMI_BOLD};
  }
`;
