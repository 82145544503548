import React from 'react';
import Grid from '@material-ui/core/Grid';
import {
  Button,
  ButtonProps as Props,
  ButtonGroup,
  Popper,
  Paper,
  Grow,
  MenuItem,
  MenuList,
  ClickAwayListener,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { Wrapper } from './styles';

interface ButtonProps {
  label: string;
  onClick: Function;
}

interface SplitButtonProps {
  options: Array<ButtonProps>;
  defaultIndex: number;
  color?: Props['color'];
}

const SplitButton: React.FC<SplitButtonProps> = ({ options, color = 'primary', defaultIndex, ...rest }) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(defaultIndex);

  const handleClick = () => {
    const selectedOption = options[selectedIndex];
    if (selectedOption?.onClick) selectedOption.onClick();
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    option: ButtonProps,
    index: number,
  ) => {
    setSelectedIndex(index);
    setOpen(false);
    option.onClick();
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <Wrapper container direction="column" alignItems="center">
      <Grid item xs={12} className="container">
        <ButtonGroup variant="contained" color={color} {...rest} ref={anchorRef}>
          <Button onClick={handleClick}>{options[selectedIndex].label}</Button>
          <Button
            color={color}
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}>
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}>
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {options.map((option, index) => (
                      <MenuItem
                        key={index}
                        disabled={index === 2}
                        selected={index === selectedIndex}
                        onClick={event => handleMenuItemClick(event, option, index)}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Wrapper>
  );
};

export default SplitButton;
