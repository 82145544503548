import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  Checkbox,
  TableSortLabel,
  Table as MTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useMediaQuery,
  List,
  ListItem,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';

import intl, { Text } from '$gintl';
import { SORT } from '$gbusiness/enums';
import { util } from '$ghelpers';
import { generateSelects } from './utils';
import { MobileWrapper, Vwrapper } from './styles';
import { Desktop, Div, ENUMS, SPACE } from '$gstyles';
import { TableDisplayModel, TableStylesModel, defaultDisplay, defaultStyles } from '$gbusiness/models/table';
import CellModel from '$gbusiness/models/cell';
import { DragDropContextWrapper } from './droppables';

interface TableProps {
  display?: TableDisplayModel;
  styles: TableStylesModel;
  TABLE: Array<CellModel>;
  data: Array<any>;
  onChangePage?: Function;
  onChangeSort?: Function;
  onSelection?: Function;
  onClickRow?: (r) => void;
  onDrag?: Function;
  itemActions?: any;
  resetSelection?: boolean;
}

const Table: React.FC<TableProps> = React.memo(
  ({
    display = defaultDisplay,
    styles = defaultStyles,
    TABLE,
    data,
    onDrag,
    onChangePage = () => {},
    onSelection,
    onClickRow,
    onChangeSort = () => {},
    itemActions,
    resetSelection = false,
  }) => {
    const {
      page,
      pageSize: rowsPerPage = defaultDisplay.pageSize,
      totalDataSize = defaultDisplay.totalDataSize,
      sortKey,
      sortOrder,
      isSelectable,
    } = display;
    const selectable = isSelectable || onSelection;
    const pageSize = rowsPerPage === 0 ? totalDataSize : rowsPerPage || totalDataSize;
    const {
      responsive,
      color,
      isStripped,
      cellPadding,
      checkWidth,
      minWidth,
      maxWidth,
      disableSort,
      fontSize,
      fontColor,
      borderColor,
      headerHeight = 40,
      rowHeight = 40,
      setRowClass,
    } = styles;
    const [checkedRows, setCheckedRows] = useState<Array<boolean>>(generateSelects(pageSize));
    const [selectAll, setSelectAll] = useState<boolean>(false);
    const isMobile = useMediaQuery(`(max-width:${ENUMS.MOBILE_WIDTH})`);
    const numberOfPages = Math.floor((totalDataSize - 1) / pageSize) + 1;

    useEffect(() => {
      setCheckedRows(generateSelects(pageSize));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetSelection]);

    const checkRow = i => {
      const rows = [...checkedRows];
      rows[i] = !rows[i];
      setCheckedRows(rows);
      if (onSelection) onSelection(rows);
    };

    const checkAllRows = () => {
      const newSelectAll = !selectAll;
      const rows = generateSelects(pageSize, newSelectAll);
      setCheckedRows(rows);
      if (onSelection) onSelection(rows);
      setSelectAll(newSelectAll);
    };

    if (!data.length) {
      return <div></div>;
    }

    const getItemStyle = (isDragging, draggableStyle) => ({
      display: 'flex',
      alignItems: 'center',
      ...(isDragging && { backgroundColor: 'rgba(0,0,0,.1)' }),
      ...draggableStyle,
    });

    const renderRow = (row, i, provided, snapshot) => {
      return (
        <TableRow
          ref={provided?.innerRef}
          {...provided?.draggableProps}
          {...provided?.dragHandleProps}
          style={getItemStyle(snapshot?.isDragging, provided?.draggableProps?.style)}
          component="div"
          key={i}
          className={`virtualized-row ${onClickRow ? 'pointer' : ''} ${row?.className || ''} ${setRowClass &&
            setRowClass(row)}`}
          onClick={onClickRow ? () => onClickRow(row) : undefined}>
          {selectable && (
            <TableCell
              component="div"
              className="center select-cell"
              style={{ flex: `0 1 ${checkWidth || 60}px` }}>
              <Checkbox checked={checkedRows[i]} onClick={() => checkRow(i)} />
            </TableCell>
          )}
          {TABLE.filter(cell => !cell.isHidden).map((cell, j) => (
            <TableCell
              component="div"
              key={j}
              className={`${cell.align || ''} ${cell.className || ''}`}
              style={{
                flex: `0 1 ${cell.width}px`,
                ...(cell.minWidth && { minWidth: cell.minWidth }),
                ...(cell.maxWidth && { maxWidth: cell.maxWidth }),
              }}>
              {cell.component && cell.component(row, itemActions, i)}
              {!cell.component && _.get(row, cell.value)}
            </TableCell>
          ))}
        </TableRow>
      );
    };

    const rendered = (
      <DragDropContextWrapper onDrag={onDrag}>
        <Vwrapper
          minWidth={minWidth}
          maxWidth={maxWidth}
          headerColor={color}
          fontSize={fontSize}
          fontColor={fontColor}
          stripped={isStripped}
          cellPadding={cellPadding}
          borderColor={borderColor}
          rowHeight={rowHeight}
          headerHeight={headerHeight!}>
          <MTable component="div" size="small" className="virtualized-table">
            {TABLE && (
              <TableHead component="div">
                <TableRow component="div" className="virtualized-row">
                  {selectable && (
                    <TableCell
                      component="div"
                      className="center select-cell"
                      style={{ flex: `0 1 ${checkWidth || 60}px` }}>
                      <span className="selectCol">
                        <Checkbox checked={selectAll} className="select-all" onClick={checkAllRows} />
                      </span>
                    </TableCell>
                  )}
                  {TABLE.filter(cell => !cell.isHidden).map((cell, i) => {
                    const cellSortKey = cell.sortKey || cell.value;
                    return (
                      <TableCell
                        component="div"
                        key={i}
                        className={`${cell.align || ''} ${cell.className || ''}`}
                        style={{
                          flex: `0 1 ${cell.width}px`,
                          ...(cell.minWidth && { minWidth: cell.minWidth }),
                          ...(cell.maxWidth && { maxWidth: cell.maxWidth }),
                        }}>
                        {cell.sortable && !disableSort && (
                          <TableSortLabel
                            className={cell.align || ''}
                            active={sortKey === cellSortKey}
                            direction={sortOrder === SORT.ASC ? 'asc' : 'desc'}
                            onClick={() => {
                              onChangeSort(
                                cellSortKey,
                                sortKey !== cellSortKey ? '' : util.changeSort(sortOrder),
                              );
                            }}>
                            <Text k={cell.label} />
                          </TableSortLabel>
                        )}
                        {(!cell.sortable || disableSort) && <Text k={cell.label} />}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
            )}

            {onDrag ? (
              <Droppable
                droppableId="droppable"
                renderClone={(provided, snapshot, rubric) => {
                  const i = rubric.source.index;
                  const row = data[i];
                  return renderRow(row, i, provided, snapshot);
                }}>
                {(provided, snapshot) => (
                  <TableBody component="div" {...provided.droppableProps} ref={provided.innerRef}>
                    {data.map((row, i) => (
                      <Draggable
                        isDragDisabled={!onDrag}
                        key={(row.id || i).toString()}
                        draggableId={(row.id || i).toString()}
                        index={i}>
                        {(provided, snapshot) => renderRow(row, i, provided, snapshot)}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </TableBody>
                )}
              </Droppable>
            ) : (
              <TableBody component="div">{data.map((row, i) => renderRow(row, i, null, null))}</TableBody>
            )}
          </MTable>

          {numberOfPages > 1 && (
            <Div className="pagination" padding={`${SPACE.MEDIUM} 0 `}>
              <Pagination
                count={numberOfPages}
                siblingCount={isMobile ? 1 : 2}
                page={page}
                boundaryCount={1}
                showFirstButton={!isMobile}
                showLastButton={!isMobile}
                onChange={(e, pageNumber) => {
                  onChangePage(pageNumber);
                }}
              />
            </Div>
          )}
        </Vwrapper>
      </DragDropContextWrapper>
    );

    if (responsive) {
      return (
        <DragDropContext>
          <MobileWrapper>
            <List className="list">
              {data.map((row, index) => {
                return (
                  <ListItem
                    className={`${setRowClass && setRowClass(row)}`}
                    key={index}
                    onClick={onClickRow ? () => onClickRow(row) : undefined}>
                    {selectable && <Checkbox checked={checkedRows[index]} onClick={() => checkRow(index)} />}
                    {TABLE.filter(cell => !cell.isHidden && cell.className?.includes('image')).map(
                      (cell, i) => {
                        return (
                          <div key={i} className={`${cell.className || ''}`}>
                            {cell.component && cell.component(row, itemActions, i)}
                          </div>
                        );
                      },
                    )}
                    <div className="text">
                      {TABLE.filter(cell => !cell.isHidden && !cell.className?.includes('action')).map(
                        (cell, i) => {
                          if (cell.className?.includes('desktop') || cell.className?.includes('image'))
                            return null;
                          return (
                            <div key={i} className={`${cell.className || ''}`}>
                              {!(cell.className || '').includes('no-label') && (
                                <span className="label">{intl(cell.label)}: </span>
                              )}
                              <span className="value">
                                {cell.component && cell.component(row, itemActions, index)}
                                {!cell.component && _.get(row, cell.value)}
                              </span>
                            </div>
                          );
                        },
                      )}
                    </div>
                    {TABLE.filter(cell => !cell.isHidden && cell.className?.includes('action')).map(
                      (cell, i) => {
                        return (
                          <div key={i} className={`${cell.className || ''}`}>
                            {cell.component && cell.component(row, itemActions, index)}
                            {!cell.component && _.get(row, cell.value)}
                          </div>
                        );
                      },
                    )}
                  </ListItem>
                );
              })}
            </List>
          </MobileWrapper>
          <Desktop>{rendered}</Desktop>
        </DragDropContext>
      );
    } else return rendered;
  },
);

export default Table;
