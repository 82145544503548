import React, { useState } from 'react';
import { connect } from 'react-redux';
import { endOfToday, startOfToday, sub } from 'date-fns';

import { screen } from '$gcomponents/hoc';
import * as TABLE from './paymentLinkTable';
import ScreenSection, { tabModel } from '../../transactionsScreen/screenSection';
import intl from '$intl';
import { dialog } from '$gcomponents/reusables';
import { invoiceActions } from '$business/redux/invoice';
import TransactionHistoryModal from '../../_paymentScreen/components/transactionHistoryModal';
import { customerActions } from '$business/redux/customer';

const TABS: Array<tabModel> = [
  { label: 'SCREEN.INVOICE.TABS.ALL', value: '' },
  { label: 'SCREEN.INVOICE.TABS.SENT', value: 'sentAt' },
  { label: 'SCREEN.INVOICE.TABS.VIEWED', value: 'viewedAt' },
  { label: 'SCREEN.INVOICE.TABS.PAID', value: 'paidAt' },
];

interface InvoiceLinkScreenProps {
  sendReminders: Function;
  deleteInvoices: Function;
  transactions: Array<any>;
  fetchTransactionHistory: Function;
  refresh: Function;
}

const InvoiceLinkScreen: React.FC<InvoiceLinkScreenProps> = ({
  refresh,
  transactions,
  fetchTransactionHistory,
  sendReminders,
  deleteInvoices,
}) => {
  const [showHistoryModal, setShowHistoryModal] = useState(false);

  const viewTransactionHistory = async row => {
    const { customerCode, billDate, projNo } = row;
    const param = {
      customerCode,
      billDate,
      projNo,
    };
    fetchTransactionHistory(param);
    setShowHistoryModal(true);
  };

  const itemActions = {
    deleteInvoice: (row, onRefresh) => {
      dialog.confirm({
        message: 'MESSAGE.DELETE_WARNING',
        onPress: async () => {
          await deleteInvoices([row.invoiceId]);
          onRefresh();
        },
      });
    },
    sendReminder: row => {
      if (row.customerCode && row.email) sendReminders([row.invoiceId]);
      else dialog.alert({ text: 'Sorry, but an email is not available for this invoice' });
    },
    transactionHistory: row => {
      viewTransactionHistory(row);
    },
  };

  return (
    <>
      <ScreenSection
        exportSettings={TABLE.exportSettings}
        itemActions={itemActions}
        title={intl('SCREEN.INVOICE.TITLE_LINKS')}
        TABLE={TABLE}
        TABS={TABS}
        filter={{ dateStart: sub(startOfToday(), { days: 1000 }), dateEnd: endOfToday() }}
      />
      <TransactionHistoryModal
        show={showHistoryModal}
        onClose={() => setShowHistoryModal(false)}
        transactions={transactions}
      />
    </>
  );
};

const mapStateToProps = state => ({
  resetOnRoute: true,
  transactions: state.customer.transactions,
});

const mapDispatchToProps = {
  fetchTransactionHistory: n => customerActions.fetchTransactionHistory(n),
  sendReminders: invoiceActions.sendReminders,
  deleteInvoices: invoiceActions.deleteInvoices,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(InvoiceLinkScreen));
