import { Dispatch } from 'redux';

import { LOADING } from '$gbusiness/redux/loading/types';
import { LOAD_STORAGE } from '$gbusiness/redux/localStorage/types';
import { apiService, localStorage, theme } from '$gbusiness/services';
import { ROUTES } from '$business/enums';
// import mockData from '$gbusiness/mocks/auth/init.json';

import { AppModel } from '../';
import { INIT_SUCCESS } from './types';
import { configs } from '$configs';
import { SET_CUSER } from '$gbusiness/redux/currentUser/types';
import UserModel, { defaultUser, deriveRawToUser } from '$gbusiness/models/user';

function restoreTheme(localTheme) {
  theme.switchTheme(localTheme);
}

async function loadStorage(dispatch) {
  const storageData = await localStorage.getStorageData();

  dispatch({
    type: LOAD_STORAGE,
    localStorage: storageData,
  });
}

async function fetchStore(dispatch) {
  const response = await apiService.fetchApi({
    url: configs.api.init,
    param: {},
    method: 'POST',
  });

  if (!response || !response?.user?.id) {
    localStorage.clearAuth();
    // return dispatch({
    //   type: INIT_FAILURE,
    //   err: response?.err || response.message,
    // });
  }

  const user: UserModel = deriveRawToUser(response.user) || defaultUser;

  dispatch({
    type: SET_CUSER,
    user,
  });
}

const shouldForcePublic = () => {
  const path = window.location.pathname;

  const thisRoute = ROUTES.PUBLIC_LIST.find(route => path.includes(route.path));
  return thisRoute ? true : false;
};

export function loadApp() {
  return async (dispatch: Dispatch, getState: () => AppModel) => {
    dispatch({
      type: LOADING,
      loadingText: 'PROGRESS.INITIALIZING',
    });

    if (!shouldForcePublic()) {
      // Load LOCAL STORAGE
      await loadStorage(dispatch);
    }

    // Load Theme
    const { localStorage } = getState();

    restoreTheme(localStorage.theme);

    if (!shouldForcePublic()) {
      await fetchStore(dispatch);
    }

    dispatch({
      type: INIT_SUCCESS,
    });
  };
}
