import {
  ToasterActionTypes,
  SET_TOASTER_DANGER,
  SET_TOASTER_WARNING,
  SET_TOASTER_SUCCESS,
  RESET_TOASTER_DANGER,
  RESET_TOASTER_SUCCESS,
  RESET_TOASTER_WARNING,
  ToastModel,
} from './types';

export const toastSuccess = (toast: ToastModel): ToasterActionTypes => ({
  type: SET_TOASTER_SUCCESS,
  toast,
});

export const toastWarning = (toast: ToastModel): ToasterActionTypes => ({
  type: SET_TOASTER_WARNING,
  toast,
});

export const toastDanger = (toast: ToastModel): ToasterActionTypes => ({
  type: SET_TOASTER_DANGER,
  toast,
});

export const resetToastSuccess = (): ToasterActionTypes => ({
  type: RESET_TOASTER_SUCCESS,
});

export const resetToastWarning = (): ToasterActionTypes => ({
  type: RESET_TOASTER_WARNING,
});

export const resetToastDanger = (): ToasterActionTypes => ({
  type: RESET_TOASTER_DANGER,
});
