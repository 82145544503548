import styled from 'styled-components';
import { InputLabel } from '@material-ui/core';
import { SPACE } from '$gstyles';
import { mobileStyle } from '$gstyles/utils';

export const Wrapper = styled.div`
  width: 100%;
  .MuiTypography-root {
      font-size: 1.4rem;
    }
    
  ${p =>
    p.size === 'small' &&
    `
    .MuiOutlinedInput-multiline {
      padding-top: 10.5px;
      padding-bottom: 10.5px;
    }
  `}

  ${p =>
    !p.isMultiline
      ? `
    .MuiFormGroup-root {
      flex-direction: row;
    }
    label.MuiFormControlLabel-root {
      height: 18px;
    }
  `
      : `
    .MuiOutlinedInput-multiline {
      padding-top: 10.5px;
      padding-bottom: 10.5px;
    }
    label.MuiFormControlLabel-root {
      height: 36px;
    }
  `}

  ${mobileStyle(`
    .MuiFormGroup-root {
      flex-direction: column;
    }
    label.MuiFormControlLabel-root {
      height: 36px;
    }
  `)}
`;

export const BSLabel = styled(InputLabel)`
  margin-top: ${SPACE.MEDIUM};

  &&.bs-label {
    position: absolute;
    top: -16px;
    left: 0;
    font-size: 13px;
    font-weight: 500;
    transform: none;
  }
`;
