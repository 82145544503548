export const generateColValues = TABLE => {
  return TABLE.map(c => c.value);
};

export const generateColsToFilter = TABLE => {
  return TABLE.filter(c => !c.alwaysShow);
};

export const getActiveColumnTable = (TABLE, colsToDisplay) => {
  return TABLE.filter(c => c.alwaysShow || colsToDisplay.includes(c.value));
};

export const getColsToDisplay = TABLE => {
  return generateColValues(generateColsToFilter(TABLE));
};

export const convertToItemAction = (arr = [] as any, handlePopoverOpen = null as any) => {
  if (!arr) return null;

  const obj = arr.reduce((acc, item) => {
    acc[item.eventName] = item.onClick;
    return acc;
  }, {});

  return {
    ...obj,
    ...(!!handlePopoverOpen ? { handlePopoverOpen } : {}),
  };
};
