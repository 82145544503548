import React from 'react';
import { connect } from 'react-redux';
import { IonAlert, IonContent, IonPage } from '@ionic/react';
import intl, { Text } from '$gintl';

import { authActions } from '$gbusiness/redux/auth';
import { Header } from '$gcomponents/widgets';
import { screen } from '$gcomponents/hoc';
import { LogoHeader } from '$gcomponents/reusables';
import { Box, SPACE, Space } from '$gstyles';
import { ROUTES } from '$gbusiness/enums';

import { Link } from '$gcomponents/primitives';
import ForgotForm from '$gcomponents/widgets/forgotForm';

interface ForgotScreenProps {
  requestPasswordReset: Function;
  screenState: any;
  history: any;
}

const ForgotPasswordScreen: React.FC<ForgotScreenProps> = ({
  requestPasswordReset,
  screenState,
  history,
}) => {
  const onSubmit = values => requestPasswordReset(values.email);

  return (
    <IonPage id="forgot-screen">
      <Header title="SCREEN.FORGOT.TITLE" />
      <IonContent>
        <IonAlert
          isOpen={screenState !== ''}
          header={intl('MESSAGE.SUBMITTED')}
          message={intl('MESSAGE.PASSWORD_RESET')}
          onDidDismiss={() => {
            history.goBack();
          }}
          buttons={['OK']}
        />
        <Box.LARGE>
          <LogoHeader />
          <ForgotForm onSubmit={onSubmit} />
          <Space.Ver margin={SPACE.LARGE}>
            <Link route={ROUTES.LOGIN} routerDirection="back" button>
              <Text k="SCREEN.FORGOT.LOGIN_LINK" />
            </Link>
          </Space.Ver>
        </Box.LARGE>
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = state => ({
  loading: state.loading,
  screenState: state.routing.screenState,
});

const mapDispatchToProps = {
  requestPasswordReset: authActions.requestPasswordReset,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(ForgotPasswordScreen));
