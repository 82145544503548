import { INPUT, INPUT_TYPES } from '$gbusiness/enums';
import InputRowModel from '$gbusiness/models/inputRow';
import { input } from '$ghelpers';
import { validateEmail } from '$ghelpers/input';

export const CUSTOMER_FORM: Array<InputRowModel> = [
  {
    items: [
      {
        label: 'INPUT.LABEL.BILL.CUSTOMER_CODE',
        value: 'customerCode',
        input: INPUT.NUMBERTEXT,
        maxLength: 10,
        gridSize: 6,
        onBlur: async ({ value, callback }) => {
          if (!value || value.length < 4) return;
          callback(value);
        },
        validation: [
          input.requiredValidation,
          {
            condition: v => {
              return v.length < 4;
            },
            message: 'LENGTH_OVER',
          },
        ],
      },
      {
        input: INPUT.TEXT,
        label: 'INPUT.LABEL.BILL.CUSTOMER_NAME',
        value: 'customerName',
        gridSize: 6,
      },
      {
        ...INPUT_TYPES.EMAIL,
        label: 'INPUT.LABEL.BILL.EMAIL',
        value: 'email',
        gridSize: 6,
        validation: [
          input.requiredValidation,
          {
            condition: v => !validateEmail(v),
            message: 'INVALID_EMAIL',
          },
        ],
      },
      {
        ...INPUT_TYPES.ZIP,
        label: 'INPUT.LABEL.BILL.ZIP',
        gridSize: 6,
      },
      {
        label: 'INPUT.LABEL.BILL.ADDRESS',
        value: 'address1',
        disabled: true,
        input: INPUT.TEXT,
        gridSize: 6,
      },
      {
        label: 'INPUT.LABEL.BILL.ADDRESS2',
        value: 'address2',
        disabled: true,
        input: INPUT.TEXT,
        gridSize: 6,
      },
    ],
  },
];
