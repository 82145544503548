import { KEYS } from '../enums/options/recurringInterval';

export default interface RecurringFormModel {
  invoiceType: string;
  email: string;
  customerCode: string;
  address: string;
  address2?: string;
  zip: string;
  amount: number;
  cardId?: string;
  isRecurring: boolean;
  startDate?: Date;
  endDate?: Date;
  scheduledDate?: Date;
  interval?: string;
}

export const defaultRecurringForm = {
  invoiceType: '',
  email: '',
  customerCode: '',
  address: '',
  zip: '',
  amount: 0,
  isRecurring: false,
  interval: KEYS.MONTHLY,
};
