import { format as dateFormat, addYears, addDays } from 'date-fns';

import INTERVAL_OPTIONS from '$business/enums/options/recurringInterval';
import { DATE_FORMATS, INPUT } from '$gbusiness/enums';
import InputRowModel from '$gbusiness/models/inputRow';
import { input } from '$ghelpers';

const tenYearsFromNow = dateFormat(addYears(new Date(), 10), DATE_FORMATS.DATE);
const minDate = dateFormat(addDays(new Date(), 2), DATE_FORMATS.DATE);

export const RECURRING_FORM: Array<InputRowModel> = [
  // {
  //   items: [
  //     {
  //       input: INPUT.CHECKBOX,
  //       label: 'INPUT.LABEL.RECURRING.RECURRING',
  //       value: 'isRecurring',
  //       gridSize: 12,
  //     },
  //   ],
  // },
  {
    isHidden: values => values.isRecurring,
    items: [
      {
        input: INPUT.DATE,
        label: 'INPUT.LABEL.RECURRING.SCHEDULED_DATE',
        value: 'startDate',
        minDate,
        maxDate: tenYearsFromNow,
        gridSize: 6,
        validationCondition: values => !values.isRecurring,
        validation: [input.requiredValidation],
      },
    ],
  },
  {
    isHidden: values => !values.isRecurring,
    items: [
      {
        input: INPUT.DATE,
        label: 'INPUT.LABEL.RECURRING.START_DATE',
        value: 'startDate',
        minDate,
        maxDate: tenYearsFromNow,
        gridSize: 4,
        validationCondition: values => values.isRecurring,
        validation: [input.requiredValidation],
      },
      {
        input: INPUT.DATE,
        label: 'INPUT.LABEL.RECURRING.END_DATE',
        value: 'endDate',
        minDate,
        maxDate: tenYearsFromNow,
        gridSize: 4,
        validationCondition: values => values.isRecurring,
        validation: [input.requiredValidation],
      },
      {
        input: INPUT.SELECT,
        label: 'INPUT.LABEL.RECURRING.INTERVAL',
        value: 'interval',
        options: INTERVAL_OPTIONS,
        gridSize: 4,
      },
    ],
  },
];
