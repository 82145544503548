import React from 'react';

import TextField from '@material-ui/core/TextField';

const InputComponent: any = ({ inputRef, ...other }) => <div {...other} />;

interface OultinedDivProps {
  labelProps: any;
  variant: any;
  children: React.ReactNode;
}

const OutlinedDiv: React.FC<OultinedDivProps> = ({ children, labelProps, variant }) => {
  return (
    <TextField
      {...labelProps}
      variant={variant}
      multiline
      InputProps={{
        inputComponent: InputComponent,
      }}
      inputProps={{ children: children }}
    />
  );
};
export default OutlinedDiv;
