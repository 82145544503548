import React from 'react';
import intl from '$intl';
import TransactionModel from '$business/models/transactions';
import { Div, H1, SPACE } from '$gstyles';
import { formatDatetime } from '$ghelpers/date';
import { Flex } from '$gstyles/wrapper';
import { ReceiptWrapper } from './styles';

interface ReceiptDetailsProps {
  transaction: TransactionModel | null;
}

const ReceiptDetails: React.FC<ReceiptDetailsProps> = ({ transaction }) => {
  if (!transaction) return null;

  const {
    amount,
    invoiceType,
    cardType,
    refundStatus,
    cardHolder,
    last4,
    atuh,
    actioned,
    datetime,
  } = transaction;

  return (
    <ReceiptWrapper>
      <div>{intl('SCREEN.INVOICE.RECEIPT.TITLE')}</div>
      <H1 textAlign="left" padding="0">
        {intl('PRICE', { amount })} {intl('INPUT.POSTFIX.CURRENCY')}
      </H1>
      <div>
        {actioned} {formatDatetime(datetime)}
      </div>
      <Div marginTop={SPACE.LARGE}>
        <Flex justifyContent="space-between">
          <Div>{intl('SCREEN.INVOICE.RECEIPT.CARDHOLDER')}:</Div>
          <Div>{cardHolder}</Div>
        </Flex>
        <Flex justifyContent="space-between">
          <Div>{intl('SCREEN.INVOICE.RECEIPT.CARD_NUMBER')}:</Div>
          <Div>{intl('SCREEN.INVOICE.RECEIPT.LAST4', { last4, cardType })}</Div>
        </Flex>
        <Flex justifyContent="space-between">
          <Div>{intl('SCREEN.INVOICE.RECEIPT.TYPE')}:</Div>
          <Div>{refundStatus}</Div>
        </Flex>
        <Flex justifyContent="space-between">
          <Div>{intl('SCREEN.INVOICE.RECEIPT.INVOICE_TYPE')}:</Div>
          <Div>{intl('INPUT.OPTION.BILLING_TYPE.' + invoiceType)}</Div>
        </Flex>
        <Flex justifyContent="space-between">
          <Div>{intl('SCREEN.INVOICE.RECEIPT.AUTHORIZATION')}:</Div>
          <Div>{atuh}</Div>
        </Flex>
      </Div>
      <Div margin={SPACE.XLARGE} textAlign="center" className="center">
        {intl('SCREEN.INVOICE.RECEIPT.STATUS')}
      </Div>
    </ReceiptWrapper>
  );
};

export default ReceiptDetails;
