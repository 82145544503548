export default interface PaymentRequestModel {
  invoiceType: string;
  email: string;
  customerCode: string;
  address: string;
  address2?: string;
  zip: string;
  amount: number;
  message?: string;
}

export const defaultPaymentRequest = {
  invoiceType: '',
  email: '',
  customerCode: '',
  address: '',
  address2: '',
  zip: '',
  amount: 0,
  message: '',
};
