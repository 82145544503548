import { INPUT, INPUT_TYPES } from '$gbusiness/enums';
import InputRowModel from '$gbusiness/models/inputRow';
import { input } from '$ghelpers';
import EMAIL_STATUSES from '$business/enums/options/emailStatus';
import YESNO_OPTIONS from '$gbusiness/enums/options/yestno';
import BILL_TYPES from '$business/enums/options/billType';

export const BILL_FORM: Array<InputRowModel> = [
  {
    items: [
      {
        input: INPUT.TEXT,
        label: 'INPUT.LABEL.ACTION_BILL.DATE',
        value: 'createdAt',
        disabled: true,
        gridSize: 6,
      },
      {
        input: INPUT.NUMBERTEXT,
        label: 'INPUT.LABEL.ACTION_BILL.SEQ',
        value: 'seqNumber',
        disabled: true,
        gridSize: 6,
      },
      {
        input: INPUT.TEXT,
        label: 'INPUT.LABEL.ACTION_BILL.SENT_DATE',
        value: 'sentAt',
        disabled: true,
        gridSize: 6,
      },

      {
        input: INPUT.SELECT,
        label: 'INPUT.LABEL.ACTION_BILL.STATUS',
        value: 'emailStatus',
        options: EMAIL_STATUSES,
        gridSize: 6,
      },
      {
        label: 'INPUT.LABEL.ACTION_BILL.PAST_DUE',
        value: 'pastDue',
        input: INPUT.SELECT,
        options: YESNO_OPTIONS,
        gridSize: 6,
        validation: [input.requiredValidation],
      },
      {
        label: 'INPUT.LABEL.BILL.BILL_TYPE',
        value: 'invoiceType',
        input: INPUT.SELECT,
        options: BILL_TYPES,
        gridSize: 6,
        validation: [
          input.requiredValidation,
          {
            condition: v => v > 0,
            message: 'OVER_ZERO',
          },
        ],
      },
      {
        ...INPUT_TYPES.EMAIL,
        label: 'INPUT.LABEL.ACTION_BILL.CUSTOMER_EMAIL',
        validation: [input.requiredValidation],
      },
      {
        ...INPUT_TYPES.EMAIL,
        label: 'INPUT.LABEL.ACTION_BILL.USER_EMAIL',
        value: 'salesEmail',
      },
      {
        label: 'INPUT.LABEL.ACTION_BILL.NOTE',
        value: 'note',
        gridSize: 12,
        input: INPUT.TEXT,
        placeholder: 'INPUT.PLACEHOLDER.NOTE',
      },
    ],
  },
];
