import { IonContent, IonList, IonMenuToggle, IonHeader, IonToolbar, IonImg, IonFooter } from '@ionic/react';
import { menuController } from '@ionic/core';
import React from 'react';
import { RouteComponentProps, withRouter, useHistory } from 'react-router-dom';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import { configs } from '$configs';
import { ROUTES } from '$business/enums';
import intl, { Text } from '$intl';

import { Wrapper } from './styles';
import { AppVersion } from '$gcomponents/reusables';
import PopOver from '$gcomponents/reusables/popOver';
import { Accordion, AccordionDetails, AccordionSummary, Button } from '@material-ui/core';
import { Div, SPACE, Space } from '$gstyles';
import { Link } from '$gcomponents/primitives';

interface MenuProps extends RouteComponentProps {
  isLoggedIn: boolean;
  currentRoute: string;
  onLogout: Function;
}

const Menu: React.FC<MenuProps> = React.memo(
  ({ currentRoute, isLoggedIn, onLogout, ...rest }) => {
    const history = useHistory();

    const navigate = route => {
      if (currentRoute === route) return;

      history.push(route);
      menuController.toggle();
    };

    const MakePaymentButton = (
      <PopOver
        className="pop-button-container"
        component={
          <Button variant="outlined" fullWidth>
            + New
          </Button>
        }>
        <Space.All padding={SPACE.MEDIUM} className="payment-options">
          <div>
            <Button className="left" fullWidth onClick={() => navigate(ROUTES.MAKE_PAYMENT)}>
              {intl('MENU.MAKE_PAYMENT')}
            </Button>
          </div>
          {/* <div>
            <Button className="left" fullWidth onClick={() => navigate(ROUTES.MAKE_RECURRING)}>
              {intl('MENU.SCHEDULE_PAYMENT')}
            </Button>
          </div> */}
          <div>
            <Button className="left" fullWidth onClick={() => navigate(ROUTES.MAKE_LINK)}>
              {intl('MENU.SEND_LINK')}
            </Button>
          </div>
        </Space.All>
      </PopOver>
    );
    return (
      <Wrapper
        contentId="main"
        menuId="start"
        disabled={!isLoggedIn}
        isProd={process.env.REACT_APP_ENV === 'prod'}
        {...rest}>
        {configs.display.layout !== 'FIXED_HEADER' && (
          <IonHeader>
            <IonToolbar>
              <IonImg class="logo" src={configs.display.logoPath} />
            </IonToolbar>
          </IonHeader>
        )}
        <IonContent class="outer-content">
          {MakePaymentButton}
          <IonList>
            {/* {ROUTES.MENU.map((appPage, index) => {
              return (
                <IonMenuToggle key={index} autoHide={false}>
                  <IonItem
                    className={currentRoute === appPage.path ? 'selected pointer' : 'pointer'}
                    routerLink={`${appPage.path}${appPage.defaultId || ''}`}
                    routerDirection="none"
                    lines="none"
                    detail={false}>
                    <IonLabel>
                      <Text k={appPage?.title} />
                    </IonLabel>
                  </IonItem>
                </IonMenuToggle>
              );
            })} */}
            {/* <IonItem lines="none" class="pointer" onClick={() => onLogout()} detail={false}>
              <IonLabel>
                <Text k="MENU.LOG_OUT" />
              </IonLabel>
            </IonItem> */}
          </IonList>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ArrowDropUpIcon />}>
              <div>{intl('MENU.BILL')}</div>
            </AccordionSummary>
            <AccordionDetails>
              <IonList>
                {ROUTES.MENU_BILL.map((appPage, index) => {
                  return (
                    <IonMenuToggle key={index} autoHide={false} className="menu-item bill">
                      <Link
                        button
                        className={currentRoute === appPage.path ? 'selected pointer' : 'pointer'}
                        route={`${appPage.path}`}>
                        <Text k={appPage?.title} />
                      </Link>
                    </IonMenuToggle>
                  );
                })}
              </IonList>
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ArrowDropUpIcon />}>
              <div>{intl('MENU.PAYMENT_GATEWAY')}</div>
            </AccordionSummary>
            <AccordionDetails>
              <IonList>
                {ROUTES.MENU_GATEWAY.map((appPage, index) => {
                  return (
                    <IonMenuToggle key={index} autoHide={false} className="menu-item gateway">
                      <Link
                        button
                        className={currentRoute === appPage.path ? 'selected pointer' : 'pointer'}
                        route={`${appPage.path}`}>
                        <Text k={appPage?.title} />
                      </Link>
                    </IonMenuToggle>
                  );
                })}
              </IonList>
            </AccordionDetails>
          </Accordion>
        </IonContent>
        <IonFooter>
          <Div height="32px">
            <AppVersion oneLine />
          </Div>
        </IonFooter>
      </Wrapper>
    );
  },
  (pp, np) => pp.currentRoute === np.currentRoute && pp.isLoggedIn === np.isLoggedIn,
);

export default withRouter(Menu);
