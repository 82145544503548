import styled from 'styled-components';
import { IonPage } from '@ionic/react';
import { Col, Div, Row, SPACE } from '$gstyles';

export const IonPageWrapper = styled(IonPage)`
  .submit-button {
    min-width: 200px;
    width: 40%;
    margin-left: ${SPACE.XLARGE};
    margin-right: ${SPACE.XLARGE};
  }
  .form-group {
    margin: 0 ${SPACE.SMALL};
  }
  .col {
    padding: 8px 16px !important;
  }
`;

export const CardListWrapper = styled(Div)`
  .cards-box {
    margin: 20px;
    margin-bottom: ${SPACE.XLARGE};
    padding: ${SPACE.LARGE};
    border: 1px solid var(--ion-border-color);
    border-radius: 4px;
    overflow-x: auto;
  }

  .MuiFormGroup-root {
    display: block;
    min-width: 500px;
  }

  .MuiRadio-root {
    padding: 0;
    padding-left: ${SPACE.MEDIUM};
  }

  .card-icon {
    max-height: 18px;
    max-width: 28px;
  }

  .last4:first-letter {
    text-transform: uppercase;
    margin-left: ${SPACE.MEDIUM};
  }
`;

export const CardRow = styled(Row)`
  align-items: center;
  margin: ${SPACE.MEDIUM};
`;

export const CardCol = styled(Col)``;
