import styled from 'styled-components';

import { Col, ItemWrapper, SPACE, WEIGHT } from '$gstyles';

export const Wrapper = styled(ItemWrapper)`
  border: 1px solid var(--ion-border-color);
  border-radius: 4px;
  margin: ${SPACE.LARGE} 0;
  .inner {
    padding: ${SPACE.MEDIUM};
  }

  .col {
    .label {
      opacity: 0.8;
    }
    .value {
      margin: 12px;
      min-height: 20px;
      color: #000;
      font-weight: ${WEIGHT.SEMI_BOLD};
    }
  }
`;

export const Cell = styled(Col)``;
