import React, { useState, useRef } from 'react';
import { IonContent, IonPage, IonPopover } from '@ionic/react';

import Header from '$components/header';
import { ApiTable, Modal } from '$gcomponents/reusables';
import ListFilterWrapper from '$components/listFilterWrapper';
import { datetimeToStringUtc } from '$ghelpers/date';
import { DATE_FORMATS } from '$gbusiness/enums';

import ListTabs from './components/tabs';
import LeftComponent, { filterProps, defaultFilter } from './components/leftComponents';
import PopMenu from './components/popMenu';
import InvoiceDetailsScreen from '../invoices/invoiceDetailsScreen';
import { DATE_RANGE_TYPE, KEYS } from '$business/enums/options/dateRange';
import RightComponent from './components/rightComponents';
import ExportModel from '$business/models/exportSettings';

export interface filterModel extends filterProps {
  key?: string;
}

export interface tabModel {
  label: string;
  value: string;
}
interface ScreenSectionProps {
  TABLE: any;
  TABS: Array<tabModel>;
  title: string;
  useDatetime?: boolean;
  exportSettings?: ExportModel;
  itemActions?: any;
  onTableLoad?: Function;
  filter?: any;
  defaultRange?: DATE_RANGE_TYPE;
}

const ScreenSection: React.FC<ScreenSectionProps> = ({
  TABLE,
  TABS,
  itemActions,
  title,
  useDatetime = false,
  exportSettings,
  filter: parentFilter = {},
  onTableLoad,
  defaultRange = KEYS.CUSTOM,
}) => {
  const contentRef = useRef<HTMLIonContentElement | null>(null);
  const [dataLength, setDataLength] = useState(undefined);
  const [invoice, setInvoice] = useState<any>(null);
  const [editMode, setEditMode] = useState(true);
  const [filter, setFilter] = useState({ ...defaultFilter, key: '', ...parentFilter });
  const [showActions, setShowActions] = useState<{ open: boolean; event: Event | undefined }>({
    open: false,
    event: undefined,
  });

  const onRefresh = () => {
    setFilter({
      ...filter,
      forceRefresh: !filter.forceRefresh,
    });
  };

  const handleTableLoad = len => {
    setDataLength(len);
    if (onTableLoad) onTableLoad(len);
  };
  const onTabChange = index => {
    setFilter({
      ...filter,
      key: TABS[index].value,
    });
  };
  const updateFilter = childFilter => {
    setFilter({
      ...filter,
      ...childFilter,
      key: filter.key,
      forceRefresh: !filter.forceRefresh,
    });
  };
  const normalizeFilter = () => {
    return {
      ...(parentFilter && { ...parentFilter }),
      ...filter,
      dateStart: filter.dateStart ? datetimeToStringUtc(filter.dateStart, DATE_FORMATS.MYSQL) : '',
      dateEnd: filter.dateEnd ? datetimeToStringUtc(filter.dateEnd, DATE_FORMATS.MYSQL) : '',
    };
  };

  const {
    endpoint,
    isPublic,
    cellPadding,
    pageSize,
    minWidth,
    maxWidth,
    isStripped,
    virtualized,
    shouldVirtualize,
    deriveToModel,
    defaultSortKey,
    defaultSortOrder,
    color,
    fontSize,
    method,
    mockData,
  } = TABLE.TABLE_CONFIG;
  const { headerHeight, rowHeight, checkWidth } = virtualized;
  const TAB_HEIGHT = 40;
  const showTableScreen = (dataLength || 0) > 0;

  const leftContainer = (
    <LeftComponent
      parentFilter={filter}
      defaultRange={defaultRange}
      onSearch={updateFilter}
      useDatetime={useDatetime}
    />
  );
  const rightContainer = <RightComponent filter={filter} exportSettings={exportSettings} />;
  const activeTab = TABS.findIndex(t => t.value === filter.key);
  const tabComponent = (
    <ListTabs height={TAB_HEIGHT} tabs={TABS} activeTab={activeTab} onTabChange={onTabChange} />
  );

  return (
    <IonPage>
      <Header titleText={title} disableBack />
      <IonPopover
        isOpen={showActions.open}
        event={showActions.event}
        onDidDismiss={e => {
          setShowActions({ open: false, event: undefined });
        }}>
        <PopMenu
          onClick={action => {
            setShowActions({ open: false, event: undefined });
            if (action === 'ADD') alert('add');
          }}
        />
      </IonPopover>
      <IonContent ref={contentRef}>
        <ListFilterWrapper
          shouldVirtualize={shouldVirtualize}
          isHidden={!showTableScreen}
          tabComponent={tabComponent}
          leftComponent={leftContainer}
          rightComponent={rightContainer}>
          <>
            <ApiTable
              dataSource={{
                endpoint,
                method,
                isPublic,
                mockData,
                pageSize,
                shouldVirtualize,
                deriveToModel,
                defaultSortKey,
                defaultSortOrder,
              }}
              styles={{
                color,
                cellPadding,
                minWidth,
                maxWidth,
                headerHeight,
                rowHeight,
                checkWidth,
                isStripped,
                fontSize,
              }}
              itemActions={{
                ...itemActions,
                onRefresh,
                viewDetails: row => {
                  if (row.invoiceId) {
                    setInvoice(row);
                    setEditMode(false);
                  }
                },
                updateStatus: row => {
                  if (row.invoiceId) {
                    setInvoice(row);
                    setEditMode(true);
                  }
                },
              }}
              TABLE={TABLE.TABLE}
              filters={normalizeFilter()}
              onTableLoad={handleTableLoad}
            />
          </>
        </ListFilterWrapper>
        <Modal useCustom show={!!invoice} onClose={() => setInvoice(null)}>
          <InvoiceDetailsScreen
            onClose={() => setInvoice(null)}
            invoice={invoice}
            editMode={editMode}
            onSave={onRefresh}
          />
        </Modal>
      </IonContent>
    </IonPage>
  );
};

export default ScreenSection;
