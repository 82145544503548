import React from 'react';

import { configs } from '$configs';
import { SORT, ROUTES, COLORS } from '$gbusiness/enums';
import CellModel from '$gbusiness/models/cell';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { formatDatetime } from '$gbusiness/helpers/date';
import intl from '$intl';
import { deriveRawToTransactions } from '$business/models/transactions';
import { KEYS } from '$business/enums/options/billType';
import { Button, Chip } from '@material-ui/core';
import PopOver from '$gcomponents/reusables/popOver';
import ExportModel from '$business/models/exportSettings';

export const AUTO_COMPLETE = {
  endpoint: configs.api.users.search,
  renderSuggestion: e => `${e.firstName} ${e.lastName} ${e.email}`,
  selectedValue: e => e.firstName,
  onSelect: (e, history) => {
    history.push(`${ROUTES.USERS}/${e.userId}`);
  },
};

export const TABLE_CONFIG = {
  endpoint: configs.api.transaction.list,
  deriveToModel: deriveRawToTransactions,
  color: COLORS.SECONDARY,
  pageSize: 50,
  cellPadding: `3px`,
  isStripped: true,
  minWidth: 960,
  maxWidth: 1800,
  fontSize: '0.9em',
  defaultSortKey: 'createdAt',
  defaultSortOrder: SORT.DSC,
  shouldVirtualize: false,
  virtualized: {
    rowHeight: 36,
    headerHeight: 60,
    checkWidth: 60,
    threshold: 10,
    additionalHeight: 40,
  },
  filter: {
    color: COLORS.PRIMARY,
  },
};


export const exportSettings: ExportModel = {
  deriveTable: row => {
    return {
      Time: formatDatetime(row.createdAt),
      Card: row.cardType,
      CardHolder: row.cardHolder,
      Expiry: row.expiry,
      CardNumber: row.last4 ? `xxxx ${row.last4}` : '',
      Transaction: row.refundStatus,
      CustomerCode: row.customerCode,
      BillType: intl('INPUT.OPTION.BILLING_TYPE.' + row.invoiceType) || 'Unknown',
      Amount: intl('PRICE', { amount: row.amount }),
      Status: row.paymentResult,
      User: row.userName,
    };
  },
  deriveToModel: TABLE_CONFIG.deriveToModel,
  fileName: 'transaction',
  url: configs.api.transaction.list,
  sortKey: TABLE_CONFIG.defaultSortKey,
  sortOrder: TABLE_CONFIG.defaultSortOrder
}

export const deriveToExport = (row: any) => {
  return {
    Time: formatDatetime(row.createdAt),
    Card: (row.cardType || '').toString().replace('CARD', ''),
    CardHolder: row.cardHolder,
    Expiry: row.expiry,
    CardNumber: row.last4 ? `xxxx ${row.last4}` : '',
    Transaction: row.refundStatus,
    CustomerCode: row.customerCode,
    BillType: intl('INPUT.OPTION.BILLING_TYPE.' + row.invoiceType) || 'Unknown',
    Amount: intl('PRICE', { amount: row.amount }),
    Status: row.paymentResult,
    User: row.userName,
  };
};

export const TABLE: Array<CellModel> = [
  {
    label: 'SCREEN.TRANSACTIONS.COLS.TIME',
    value: 'createdAt',
    component: row => <>{row.createdAt ? formatDatetime(row.createdAt) : ''}</>,
    sortable: SORT.ASC,
    width: 260,
  },
  // {
  //   label: 'SCREEN.INVOICE.COLS.SEQ',
  //   value: 'invoiceId',
  //   sortKey: 'invoiceId',
  //   sortable: SORT.ASC,
  //   width: 110,
  // },
  {
    label: 'SCREEN.TRANSACTIONS.COLS.CARD_TYPE',
    value: 'cardType',
    component: row => <>{row.cardType.replace('CARD', '')}</>,
    className: 'uppercase',
    width: 160,
  },
  {
    label: 'SCREEN.TRANSACTIONS.COLS.CARDHOLDER',
    value: 'cardHolder',
    width: 240,
  },
  {
    label: 'SCREEN.TRANSACTIONS.COLS.EXPIRY',
    value: 'expiry',
    width: 100,
  },
  {
    label: 'SCREEN.TRANSACTIONS.COLS.CARD_NUMBER',
    value: 'last4',
    component: row => {
      return row.last4 ? <>xxxx {row.last4}</> : null;
    },
    width: 180,
  },
  {
    label: 'SCREEN.TRANSACTIONS.COLS.REFUND_STATUS',
    value: 'refundStatus',
    component: row => {
      const color = row.refundStatus === 'Refund' || row.refundStatus === 'Void' ? 'danger' : 'default';
      return <div className={`${color} outlined`}>{row.refundStatus}</div>;
    },
    width: 150,
  },
  {
    label: 'SCREEN.TRANSACTIONS.COLS.CUSTOMER_CODE',
    value: 'customerCode',
    sortable: SORT.ASC,
    width: 160,
  },
  {
    label: 'SCREEN.TRANSACTIONS.COLS.INVOICE_TYPE',
    value: 'invoiceType',
    component: row => {
      const color =
        row.invoiceType === KEYS.CONTRACT
          ? 'primary'
          : row.invoiceType === KEYS.PPI
            ? 'tertiary'
            : row.invoiceType === KEYS.SALES
              ? 'SALES'
              : 'default';
      return (
        <Chip
          variant="outlined"
          className={`${color} outlined`}
          size="small"
          label={intl('INPUT.OPTION.BILLING_TYPE.' + row.invoiceType) || 'Unknown'}
        />
      );
    },
    sortable: SORT.ASC,
    sortKey: 'invoiceType',
    width: 170,
  },
  {
    label: 'SCREEN.TRANSACTIONS.COLS.AMOUNT',
    value: 'amount',
    sortable: SORT.DSC,
    component: row => <>{intl('PRICE', { amount: row.amount })}</>,
    width: 150,
  },
  {
    label: 'SCREEN.TRANSACTIONS.COLS.PAYMENT_STATUS',
    value: 'paymentResult',
    component: row => {
      const color = row.paymentResult === 'declined' ? 'danger' : 'success';
      return (
        <div className={`${color} outlined`} style={{ textTransform: 'capitalize' }}>
          {row.paymentResult}
        </div>
      );
    },
    width: 150,
  },
  {
    label: 'SCREEN.TRANSACTIONS.COLS.USER',
    value: 'userName',
    sortable: SORT.ASC,
    sortKey: 'userLast',
    width: 180,
  },
  {
    label: 'SCREEN.TRANSACTIONS.COLS.ACTION',
    value: '',
    align: 'center',
    className: 'action',
    component: (row, actions) => {
      if (row.paymentResult === 'declined') return null;
      return (
        <PopOver component={<MoreHorizIcon className="pointer" />}>
          <>
            {row.refundStatus === 'Purchase' && (
              <>
                <Button
                  className="left"
                  fullWidth
                  onClick={() => actions.voidTransaction(row, actions.onRefresh)}>
                  {intl('SCREEN.INVOICE.ACTIONS.VOID')}
                </Button>
                <Button
                  className="left"
                  fullWidth
                  onClick={() => actions.refundTransaction(row, actions.onRefresh)}>
                  {intl('SCREEN.INVOICE.ACTIONS.REFUND')}
                </Button>
              </>
            )}
            <Button className="left" fullWidth onClick={() => actions.viewReceipt(row)}>
              {intl('SCREEN.INVOICE.ACTIONS.VIEW_RECEIPT')}
            </Button>
          </>
        </PopOver>
      );
    },
    width: 100,
  },
];
