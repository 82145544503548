import { clog, util } from '$ghelpers';
import { configs, features } from '$configs';
import ReduxStore from '$business/redux';

import { toast } from '$gcomponents/reusables';
import { COLORS } from '$gbusiness/enums';

import { getStorageItem, clearAuth } from './local.storage';
import intl from '$gintl';
import { LOADING } from '../redux/loading/types';

export const fetchApi: any = async ({
  url,
  param = {},
  mockData = undefined,
  headers = {},
  method = 'POST',
  isPublic = false,
}) => {
  let response;
  if (!url && !mockData) {
    response = null;
    return response;
  }

  clog('REQUEST', param);
  if (features.useMockData && mockData) {
    await util.sleep(configs.mockFetchDuration);
    response = mockData;
  } else {
    response = await apiCall({ url, param, method, headers, isPublic });
  }
  clog('RESPONSE', response);

  return response;
};

export const checkIfTokenExpired = async () => {
  const expTimestp = await getStorageItem('expTimestp');
  if (!expTimestp || expTimestp === '0') return false;

  const now = +new Date();

  clog('NOW', now);
  clog('EXP', parseInt(expTimestp.toString()));
  return now > parseInt(expTimestp.toString());
};

export const apiCall = async ({ url, param = {}, headers = {}, method = 'POST', isPublic }) => {
  const store = ReduxStore.getState();
  const globalHeaders = store.init.headers;
  const { accessToken: localToken, userId } = store.localStorage;
  const accessToken = localToken || process.env.REACT_APP_PUBLIC_TOKEN;

  // Check if the token has expired
  if (!isPublic && accessToken && (await checkIfTokenExpired())) {
    // Token is Expired... Not what?

    // TODO: For now let's logout
    await clearAuth();
    window.location.reload();
    return;
  }

  const mergedHeaders = {
    ...headers,
    ...globalHeaders,
  };
  const options = {
    method,
    referrer: '',
    headers: {
      ...mergedHeaders,
      ...(!isPublic && { Authorization: `Bearer ${accessToken}` }),
      ...(userId && { userId: userId?.toString() }),
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: method === 'GET' ? undefined : JSON.stringify(param),
  };

  let fullUrl = url.includes('://') ? url : `${process.env.REACT_APP_API_BASE}/${url}`;
  fullUrl = fullUrl.replace(/\/\s*$/, '');
  const response = await fetch(fullUrl, options).catch(handleErr);
  const success = response.status && response.status >= 200 && response.status < 300;
  const result = await response.json().catch(err => handleErrJson(err, success));
  return Promise.resolve(result);
};

const handleErr = err => {
  console.warn(err);
  const resp = new Response(
    JSON.stringify({
      err: 'ERROR.NETWORK',
      message: err.message,
    }),
  );
  return resp;
};

const handleErrJson = (err, success) => {
  console.warn(err);
  return success
    ? new Response(
        JSON.stringify({
          success: true,
        }),
      ).json()
    : new Response(
        JSON.stringify({
          err: 'ERROR.NETWORK',
          message: err.message,
        }),
      ).json();
};

export const handleErrorMessage = response => {
  if (!response) return [];
  let arr: Array<string> = [];

  const { errors, message } = response;
  if (errors) {
    for (const i in errors) {
      if (typeof errors[i] === 'string') arr.push(errors[i]);
      else
        for (const j in errors[i]) {
          arr.push(errors[i][j]);
        }
    }
  } else if (message) {
    arr.push(message);
  }
  return arr;
};

export function handleApiFail(dispatch, type, response, key, shouldToast = false) {
  let errors = handleErrorMessage(response);
  if (type) dispatch({ type, err: key });

  if (!errors.length) errors = [intl(key)];

  if (shouldToast && errors.length) {
    for (const text of errors) {
      toast({ text, message: intl(key), color: COLORS.DANGER });
    }
  }
}

export function handleApiSuccess(dispatch, type, message = '', cssClass = 'medium') {
  if (type) dispatch({ type });
  if (message) {
    toast({ message, color: COLORS.SUCCESS, cssClass });
  }
}

export function dispatchLoading(dispatch, key = '') {
  dispatch({
    type: LOADING,
    ...(key && { loadingText: key }),
  });
}
