import SubscriptionModel from '$business/models/subscription';
import TransactionModel from '../../models/transactions';

export const SUBSCRIPTION_FAILURE = 'SUBSCRIPTION_FAILURE';
export const RESET_SUBSCRIPTION = 'RESET_SUBSCRIPTION';
export const GET_SUBSCRIPTION_SUCCESS = 'GET_SUBSCRIPTION_SUCCESS';

export interface typeOnlyType {
  type: typeof RESET_SUBSCRIPTION;
}

export interface failureType {
  type: typeof SUBSCRIPTION_FAILURE | typeof SUBSCRIPTION_FAILURE;
  err: string;
}

export interface GetSubscriptionSuccessType {
  type: typeof GET_SUBSCRIPTION_SUCCESS;
  subscription: SubscriptionModel;
  transactions: Array<TransactionModel>;
}

export interface SubscriptionReducerType {
  subscription?: SubscriptionModel;
  transactions?: Array<TransactionModel>;
}

export type SubscriptionActionTypes = failureType | typeOnlyType | GetSubscriptionSuccessType;

export const SUBSCRIPTION_INIT_STATE: SubscriptionReducerType = {};
