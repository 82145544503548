import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { IonContent, IonPage } from '@ionic/react';
import intl from '$gintl';

import { authActions } from '$gbusiness/redux/auth';
import { Header } from '$gcomponents/widgets';
import { screen } from '$gcomponents/hoc';
import { Div, FONT, SPACE } from '$gstyles';
import { sleep } from '$ghelpers/util';

interface VerifyEmailScreenProps {
  verifyEmail: Function;
  screenState: string;
  match: any;
  history: any;
}

const VerifyEmailScreen: React.FC<VerifyEmailScreenProps> = ({ verifyEmail, screenState, match }) => {
  const {
    params: { token, email },
  } = match;

  useEffect(() => {
    verifyEmailNow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const verifyEmailNow = async () => {
    if (!email || !token) return;
    await sleep(200);
    await verifyEmail(token, email);
  };

  const isLoaded = screenState !== '' && screenState !== undefined;

  return (
    <IonPage id="forgot-screen">
      <Header title="SCREEN.VERIFY_EMAIL.TITLE" />
      <IonContent>
        <Div margin={SPACE.XXLARGE} fontSize={FONT.LARGE} className="center">
          {isLoaded && (
            <>
              <Div fontSize={FONT.LARGE}>{intl('MESSAGE.EMAIL_VERIFIED', { email })}</Div>
              {screenState === 'EMAIL_VERIFY_0' && (
                <Div margin={SPACE.XXLARGE}>
                  <a href={process.env.PUBLIC_URL}>{intl('BUTTON.LOGIN_APP')}</a>
                </Div>
              )}
            </>
          )}
        </Div>
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = state => ({
  loading: state.loading,
  screenState: state.routing.screenState,
});

const mapDispatchToProps = {
  verifyEmail: authActions.verifyEmail,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(VerifyEmailScreen));
