import React from 'react';
import intl from '$intl';
import CardModel from '$business/models/cardModel';
import { Inline } from '$gstyles';
import { getCardPathFromType } from '$ghelpers/util';

interface CardItemProps {
  card: CardModel;
}

const CardItem: React.FC<CardItemProps> = ({ card }) => {
  const { last4, cardType } = card;
  return (
    <>
      <img className="card-icon" src={getCardPathFromType(card.cardType)} alt={card.cardType} />
      <Inline className="last4">{intl('SCREEN.MAKE_PAYMENT.LAST4', { cardType, last4 })}</Inline>
    </>
  );
};

export default CardItem;
