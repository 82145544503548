import React, { useState } from 'react';
import { connect } from 'react-redux';
import { IonContent } from '@ionic/react';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';

import { screen } from '$gcomponents/hoc';
import Header from '$components/header';
import { ApiTable, dialog, Modal } from '$gcomponents/reusables';
import ListFilterWrapper from '$components/listFilterWrapper';

import EmptySection from './components/empty';
import LeftComponent, { defaultFilter } from './components/leftComponents';
import { TABLE, TABLE_CONFIG } from './table';
import { IonPageWrapper } from './styles';
import { datetimeToString } from '$ghelpers/date';
import { DATE_FORMATS } from '$gbusiness/enums';
import RightComponent from './components/rightComponents';
import { invoiceActions } from '$business/redux/invoice';
import MuiModal from '$gcomponents/reusables/modal/material';
import { Button } from '@material-ui/core';
import intl from '$intl';
import { Flex } from '$gstyles/wrapper';
import { Inline, SPACE } from '$gstyles';
import InvoiceDetailsScreen from '../invoiceDetailsScreen';
import { ROUTES } from '$business/enums';

interface SendBillsScreenProps {
  userId: number;
  currentRoute: string;
  isFinished: boolean;
  numSuccess: number;
  numFailure: number;
  deleteInvoices: Function;
  sendInvoices: Function;
  sendInvoice: Function;
  clearInvoiceStatus: Function;
}

const SendBillsScreen: React.FC<SendBillsScreenProps> = ({
  userId,
  currentRoute,
  isFinished,
  numSuccess,
  numFailure,
  deleteInvoices,
  sendInvoices,
  sendInvoice,
  clearInvoiceStatus,
}) => {
  const [filter, setFilter] = useState({ ...defaultFilter, key: '' });
  const [selectedList, setSelectedList] = useState([]);
  const [dataLength, setDataLength] = useState(undefined);
  const [invoice, setInvoice] = useState<any>(null);
  const [editMode, setEditMode] = useState(true);
  const [resetSelection, setResetSelection] = useState(false);

  const onTableLoad = len => {
    setDataLength(len);
  };

  const {
    endpoint,
    isPublic,
    cellPadding,
    fontSize,
    pageSize,
    minWidth,
    isStripped,
    virtualized,
    shouldVirtualize,
    deriveToModel,
    color,
    defaultSortKey,
    defaultSortOrder,
  } = TABLE_CONFIG;

  const { headerHeight, rowHeight, checkWidth, additionalHeight } = virtualized;
  const TAB_HEIGHT = 40;

  const showEmptyScreen = dataLength === 0;
  // const showTableScreen = (dataLength || 0) > 0;
  const updateFilter = childFilter => {
    setFilter({
      ...filter,
      ...childFilter,
      forceRefresh: !filter.forceRefresh,
    });
  };

  const normalizeFilter = () => {
    return {
      ...filter,
      key: 'unsent',
      view: 'bill',
      dateStart: filter.dateStart ? datetimeToString(filter.dateStart, DATE_FORMATS.MYSQL) : '',
      dateEnd: filter.dateEnd ? datetimeToString(filter.dateEnd, DATE_FORMATS.MYSQL) : '',
    };
  };
  const sendBill = (list, email = '') => {
    dialog.confirm({
      message: email ? 'MESSAGE.SEND_BILL_WARNING_ONE' : 'MESSAGE.SEND_BILL_WARNING',
      key: { count: list.length, email },
      cssClass: 'large',
      onPress: () => {
        if (email) sendInvoice(list, email, true);
        else sendInvoices(list);
      },
    });
  };
  const sendSelected = () => {
    sendBill(selectedList);
  };
  const deleteSelected = () => {
    dialog.confirm({
      message: 'MESSAGE.DELETE_WARNING',
      cssClass: 'large',
      onPress: async () => {
        await deleteInvoices(selectedList);
        updateFilter({});
        onResetSelection();
      },
    });
  };
  const onResetSelection = async () => {
    setResetSelection(!resetSelection);
  };
  const handleModalClose = () => {
    onResetSelection();
    clearInvoiceStatus();
    updateFilter({});
  };
  const leftContainer = (
    <LeftComponent
      filter={filter}
      setFilter={setFilter}
      dataLength={dataLength || 0}
      onSearch={updateFilter}
    />
  );
  const rightContainer = (
    <RightComponent
      onClickDelete={deleteSelected}
      onClickSend={sendSelected}
      isSelected={selectedList.length > 0}
    />
  );

  return (
    <IonPageWrapper>
      <Header title="SCREEN.BILLS.TITLE" disableBack />
      <IonContent>
        <ListFilterWrapper
          shouldVirtualize={shouldVirtualize}
          leftComponent={leftContainer}
          rightComponent={rightContainer}
          additionalHeight={TAB_HEIGHT}>
          <>
            {showEmptyScreen && (
              <EmptySection
                onRefresh={updateFilter}
                userId={userId}
                dateStart={filter.dateStart || new Date()}
                dateEnd={filter.dateEnd || new Date()}
              />
            )}
            <ApiTable
              dataSource={{
                endpoint,
                isPublic,
                pageSize,
                shouldVirtualize,
                deriveToModel,
                defaultSortKey,
                defaultSortOrder,
              }}
              styles={{
                color,
                fontSize,
                cellPadding,
                minWidth,
                headerHeight,
                rowHeight,
                checkWidth,
                isStripped,
                additionalHeight,
              }}
              itemActions={{
                sendBill: row => {
                  sendBill([row.invoiceId], row.email);
                },
                viewDetails: row => {
                  if (row.invoiceId) {
                    setInvoice(row);
                    setEditMode(false);
                  }
                },
                updateStatus: row => {
                  if (row.invoiceId) {
                    setInvoice(row);
                    setEditMode(true);
                  }
                },
              }}
              TABLE={TABLE}
              resetSelection={resetSelection}
              filters={normalizeFilter()}
              onTableLoad={onTableLoad}
              onSelection={rows => {
                setSelectedList(rows.filter(r => r?.invoiceId).map(r => r?.invoiceId));
              }}
            />
          </>
        </ListFilterWrapper>
        <MuiModal
          open={isFinished && currentRoute === ROUTES.SEND_BILL}
          minWidth="320px"
          onClose={handleModalClose}
          title={intl('SCREEN.BILLS.DIALOG.TITLE')}
          footer={
            <>
              <Button fullWidth onClick={handleModalClose} color="secondary" variant="outlined">
                {intl('BUTTON.CANCEL')}
              </Button>
              <Button fullWidth onClick={handleModalClose} color="secondary" variant="contained">
                {intl('BUTTON.CONFIRM')}
              </Button>
            </>
          }>
          <Flex className="dialog-message warning outlined">
            <ReportProblemOutlinedIcon />
            <Inline marginLeft={SPACE.MEDIUM}>
              {intl('SCREEN.BILLS.DIALOG.FAILURE', { count: numFailure || 0 })}
            </Inline>
          </Flex>
          <Flex className="dialog-message success outlined">
            <CheckCircleOutlineOutlinedIcon />
            <Inline marginLeft={SPACE.MEDIUM}>
              {intl('SCREEN.BILLS.DIALOG.SUCCESS', { count: numSuccess || 0 })}
            </Inline>
          </Flex>
        </MuiModal>
        <Modal useCustom show={!!invoice} onClose={() => setInvoice(null)}>
          <InvoiceDetailsScreen
            onClose={() => setInvoice(null)}
            invoice={invoice}
            editMode={editMode}
            onSave={handleModalClose}
          />
        </Modal>
      </IonContent>
    </IonPageWrapper>
  );
};

const mapStateToProps = state => ({
  resetOnRoute: true,
  userId: state.currentUser.userId,
  currentRoute: state.routing.currentRoute,
  isFinished: state.invoice.isFinished,
  numSuccess: state.invoice.numSuccess,
  numFailure: state.invoice.numFailure,
});

const mapDispatchToProps = {
  sendInvoice: invoiceActions.sendInvoice,
  sendInvoices: invoiceActions.sendInvoices,
  deleteInvoices: invoiceActions.deleteInvoices,
  clearInvoiceStatus: invoiceActions.clearInvoiceStatus,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(SendBillsScreen));
