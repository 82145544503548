import styled from 'styled-components';
import { IonPage } from '@ionic/react';
import { SPACE } from '$gstyles';

export const IonPageWrapper = styled(IonPage)`
  .submit-button {
    min-width: 200px;
    width: 40%;
    margin-left: ${SPACE.XLARGE};
    margin-right: ${SPACE.XLARGE};
  }
  .form-group {
    margin: 0 ${SPACE.SMALL};
  }
  .col {
    padding: 8px 16px !important;
  }
`;
