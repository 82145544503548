import { Dispatch } from 'redux';

import { configs } from '$configs';
import { fetchApi } from '$gbusiness/services/api';
import {
  GET_SUBSCRIPTION_SUCCESS,
  RESET_SUBSCRIPTION,
  SubscriptionActionTypes,
  SUBSCRIPTION_FAILURE,
} from './types';
import { LOADING } from '$gbusiness/redux/loading/types';
import { COLORS } from '$gbusiness/enums';
import { toast } from '$gcomponents/reusables';
import GetSubscriptionMock from '$business/mocks/getSubscription.json';
import { deriveRawToSubscription } from '../../models/subscription';
import { deriveRawToTransactions } from '../../models/transactions';

export function dispatchLoading(dispatch, key = '') {
  dispatch({
    type: LOADING,
    ...(key && { loadingText: key }),
  });
}

export function handleFail(dispatch, err, key, shouldToast = false) {
  const errorKey = key || 'ERROR.SERVER';
  dispatch({
    type: SUBSCRIPTION_FAILURE,
    err: errorKey,
  });

  if (shouldToast) toast({ text: err, message: errorKey, color: COLORS.DANGER });
}

export function getSubscription(subscriptionId) {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch);

    const response = await fetchApi({
      url: configs.api.subscription.one + subscriptionId,
      method: 'GET',
      mockData: GetSubscriptionMock,
    });

    if (!response || !response?.subscription) {
      handleFail(dispatch, response?.err, 'ERROR.GET_SUBSCRIPTION', true);
      return;
    }

    dispatch({
      type: GET_SUBSCRIPTION_SUCCESS,
      subscription: deriveRawToSubscription(response.subscription),
      transactions: response.transactions.map(t => deriveRawToTransactions(t)),
    });
  };
}

export function dehydrate(): SubscriptionActionTypes {
  return { type: RESET_SUBSCRIPTION };
}
