import styled from 'styled-components';
import { Div, Mixins, SPACE } from '$gstyles';
import { mobileStyle } from '$gstyles/utils';

export const EmptyWrapper = styled(Div)`
  ${Mixins.vcenter}
  text-align: center;
`;

export const Circle = styled(Div)`
  ${Mixins.vcenter}
  width: 461px;
  height: 461px;
  background: #f2f2f2;
  border-radius: 50%;
`;

export const ReceiptWrapper = styled(Div)`
  line-height: normal;
  padding: ${SPACE.LARGE};
  margin-top: ${SPACE.LARGE};

  width: 400px;

  ${mobileStyle(`
    width: 300px;
  `)}
`;
