import {
  GET_SUBSCRIPTION_SUCCESS,
  SUBSCRIPTION_FAILURE,
  RESET_SUBSCRIPTION,
  SubscriptionReducerType,
  SUBSCRIPTION_INIT_STATE,
  SubscriptionActionTypes,
} from './types';

export default function(
  state: SubscriptionReducerType = SUBSCRIPTION_INIT_STATE,
  action: SubscriptionActionTypes,
): SubscriptionReducerType {
  switch (action.type) {
    case GET_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscription: action.subscription,
        transactions: action.transactions,
      };
    case SUBSCRIPTION_FAILURE:
      return {
        ...state,
        subscription: undefined,
        transactions: undefined,
      };
    case RESET_SUBSCRIPTION:
      return SUBSCRIPTION_INIT_STATE;
    default:
      return state;
  }
}
