import React from 'react';
import intl from '$intl';
import { IonContent, IonFooter } from '@ionic/react';

import { SPACE } from '$gstyles';
import { Flex } from '$gstyles/wrapper';
import { Button } from '$gcomponents/primitives';
import { ModalWrapper } from '../styles';
import { TableView2 } from '$gcomponents/widgets';
import TRANSACTION_HISTORY_TABLE, { TRANSACTION_HISTORY_TABLE_CONFIG } from './transactionHistoryTable';

interface TransactionHistoryModalProps {
  show: boolean;
  onClose: Function;
  transactions: Array<any>;
}

const TransactionHistoryModal: React.FC<TransactionHistoryModalProps> = ({ show, onClose, transactions }) => {
  return (
    <ModalWrapper
      title="SCREEN.TRANSACTION_HISTORY.TITLE"
      width="800px"
      show={show}
      onClose={() => onClose()}
      useCustom>
      <IonContent>
        {transactions.length > 0 && (
          <TableView2
            data={transactions}
            TABLE={TRANSACTION_HISTORY_TABLE}
            tableConfig={TRANSACTION_HISTORY_TABLE_CONFIG}
          />
        )}
      </IonContent>
      <IonFooter>
        <Flex padding={SPACE.MEDIUM} justifyContent="center">
          <Button className="third" variant="outlined" onClick={() => onClose()}>
            {intl('BUTTON.CLOSE')}
          </Button>
        </Flex>
      </IonFooter>
    </ModalWrapper>
  );
};

export default TransactionHistoryModal;
