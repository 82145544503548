import React from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import intl from '$intl';
import SubscriptionModel from '$business/models/subscription';
import { Flex } from '$gstyles/wrapper';
import { Col, Row, Space } from '$gstyles';

import ChipBox from './chipBox';
import { format } from '$ghelpers/date';
import { DATE_FORMATS } from '$gbusiness/enums';

interface TopSectionProps {
  subscription: SubscriptionModel;
  onBack: () => void;
}

const TopSection: React.FC<TopSectionProps> = ({ subscription, onBack }) => {
  const {
    customerCode,
    amount,
    interval,
    suspendedAt,
    startDate,
    endDate,
    nextPaymentAt,
    lastPaymentAt,
  } = subscription;
  const chipColor = 'rgb(39, 174, 96, 0.05)';
  return (
    <Space.Bottom>
      <Flex className="top-top">
        <div className="back-button" onClick={onBack}>
          <ArrowBackIcon fontSize="small" />
          <div>{intl('SCREEN.RECURRINGS.BACK_LABEL')}</div>
        </div>
        <div className="customer-code">
          {intl('SCREEN.RECURRINGS.CUSTOMER_CODE')}: {customerCode}
        </div>
        <div className={`status ${suspendedAt ? 'danger' : 'primary'} outlined`}>
          {intl('SCREEN.RECURRINGS.' + (suspendedAt ? 'SUSPENDED' : 'ACTIVATED'))}
        </div>
      </Flex>
      <Row>
        <Col gridSize={7}>
          <Flex justifyContent="flex-start" className="column left">
            <ChipBox value={intl('PRICE', { amount })} label="SCREEN.RECURRINGS.NET_TOTAL" />
            <ChipBox value={format(startDate, DATE_FORMATS.SLASH)} label="SCREEN.RECURRINGS.START_DATE" />
            <ChipBox value={format(endDate, DATE_FORMATS.SLASH)} label="SCREEN.RECURRINGS.END_DATE" />
            <ChipBox
              value={intl('INPUT.OPTION.RECURRING_INTERVAL.' + interval)}
              label="SCREEN.RECURRINGS.INTERVAL"
            />
          </Flex>
        </Col>
        <Col gridSize={5}>
          <Flex justifyContent="flex-end" className="column right">
            <ChipBox
              value={format(nextPaymentAt, DATE_FORMATS.SLASH)}
              label="SCREEN.RECURRINGS.END_DATE"
              color={chipColor}
            />
            <ChipBox
              value={format(lastPaymentAt, DATE_FORMATS.SLASH)}
              label="SCREEN.RECURRINGS.END_DATE"
              color={chipColor}
            />
          </Flex>
        </Col>
      </Row>
    </Space.Bottom>
  );
};

export default TopSection;
