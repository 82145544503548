import { toLocal } from '$ghelpers/date';

export default interface SubscriptionModel {
  subscriptionId: number;
  isRecurring: boolean;
  startDate: string;
  endDate?: string;
  count: number;
  interval?: string;
  invoiceType: string;
  email: string;
  customerCode: string;
  customerName: string;
  address1?: string;
  address2?: string;
  zip?: string;
  amount: number;
  nextPaymentAt: string;
  lastPaymentAt: string;
  userName: string;
  createdAt: string;
  suspendedAt: string;
}

export const deriveRawToSubscription = raw => {
  const subscription: SubscriptionModel = {
    subscriptionId: raw.id,
    isRecurring: raw.isRecurring,
    startDate: raw.startDate,
    endDate: raw.endDate || '',
    count: raw.count,
    interval: raw.interval,
    invoiceType: raw.invoiceType,
    email: raw.email,
    address1: raw.address1,
    address2: raw.address2,
    customerCode: raw.customerCode,
    customerName: raw.customerName,
    amount: Number(raw.totalAmount),
    nextPaymentAt: raw.nextPaymentAt,
    lastPaymentAt: raw.lastPaidAt,
    userName: raw.userName || (raw.userFirst || '') + ' ' + (raw.userLast || ''),
    createdAt: toLocal(raw.createdAt),
    suspendedAt: toLocal(raw.suspendedAt),
  };
  return subscription;
};

// DATA GENERATOR json-generator.com
// {
//   totalSize: 70,
//   list: [
//     '{{repeat(5, 70)}}',
//     {
//       id: "{{index() + 1}}",
//       startDate: "{{date(new Date(), new Date(2021, 6, 1), 'YYYY-MM-dd')}}",
//       endDate: "{{date(new Date(2021, 6, 1), new Date(2022, 6, 10), 'YYYY-MM-dd')}}",
//       isRecurring: "{{random(true, false)}}",
//       interval: function (t) { if (this.isRecurring) return 'MONTHLY'; else return ''; },
//       customerCode: "{{integer(1000000, 9999999)}}",
//       customerName: "{{firstName() + ' ' + surname()}}",
//       email: "{{email()}}",
//       totalAmount: "{{floating(10, 1100, 2)}}",
//       count: function (t) { if (this.isRecurring) return t.integer(1, 20); else return 1; },
//       cardId: "{{integer(1, 20)}}",
//       userName: "{{firstName() + ' ' + surname()}}",
//       nextPaymentAt: "{{date(new Date(), new Date(2022, 6, 10), 'YYYY-MM-dd HH:mm:ss')}}",
//       lastPaidAt: "{{date(new Date(2021, 4, 1), date(new Date()), 'YYYY-MM-dd HH:mm:ss')}}",
//       createdAt: function (t) { if (this.transactionId > 0) return t.date(new Date(2021, 3, 1), new Date(), 'YYYY-MM-dd HH:mm:ss'); else return ''; },
//       suspendedAt: function (t) { if (this.cardId > 15) return t.date(new Date(2021, 3, 1), new Date(), 'YYYY-MM-dd HH:mm:ss'); else return ''; }
//     }
//   ]
// }
