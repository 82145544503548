import React from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import CsvDownloader from 'react-csv-downloader';
import { Button } from '@material-ui/core';

import { filterProps } from './leftComponents';
import { fetchApi } from '$gbusiness/services/api';
import { dateToString } from '$gbusiness/helpers/date';
import ExportModel from '$business/models/exportSettings';

interface RightComponentProps {
  filter: filterProps;
  exportSettings?: ExportModel;
}

const RightComponent: React.FC<RightComponentProps> = ({ filter, exportSettings }) => {
  const getList = async () => {
    const response = await fetchApi({
      url: exportSettings?.url,
      method: 'POST',
      param: {
        ...filter,
        pageSize: 9999999,
        sortKey: exportSettings?.sortKey,
        sortOrder: exportSettings?.sortOrder,
      },
    });

    return response.list.map(item => {
      const raw = exportSettings?.deriveToModel(item);
      return exportSettings ? exportSettings.deriveTable(raw) : raw;
    });
  };

  if (!exportSettings) return null;
  const { dateStart, dateEnd, query } = filter;

  const fileName = `${exportSettings?.fileName || 'bills'}${
    dateStart || dateEnd ? `_${dateToString(dateStart)}-${dateToString(dateEnd)}` : ''
  }${query ? `_${encodeURI(query)}` : ''}`;

  return (
    <CsvDownloader filename={fileName} extension=".csv" separator="," wrapColumnChar='"' datas={getList}>
      <Button startIcon={<GetAppIcon />} variant="contained" color="secondary">
        Export
      </Button>
    </CsvDownloader>
  );
};

export default RightComponent;
