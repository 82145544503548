import React from 'react';

import { PopoverWrapper } from './styles';

interface PopOverProps {
  backdrop?: boolean;
  component: React.ReactElement;
  hover?: boolean;
  [x: string]: any;
}

const PopOver: React.FC<PopOverProps> = ({ component, hover, backdrop = false, children, ...rest }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const newComponent = React.cloneElement(component, {
    ...(!hover && { onClick: handlePopoverOpen }),
    ...(hover && { onMouseEnter: handlePopoverOpen }),
    ...(hover && { onMouseLeave: handlePopoverClose }),
  });

  return (
    <>
      <span {...rest}>{newComponent}</span>
      <PopoverWrapper
        className={backdrop && 'backdrop'}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus>
        <div className="pop-container" onClick={handlePopoverClose}>
          {children}
        </div>
      </PopoverWrapper>
    </>
  );
};

export default PopOver;
