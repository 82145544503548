import React from 'react';
import { Chip, Button } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import { configs } from '$configs';
import intl, { Text } from '$intl';
import { SORT, ROUTES, COLORS, DATE_FORMATS } from '$gbusiness/enums';
import CellModel from '$gbusiness/models/cell';
import { format, today } from '$ghelpers/date';
import { COLOR, Div } from '$gstyles';
import PopOver from '$gcomponents/reusables/popOver';
import { Flex } from '$gstyles/wrapper';
import { deriveRawToInvoice } from '$business/models/invoice';
import { KEYS as BILL_TYPE } from '$business/enums/options/billType';

export const AUTO_COMPLETE = {
  endpoint: configs.api.users.search,
  renderSuggestion: e => `${e.firstName} ${e.lastName} ${e.email}`,
  selectedValue: e => e.firstName,
  onSelect: (e, history) => {
    history.push(`${ROUTES.USERS}/${e.userId}`);
  },
};

export const TABLE_CONFIG: any = {
  endpoint: configs.api.invoice.list,
  isPublic: false,
  deriveToModel: deriveRawToInvoice,
  color: COLORS.SECONDARY,
  pageSize: 1000,
  fontSize: '0.9em',
  cellPadding: `3px`,
  defaultSortKey: 'createdAt',
  defaultSortOrder: SORT.ASC,
  isStripped: true,
  minWidth: 960,
  maxWidth: 1300,
  shouldVirtualize: true,
  virtualized: {
    rowHeight: 36,
    headerHeight: 60,
    checkWidth: 60,
    threshold: 10,
    additionalHeight: 0,
  },
  filter: {
    color: COLORS.PRIMARY,
  },
};

export const TABLE: Array<CellModel> = [
  {
    label: 'SCREEN.INVOICE.COLS.BILL_DATE',
    value: 'billDate',
    // component: row => <>{row.createdAt ? format(row.billDate.substr(0, 10), DATE_FORMATS.SLASH) : ''}</>,
    sortKey: 'createdAt',
    sortable: SORT.ASC,
    width: 180,
  },
  {
    label: 'SCREEN.INVOICE.COLS.IMPORTED_DATE',
    value: 'importedDate',
    component: row => <>{row.importedDate ? format(row.importedDate, DATE_FORMATS.SLASH) : ''}</>,
    width: 180,
  },
  {
    label: 'SCREEN.INVOICE.COLS.SEQ',
    value: 'invoiceId',
    sortKey: 'id',
    sortable: SORT.ASC,
    width: 80,
  },
  {
    label: 'SCREEN.INVOICE.COLS.PAST_DUE',
    value: 'dueDate',
    component: row => {
      const isPastDue = row.dueDate < today();
      const color = isPastDue ? COLOR.DANGER : COLOR.SUCCESS;
      const text = isPastDue ? 'Past Due' : 'No';
      return (
        <Div color={color} fontWeight="600">
          {text}
        </Div>
      );
    },
    sortable: SORT.ASC,
    width: 150,
  },
  {
    label: 'SCREEN.INVOICE.COLS.BILL_TYPE',
    value: 'invoiceType',
    component: row => {
      const color =
        row.invoiceType === BILL_TYPE.CONTRACT
          ? 'primary'
          : row.invoiceType === BILL_TYPE.PPI
          ? 'tertiary'
          : row.invoiceType === BILL_TYPE.SALES
          ? 'SALES'
          : 'default';
      return (
        <Chip
          variant="outlined"
          className={`${color} outlined`}
          size="small"
          label={intl('INPUT.OPTION.BILLING_TYPE.' + row.invoiceType)}
        />
      );
    },
    sortable: SORT.ASC,
    width: 200,
  },
  {
    label: 'SCREEN.INVOICE.COLS.BILL_NUMBER',
    value: 'billNo',
    sortable: SORT.ASC,
    width: 240,
  },
  {
    label: 'SCREEN.INVOICE.COLS.CUSTOMER_CODE',
    value: 'customerCode',
    sortable: SORT.ASC,
    width: 200,
  },
  {
    label: 'SCREEN.INVOICE.COLS.EMAIL',
    value: 'email',
    sortable: SORT.ASC,
    width: 350,
  },
  {
    label: 'SCREEN.INVOICE.COLS.SEND_BILL',
    value: '',
    component: (row, action) => {
      return (
        <Flex className="pointer" onClick={() => action.sendBill(row)}>
          <EmailIcon style={{ fontSize: '1.4em' }} />
          <span className="send-text">
            <Text k="SCREEN.BILLS.SEND_ACTION" />
          </span>
        </Flex>
      );
    },
    width: 200,
  },
  {
    label: 'SCREEN.INVOICE.COLS.ACTION',
    value: '',
    align: 'center',
    className: 'action',
    component: (row, actions) => {
      return (
        <PopOver component={<MoreHorizIcon className="pointer" />}>
          <>
            <Button className="left" fullWidth onClick={() => actions.viewDetails(row)}>
              {intl('SCREEN.INVOICE.ACTIONS.VIEW_DETAILS')}
            </Button>
            <Button className="left" fullWidth onClick={() => actions.updateStatus(row)}>
              {intl('SCREEN.INVOICE.ACTIONS.UPDATE_STATUS')}
            </Button>
          </>
        </PopOver>
      );
    },
    width: 100,
  },
];
