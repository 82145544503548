import React from 'react';
import { Tab, TabWrapper } from '$styles/tabs';
import intl from '$intl';
import { tabModel } from '../screenSection';

interface ListTabProps {
  height: number;
  activeTab: number;
  tabs: Array<tabModel>;
  onTabChange: Function;
}

const ListTabs: React.FC<ListTabProps> = ({ height, activeTab, tabs, onTabChange }) => {
  return (
    <TabWrapper height={height}>
      {tabs.map((tab, index) => (
        <Tab
          key={index}
          {...(activeTab === index && { className: 'active' })}
          onClick={() => onTabChange(index)}>
          {intl(tab.label)}
        </Tab>
      ))}
    </TabWrapper>
  );
};

export default ListTabs;
