import React from 'react';
import { connect } from 'react-redux';

import { screen } from '$gcomponents/hoc';
import * as TABLE from './recurringTable';
import ScreenSection, { tabModel } from '../../transactionsScreen/screenSection';
import intl from '$intl';
import { invoiceActions } from '$business/redux/invoice';
import { ROUTES } from '$business/enums';

const TABS: Array<tabModel> = [
  { label: 'SCREEN.INVOICE.TABS.ALL', value: '' },
  { label: 'SCREEN.INVOICE.TABS.ACTIVE', value: 'active' },
  { label: 'SCREEN.INVOICE.TABS.SUSPENDED', value: 'suspended' },
];

interface RecurringsScreenProps {
  history: any;
  suspendSubscription: Function;
  unsuspendSubscription: Function;
  deleteSubscription: Function;
}

const RecurringsScreen: React.FC<RecurringsScreenProps> = ({
  history,
  suspendSubscription,
  unsuspendSubscription,
  deleteSubscription,
}) => {
  const itemActions = {
    suspendSubscription: async (row, onRefresh) => {
      await suspendSubscription([row.subscriptionId]);
      onRefresh();
    },
    unsuspendSubscription: async (row, onRefresh) => {
      await unsuspendSubscription([row.subscriptionId]);
      onRefresh();
    },
    viewSubscription: row => {
      history.push(`${ROUTES.RECURRING}/${row.subscriptionId}`);
    },
    deleteSubscription: async (row, onRefresh) => {
      await deleteSubscription([row.subscriptionId]);
      onRefresh();
    },
  };

  return (
    <ScreenSection
      itemActions={itemActions}
      title={intl('SCREEN.INVOICE.TITLE_RECURRING')}
      TABLE={TABLE}
      TABS={TABS}
    />
  );
};

const mapDispatchToProps = {
  suspendSubscription: invoiceActions.suspendSubscription,
  unsuspendSubscription: invoiceActions.unsuspendSubscription,
  deleteInvoices: invoiceActions.deleteSubscription,
};

const connected = connect(null, mapDispatchToProps);

export default connected(screen(RecurringsScreen));
