import React from 'react';
import { IonRouterLink } from '@ionic/react';

import { ButtonWrapper, RouterLink } from './styles';

interface LinkProps {
  route?: string;
  button?: boolean;
  isHidden?: boolean;
  routerDirection?: 'back' | undefined;
  [x: string]: any;
}

const Link: React.FC<LinkProps> = ({
  route,
  isHidden,
  children,
  button = false,
  routerDirection,
  ...rest
}) => {
  if (isHidden) return null;
  const linkComponent = routerDirection ? (
    <IonRouterLink routerLink={route} routerDirection={routerDirection} {...rest}>
      {children}
    </IonRouterLink>
  ) : route ? (
    <RouterLink to={route} {...rest}>
      {children}
    </RouterLink>
  ) : (
    <div {...rest}>{children}</div>
  );

  return button ? <ButtonWrapper>{linkComponent}</ButtonWrapper> : <>{linkComponent}</>;
};

export default Link;
